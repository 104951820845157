export class SummaryCoordinates {
  latitude: number;
  longitude: number;
  isFallback?: boolean;
}

export class SummaryLocation extends SummaryCoordinates {
  address: string;
  formatted_address: string;
  state: string;
  city: string;
  country: string;
  countryname: string;
  postalCode: string;
  isArea?: number;
  streetNumber?: string;
  street?: string;
}

import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { UserAvailableRoles } from '@app/shared/models';
import { L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { FlexModule } from '@angular/flex-layout';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { MatIconModule } from '@angular/material/icon';
import { DialogRegisterRoleComponent } from '@app/shared/components';
import { ModalService } from '@app/shared/services';
import { CurrentUser } from '@app/shared/models/current-user';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { FeatureFlags } from '@app/shared/models/constants';
import { Store } from '@ngrx/store';
import { IntercomService } from '@app/shared/services/intercom.service';
import { UserRoles } from '@app/shared/models/enum/userroles';

@Component({
  selector: 'gingr-sidenav-mobile',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    RouterLink,
    SvgIconComponent,
    L10nTranslatePipe,
    MatIconModule,
    RouterLinkActive,
    NgClass,
  ],
  templateUrl: './sidenav-mobile.component.html',
  styleUrls: ['./sidenav-mobile.component.scss'],
})
export class SidenavMobileComponent implements OnInit {
  @Input() user: CurrentUser;
  @Input() avatar: string;
  @Input() isProUser: boolean;
  @Input() featurePageSubscription: boolean;
  @Input() baseRole: string;
  @Input() featureHasGGCoin: boolean;
  @Input() showBuildId: boolean;
  @Input() releaseId: string;

  @Output() loginEvent = new EventEmitter();
  @Output() logoutEvent = new EventEmitter();

  readonly locale = inject(L10N_LOCALE);
  readonly store = inject(Store);
  readonly intercomService = inject(IntercomService);
  settingsExpanded = false;
  profileExpanded = false;
  userAvailableRoles = UserAvailableRoles;
  changeLanguage: boolean;
  userRoles = UserRoles;
  hideReviews = false;

  constructor(private modalService: ModalService) {}

  async ngOnInit() {
    await StaticUtilsService.LdClient.waitUntilReady().then();
    this.hideReviews = StaticUtilsService.LdClient.variation(
      FeatureFlags.HideClientReviews,
      false
    );
    this.changeLanguage = StaticUtilsService.LdClient.variation(
      FeatureFlags.ChangeLanguage,
      false
    );
  }

  openDialog(type: string): void {
    this.modalService.openDialog(
      DialogRegisterRoleComponent,
      {
        type: type,
        role: UserAvailableRoles.client,
      },
      'register-dialog--panel'
    );
  }

  openIntercomSupport() {
    this.intercomService.intercom(true);
  }
}

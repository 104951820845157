import { Injectable } from '@angular/core';
import {
  Language,
  UserEyeColor,
  UserHairColor,
  UserHear,
  UserSex,
  UserSexuality,
} from '@app/shared/models';
import { StorageService } from '@app/shared/services/storage.service';
import {
  EstablishmentServiceItem,
  UserBody,
  UserGenitaliaArea,
  UserOrigin,
  UserPhonePrefix,
  UserServiceItem,
  UserBodyArt,
} from '@app/shared/models/user';
import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsHelperService {
  public static debugFormControlErrors(controls) {
    for (const control in controls) {
      if (controls[control]['errors'] !== null) {
      }
    }
  }

  public static parseServerDOBDate(date) {
    const parts = date.split('-');
    return new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
  }

  public static getBirthYearList(): Array<number> {
    const years = [];
    for (let i = this.getDefaultBirthYear(); i >= 1940; i--) {
      years.push({
        year: '' + i,
      });
    }
    return years;
  }

  public static getBirthYearNumbers(): number[] {
    const years = [];
    for (let i = this.getDefaultBirthYear(); i >= 1940; i--) {
      years.push(i);
    }
    return years;
  }

  public static getDefaultBirthYear() {
    return new Date(
      new Date().getTime() - 18 * 1000 * 60 * 60 * 24 * 365
    ).getFullYear();
  }

  constructor() {}

  public getPrices(prices: any[], role?: string): Array<Language> {
    return prices
      ? prices.filter((each) => {
          return role ? each.role === role : true;
        })
      : [];
  }

  getLanguageIdByLocale(languages: any[], locale) {
    let language_id;
    if (languages.length > 0) {
      language_id = languages[0]['id'];
    }
    languages.forEach((item: Language) => {
      if (item.code === locale) {
        language_id = item.id;
      }
    });
    return language_id;
  }
}

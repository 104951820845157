import firebase from 'firebase/compat';
import App = firebase.app.App;

export interface Config {
  generalProperties: AppConfigItem[];
  hearAboutUs: AppConfigItem[];
  bodies: AppConfigItem[];
  eyeColors: AppConfigItem[];
  hairColors: AppConfigItem[];
  languages: Language[];
  origins: AppConfigItem[];
  services: Service[];
  genders: AppConfigItem[];
  sexualities: AppConfigItem[];
  talias: AppConfigItem[];
  nationalities: string[];
  countries: Country[];
  travels: AppConfigItem[];
}

export enum AppConfig {
  INSTA_PENDING_CONFIRMATION = 'INSTA_PENDING_CONFIRMATION',
  INSTA_END_CONFIRMATION = 'INSTA_END_CONFIRMATION',
  TRAVEL_PRICE_PER_KM = 'TRAVEL_PRICE_PER_KM',
  TRAVEL_PRICE_MIN = 'TRAVEL_PRICE_MIN',
  ESCORT_FEE_BOOK = 'ESCORT_FEE_BOOK',
  ESCORT_FEE_INSTA = 'ESCORT_FEE_INSTA',
  INSTA_ARRIVED_DISTANCE_METERS = 'INSTA_ARRIVED_DISTANCE_METERS',
}

export interface AppConfigItem {
  name: string;
  value?: string;
  id?: number;
}

export interface Language {
  name: string;
  code: string;
  id: number;
}

export interface Country {
  name: string;
  code: string;
  code2: string;
}

export interface Service {
  id: number;
  name: string;
  activityType?: string;
  group: number;
}

export enum ConfigLanguageKeys {
  BodyTypes = 'COMMON.BODYTYPES.',
  HairColors = 'COMMON.HAIRCOLORS.',
  EyeColors = 'COMMON.EYECOLORS.',
}

// import the required animation functions from the angular animations module
import {
  trigger,
  state,
  animate,
  transition,
  style,
  query,
  stagger,
} from '@angular/animations';

export const gridAnimation = trigger('gridAnimation', [
  transition(':enter', [
    query(
      '.grid-tile',
      [
        style({ transform: 'translateY(-50px)', opacity: 0 }),
        stagger(50, [
          animate(
            '800ms cubic-bezier(.35,0,.25,1)',
            style({ transform: 'translateY(0px)', opacity: 1 })
          ),
        ]),
      ],
      { delay: 0, optional: true }
    ),
  ]),
]);

<div class="reset-pass-container" ngClass.xs="mobile" ngClass.sm="mobile">
  <mat-icon class="material-icons close" (click)="closeDialog()">
    close
  </mat-icon>
  <h4 mat-dialog-title class="color-system-yellow">
    {{ resetPasswordTitle | translate: locale.language }}
  </h4>

  <form
    [formGroup]="resetPasswordForm"
    name="resetPasswordForm"
    id="reset-password-form"
    #f="ngForm"
    (ngSubmit)="submitForm()"
    fxLayout="column"
  >
    <mat-form-field class="form-input">
      <input
        formControlName="password"
        matInput
        placeholder="New Password"
        type="password"
        [(ngModel)]="resetPasswordModel.password"
        [type]="showPassword ? 'text' : 'password'"
      />
      <mat-icon
        class="cursor-pointer how-pass"
        (click)="showPassword = !showPassword"
        >remove_red_eye</mat-icon
      >
      <mat-error
        *ngIf="resetPasswordForm.controls['password'].hasError('required')"
      >
        {{ 'RESET_PASSWORD_DIALOG.required' | translate: locale.language }}
      </mat-error>
      <mat-error
        *ngIf="resetPasswordForm.controls['password'].hasError('pattern')"
      >
        {{ 'RESET_PASSWORD_DIALOG.pattern' | translate: locale.language }}
      </mat-error>
      <mat-error
        *ngIf="resetPasswordForm.controls['password'].hasError('server')"
      >
        {{ resetPasswordForm.controls['password'].getError('server') }}
      </mat-error>
      <mat-error
        *ngIf="resetPasswordForm.controls['password'].hasError('noMatch')"
      >
        {{ 'RESET_PASSWORD_DIALOG.noMatch' | translate: locale.language }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input">
      <input
        formControlName="compare_password"
        matInput
        type="password"
        placeholder="{{
          'RESET_PASSWORD_DIALOG.Confirm Password' | translate: locale.language
        }}"
        [type]="showConfirmPassword ? 'text' : 'password'"
      />
      <mat-icon
        class="cursor-pointer how-pass"
        (click)="showConfirmPassword = !showConfirmPassword"
        >remove_red_eye</mat-icon
      >
      <mat-error
        *ngIf="
          resetPasswordForm.controls['compare_password'].hasError('required')
        "
      >
        {{ 'RESET_PASSWORD_DIALOG.required' | translate: locale.language }}
      </mat-error>
      <mat-error
        *ngIf="
          resetPasswordForm.controls['compare_password'].hasError('noMatch')
        "
      >
        {{ 'RESET_PASSWORD_DIALOG.noMatch' | translate: locale.language }}
      </mat-error>
    </mat-form-field>

    <button
      mat-raised-button
      [disabled]="!resetPasswordForm.valid || isSubmitting"
      type="submit"
      color="accent"
    >
      {{ 'RESET_PASSWORD_DIALOG.OK' | translate: locale.language }}
    </button>
  </form>
</div>

<div
  fxHide.xs
  id="domains"
  class="domains-container content-container"
  fxLayout="column"
>
  <mat-grid-list rowHeight="48" cols="2" gutterSize="8px">
    <mat-grid-tile
      [class.countrySelected]="extraCountry === origin.country"
      [colspan]="1"
      [rowspan]="1"
      *ngFor="let origin of origins"
    >
      <mat-card appearance="outlined" (click)="emitOrigin(origin)" fxFlex="95">
        <mat-card-content fxLayout="row">
          <img
            height="48px"
            [src]="
              'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/' +
              origin.countryCode2.toLowerCase() +
              '.svg'
            "
            alt="{{ origin.country }}"
            class="language-selector__flag"
          />
          <div
            fxLayout="row"
            class="domain-label"
            fxLayoutAlign="space-between center"
          >
            <span class="countyLabel">{{ origin.country }}</span>
            <span class="domain">{{
              'www.' + origin.domainBegin + '.' + origin.domainEnd
            }}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<div fxHide fxShow.xs fxFlex="100" class="press-domains-mobile">
  <div fxFlex="90" class="press-domains-mobile_wrapper">
    <gingr-form-field class="press-domains-mobile_wrapper_select">
      <mat-form-field class="gg-form-field__content">
        <img
          class="press-domains-mobile_wrapper_select_img"
          src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/{{
            selectedCountry | lowercase
          }}.svg"
          alt="{{ selectedCountry }}"
        />
        <mat-select
          panelClass="press-countries-select"
          (selectionChange)="selectOrigin(selectedCountry)"
          value="Select country"
          placeholder="Select country"
          [(ngModel)]="selectedCountry"
        >
          <mat-option
            value="{{ origin.countryCode2 }}"
            *ngFor="let origin of origins"
          >
            <div class="press-domains-mobile_wrapper_select_item">
              <span>{{ origin.country }}</span>
              <img
                class="press-domains-mobile_wrapper_select_item_img"
                height="100px"
                [src]="
                  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/' +
                  origin.countryCode2.toLowerCase() +
                  '.svg'
                "
                alt="{{ origin.country }}"
              />
            </div>
          </mat-option>
        </mat-select>
        <mat-error>{{
          'ACCOUNT.DETAILS.Please set country' | translate: locale.language
        }}</mat-error>
      </mat-form-field>
    </gingr-form-field>
    <mat-icon class="press-domains-mobile_wrapper_help-icon"
      >help_outline</mat-icon
    >
  </div>
</div>

import { Action } from '@ngrx/store';
import * as auth from './auth.actions';

export const initialState = {
  isAuthenticated: false,
  user: null,
};

export const AUTH_KEY = 'AUTH';
export const AUTH_LOGIN = auth.AUTH_LOGIN;
export const AUTH_LOGOUT = auth.AUTH_LOGOUT;

export const logout = () => ({ type: AUTH_LOGOUT });

export const selectorAuth = (state) => state.auth;

export function authReducer(state = initialState, action: auth.Actions) {
  switch (action.type) {
    case AUTH_LOGIN:
      return Object.assign({}, state, {
        isAuthenticated: true,
        user: action.payload.user,
      });

    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
}

export const getUser = (state) => state.user;

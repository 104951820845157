import { Component, HostBinding, OnInit } from '@angular/core';

import { UserService } from '@app/shared/services';
import { DialogLoginComponent } from '@app/shared/components/dialog-login-register/dialog-login-register.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { logoutAction } from '@app/shared/actions/shared.actions';
import { Store } from '@ngrx/store';

@Component({
    selector: 'gingr-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
})
export class LoginComponent implements OnInit {
  private dialogRef: MatDialogRef<DialogLoginComponent> = this.dialog.open(
    DialogLoginComponent,
    {
      data: { type: 'login' },
      panelClass: 'login-dialog--panel',
    }
  );

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    this.dialogRef.afterClosed().subscribe((result) => {
      // no history back when user make authentication
      // if (this.userService.showWhenAuth(this.isAuth)) {
      //   window.history.back();
      // }
    });
  }

  ngOnInit() {
    this.store.dispatch(logoutAction());
  }
}

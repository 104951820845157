import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResetPassword {
  password: string;
  code: string;
  username: string;
}

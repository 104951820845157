import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogForgotPasswordComponent } from '@app/shared/components/dialog-forgot-password/dialog-forgot-password.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogResetPasswordComponent } from '@app/shared/components/dialog-reset-password/dialog-reset-password.component';
import { ModalService } from '@app/shared/services/modal.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { logoutAction } from '@app/shared/actions/shared.actions';
import { Store } from '@ngrx/store';

@Component({
    selector: 'gingr-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    standalone: true,
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const code = params?.['code'];
      const username = params?.['username'];

      if (code && username) {
        this.modalService.openDialog(DialogResetPasswordComponent, {
          code: code,
          username: username,
        });
      }
    });
  }
}

@Component({
    selector: 'gingr-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    standalone: true,
})
export class ForgotPasswordComponent implements OnInit {
  private dialogRef: MatDialogRef<DialogForgotPasswordComponent> =
    this.dialog.open(DialogForgotPasswordComponent);

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private dialog: MatDialog,
    private store: Store<any>
  ) {
    this.dialogRef.afterClosed().subscribe(() => {});
  }

  ngOnInit() {
    this.store.dispatch(logoutAction());
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { StorageService } from './storage.service';
import { ConfigService } from '@app/shared/services/config.service';
import { Store } from '@ngrx/store';
import { getAuthToken } from '@app/shared/reducers/user.selectors';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public static loginActionPath = '/login';
  public csrfToken: String;
  accessToken: string;

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public notify: NotificationService,
    public configService: ConfigService,
    public router: Router,
    public store: Store<any>
  ) {
    this.store
      .select(getAuthToken)
      .subscribe((accessToken) => (this.accessToken = accessToken));
  }

  protected setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    };

    if (localStorage['appToken']) {
      headersConfig['x-csrf-token'] = localStorage['appToken'];
    }

    if (this.accessToken) {
      headersConfig['Authorization'] = `Bearer ${this.accessToken}`;
    }
    return new HttpHeaders(headersConfig);
  }

  protected formatErrors(error: any) {
    return observableThrowError(error);
  }

  get(
    path: string,
    params: HttpParams = new HttpParams(),
    headers = null
  ): Observable<any> {
    headers = headers != null ? headers : this.setHeaders();

    return this.http
      .get(`${this.configService.config.nodeApiUrl}${path}`, {
        headers: headers,
        params: params,
      })
      .pipe(
        catchError(this.formatErrors),
        map((res) => res)
      );
  }

  getLocalAsset(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http.get(path).pipe(
      catchError(this.formatErrors),
      map((res) => res)
    );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(
        `${this.configService.config.nodeApiUrl}${path}`,
        JSON.stringify(body),
        {
          headers: this.setHeaders(),
        }
      )
      .pipe(
        catchError(this.formatErrors),
        map((res) => res)
      );
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(
        `${this.configService.config.nodeApiUrl}${path}`,
        JSON.stringify(body),
        {
          headers: this.setHeaders(),
        }
      )
      .pipe(
        catchError(this.formatErrors),
        map(
          (data) => data,
          (err) => {
            if (
              (err.status === 401 ||
                (err.status === 403 && !this.accessToken)) &&
              path !== ApiService.loginActionPath
            ) {
              this.notify.warn(
                'Warning',
                'User needs to be logged in to perform this action'
              );
              this.router.navigate(['login']);
            } else {
              // TODO
              // DOUBLE NOTIFICATIONS The api service don't show manange error, only make the connection
              // we are obtaining double notification and expected
              // this.notify.error('Error ' + response.status, 'Something went wrong. Please try again later');
            }
            Sentry.captureException(err);
          }
        )
      );
  }

  /**
   * return response data with all status code
   * @param {string} path
   * @param {Object} body
   * @returns {Observable<any>}
   */
  basePost(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(
        `${this.configService.config.nodeApiUrl}${path}`,
        JSON.stringify(body),
        {
          headers: this.setHeaders(),
        }
      )
      .pipe(
        catchError(this.formatErrors),
        map((res) => {
          return res;
        })
      );
  }

  delete(path): Observable<any> {
    return this.http
      .delete(`${this.configService.config.nodeApiUrl}${path}`, {
        headers: this.setHeaders(),
      })
      .pipe(
        catchError(this.formatErrors),
        map((res) => res)
      );
  }
}

<div fxLayout="row" class="location-selector-container w-full py-10 px-0 xl:pt-[56px]">
  <div
    class="form-group border-2 border-primary rounded px-3.5 pl-[5px] text-white py-[1px]"
  >
    <input
      (blur)="unfocus()"
      [(ngModel)]="locationTextLabel"
      type="text"
      class="form-control location-text focus:outline-none text-white bg-transparent"
      #search
    />
    <img
      alt="close"
      src="assets/icons/cross.svg"
      class="cursor-pointer"
      (click)="deleteLocation()"
    />
  </div>
</div>

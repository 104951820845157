import { createReducer, on } from '@ngrx/store';
import { GingrPricingCommand } from '@app/account/containers/profile-v2/models/gingr-pricing.command';
import { setPricingCommand } from '@app/account/containers/profile-v2/store/profile-v2.actions';

export interface ProfileV2State {
  pricingCommand: GingrPricingCommand;
}

export const initialProfileV2: ProfileV2State = {
  pricingCommand: undefined,
};

export const profileV2Reducer = createReducer(
  initialProfileV2,
  on(setPricingCommand, (state, { pricingCommand }) => ({
    ...state,
    pricingCommand,
  }))
);

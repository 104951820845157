import { catchError, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ApiService,
  NodeApiService,
  NotificationService,
} from '@app/shared/services';
import { User, UserAvailableRoles } from '@app/shared/models';
import { Store } from '@ngrx/store';
import {
  setManagedUserAction,
  smsVerifyManagedAction,
} from '@app/shared/actions/shared.actions';

@Injectable({
  providedIn: 'root',
})
export class ManageGingrsService {
  private managedGingrs$ = new BehaviorSubject<any[]>(null);
  public managedGingrs = this.managedGingrs$.asObservable();

  addGingrType: string;

  public static isGingrPremium(roleId: number): boolean {
    return UserAvailableRoles.gingr.roles[1] === roleId;
  }

  public static supplementData(user: any): any {
    if (user.hasOwnProperty('profile') && !user.profile) {
      user.profile = {};
    }
    return user;
  }

  constructor(
    private nodeApi: NodeApiService,
    private api: ApiService,
    private notify: NotificationService,
    private store: Store<any>
  ) {}

  public takeALookAtGingrs(): any[] {
    return this.managedGingrs$.getValue();
  }

  public updateManagedGingrsValue(value: any[]) {
    this.nextValue(value);
  }

  private nextValue(value: any[]) {
    this.managedGingrs$.next(value);
  }
  // update by post
  public updateAccount(data: any): Observable<any> {
    return this.api.post('/manageGingrs/update', data);
  }

  // accept gingr reconection
  public acceptGingrConnection(slug: string): Observable<any> {
    return this.nodeApi
      .post('/manageGingrs/reConnectConfirmation', {
        action: 1,
        slug: slug,
      })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            this.loadManagedUsers();
          }
          return response;
        })
      );
  }

  // decline gingr reconection
  public declineGingrConnection(slug: string): Observable<any> {
    return this.nodeApi
      .post('/manageGingrs/reConnectConfirmation', {
        action: 0,
        slug: slug,
      })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            this.loadManagedUsers();
          }
          return response;
        })
      );
  }

  // update by post
  public createNewGingrAccount(data: any): Observable<any> {
    return this.nodeApi.post('/manageGingrs/create', data);
  }

  // cteate new gingr
  public createIndependentGingrAccount(data: any): Observable<any> {
    return this.nodeApi.post('/manageGingrs/createIndependent', data);
  }

  // update status
  public updateGingrStatus(data: any): Observable<any> {
    return this.nodeApi.post('/manageGingrs/updateStatus', data);
  }

  // confirmation connection Existing Gingr
  public confirmGingrConnection(data: any): Observable<any> {
    return this.nodeApi.post('/manageGingrs/disconnect/acceptDecline', data);
  }

  public loadManagedUsers() {
    return this.nodeApi.get('/manageGingrs').subscribe((response) => {
      if (response.status === 200) {
        this.nextValue(response.result);
      }
    });
  }

  public setGingr(user: User, gingrType?: string) {
    this.store.dispatch(setManagedUserAction({ data: user }));
    this.addGingrType = gingrType;
  }

  public getGingrBySlug(slug: string) {
    return this.nodeApi.get('/manageGingrs/' + slug);
  }

  public deleteGingr(slug: string) {
    return this.nodeApi.post('/manageGingrs/delete', { slug: slug });
  }

  public transferGingr(data: any) {
    return this.nodeApi.post('/manageGingrs/transfer', data);
  }

  public addExistingGingr(data: any) {
    return this.nodeApi.post('/manageGingrs/addExisting', data);
  }

  /**
   * Send Identify
   */
  sendIdentify(file: any, slug: string): Observable<Response> {
    const fileJson = { identifyMedia: file, slug: slug };
    return this.nodeApi.post('/identify/id', fileJson);
  }

  /**
   * Send Identify Photo
   */
  sendIdentifyPhoto(file: any, slug: string): Observable<Response> {
    const fileJson = { identifyMedia: file, slug: slug };
    return this.nodeApi.post('/identify/image', fileJson);
  }

  /**
   * Send Email Verification
   */
  sendEmailVerification(slug: string): Observable<Response> {
    const data: any = {
      slug: slug,
    };
    return this.nodeApi.post('/sendEmailVerification', data);
  }

  /**
   * Send Sms Verification
   * @param slug
   */
  sendSmsVerification(slug: string): Observable<Response> {
    const data: any = {
      slug: slug,
    };
    return this.api.post('/sendSmsVerification', data);
  }

  /**
   * Pending inserting code
   * @param code
   * @param slug
   */
  sendSmsConfirmation(code: string, slug: string): Observable<Response> {
    const data: any = {
      slug: slug,
      sms: code,
    };
    return this.api.post('/confirmationSms', data).pipe(
      map((result) => {
        if (result.status === 200) {
          this.store.dispatch(smsVerifyManagedAction());
        }
        return result;
      })
    );
  }

  public goToLive(slug: string, userLocation?) {
    const data: any = {
      slug: slug,
      userLocation,
    };
    return this.api.post('/goLive', data);
  }

  update(user: User): void {
    this.store.dispatch(setManagedUserAction({ data: user }));
  }

  public changeDefaultSettings(settings: any): Observable<any> {
    return this.nodeApi.post('/manageGingrs/defaultSettings', settings).pipe(
      filter((response: any) => {
        if (response.status === 200) {
          // this.getUserData().subscribe(data => {
          //   this.update(data.result);
          // });
          if (settings.active) {
            this.notify.success(
              'Success',
              'Setting changed to ' + (settings.active ? 'default' : 'custom')
            );
          }
        } else {
          this.notify.error('Error', response.message);
        }
        return response;
      }),
      catchError((err: any) => {
        if (err) {
          this.notify.error('Error', err.message);
        }
        return err;
      })
    );
  }
}

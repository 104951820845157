<div
  *ngIf="pressDownloads && pressDownloads.length !== 0"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="content-container downloads-container"
>
  <div fxFlexFill class="app-title" fxLayout="column">
    <h2>
      {{ 'PRESS.Press downloads' | translate: locale.language }}
    </h2>
  </div>
  <a
    [href]="'https://media.gingr.es/press/press_resources/files.zip'"
    download
    mat-button
    class="mat-button mat-accent mat-ink-ripple download-file"
  >
    <mat-icon> file_download </mat-icon>
    {{ 'PRESS.Download all' | translate: locale.language }}
  </a>
  <mat-grid-list [cols]="cols | async">
    <mat-grid-tile *ngFor="let resource of pressDownloads">
      <mat-card appearance="outlined" fxLayout="column">
        <mat-card-header>
          <mat-card-title class="preview">
            <div
              class="img"
              *ngIf="resource.image"
              [style.background-image]="resource.image"
            ></div>
            <mat-icon *ngIf="resource.pdf" class="material-icons">
              picture_as_pdf
            </mat-icon>
            <mat-icon *ngIf="resource.unknownFormat">
              insert_drive_file
            </mat-icon>
            <video
              (mouseenter)="resource.hover = true"
              (mouseleave)="resource.hover = false"
              class="video-preview"
              *ngIf="resource.video"
              [controls]="resource.hover"
            >
              <source
                [src]="mediaCdnUrl + resource.video"
                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
              />
            </video>
          </mat-card-title>
          <mat-card-subtitle>
            <p>{{ resource.title }}</p>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <a
            [href]="mediaCdnUrl + resource.fileSrc"
            download
            mat-button
            class="mat-button mat-accent mat-ink-ripple download-file"
          >
            <mat-icon> file_download </mat-icon>
            {{ 'PRESS.Download file' | translate: locale.language }}
          </a>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>

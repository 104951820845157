import { AbstractControl } from '@angular/forms';

// Password Confirm validation function
export function CaptchaValidator(control: AbstractControl) {
    if(control.parent){
        if(!control.value || control.value == ''){
            return { nocaptcha: true }
        }
        return null;
    }
    return null;
  }

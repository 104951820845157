import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'gingr-busy-overlay',
  templateUrl: './busy-overlay.component.html',
  styleUrls: ['./busy-overlay.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class BusyOverlayComponent {
  @Input() text: string;

  public isShowGlobe: boolean = true;
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  LocationService,
  NotificationService,
  ValidatorService,
} from '@app/shared/services';
import {
  Errors,
  RegUserAgency,
  RegUserBrothel,
  UserAvailableRoles,
} from '@app/shared/models';
import { Router, RouterLink } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RegisterService } from '../../services/register.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { LoginUser, RegUserClient, RegUserGingr } from '../../models';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTabGroup } from '@angular/material/tabs';
import { UserSettingsHelperService } from '@app/account/services/user-settings-helper.service';
import { PrivacyPolicyValidator } from '@app/shared/validators';
import { DomSanitizer } from '@angular/platform-browser';
import { UserPhonePrefix } from '@app/shared/models/user';
import { ModalService } from '@app/shared/services/modal.service';
import { DialogForgotPasswordComponent } from '@app/shared/components/dialog-forgot-password/dialog-forgot-password.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { _t } from '@app/shared/helpers';
import { MediaObserver } from '@angular/flex-layout';

import { InformationFillingService } from '@app/account/services/information-filling.service';
import {
  L10N_CONFIG,
  L10N_LOCALE,
  L10nConfig,
  L10nLocale,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { getAllLanguageList } from '@app/shared/reducers/shared.selectors';
import { CognitoService } from '@app/shared/services/cognito.service';
import { CognitoRegisterModel } from '@app/shared/models/cognito-register.model';
import { getCurrentUser } from '@app/shared/reducers/user.selectors';
import { ConfirmationConnectionService } from '@app/shared/components/confirmation-connection/confirmation-connection.service';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass, NgIf } from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { SvgIconComponent } from 'angular-svg-icon';
import { MatLineModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { ProfileApprovedStatus } from '@app/shared/models/current-user';
import { FilterV2Service } from '@app/shared/services/filter-v2.service';
import { TermsConditionsValidator } from '@app/shared/validators/privacy-policy.validator';
import { ReferralService } from '@app/account/containers/referral/referral.service';

@Component({
  selector: 'gingr-dialog-login-register',
  templateUrl: './dialog-login-register.component.html',
  styleUrls: ['./dialog-login-register.component.scss'],
  standalone: true,
  imports: [MatListModule, MatLineModule, SvgIconComponent, L10nTranslatePipe],
})
export class DialogLoginRegisterComponent {
  name: string;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public dialog: MatDialog
  ) {}

  public openDialog(type: any): void {
    let dialogRef;

    if (typeof type === 'object') {
      dialogRef = this.dialog.open(DialogRegisterRoleComponent, {
        data: { type: type },
        panelClass: 'register-dialog--panel',
        autoFocus: false,
      });
    } else if (typeof type === 'string' && type === 'login') {
      dialogRef = this.dialog.open(DialogLoginComponent, {
        data: { type: type },
        panelClass: 'login-dialog--panel',
        autoFocus: false,
        width: '480px',
      });
    } else {
      dialogRef = this.dialog.open(DialogLoginRegisterDialogComponent, {
        data: { type: type },
        panelClass: 'register-dialog--panel',
        autoFocus: false,
      });
    }

    dialogRef.afterClosed().subscribe(() => {});
  }
}

@Component({
  selector: 'gingr-dialog-register-role',
  templateUrl: './dialog-register-role.component.html',
  styleUrls: ['./dialog-register-role.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    FlexModule,
    SvgIconComponent,
    L10nTranslatePipe,
    NgIf,
  ],
})
export class DialogRegisterRoleComponent implements OnInit, OnDestroy {
  @Input() lang: string;
  name: string;
  public tabTypes = DialogLoginRegisterDialogComponent.tabsTypes;
  private subscriptions: Subscription[] = [];
  private historyStatePushed: boolean = false;
  ggCoinUrl = this.configService.config.ggcoinDomain;

  constructor(
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private configService: ConfigService,
    public dialogRef: MatDialogRef<DialogRegisterRoleComponent>,
    public observableMedia: MediaObserver,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.subscriptions.push(
      this.dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          if (this.data && this.data.refer && this.data.refer === 'login') {
            this.dialog.open(DialogLoginComponent, {
              data: { type: this.data.type },
              panelClass: 'login-dialog--panel',
              autoFocus: false,
            });
          }
        }
      })
    );
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    let resetStyleFunc;
    this.subscriptions.push(
      this.observableMedia.asObservable().subscribe((change) => {
        if (change[0].mqAlias === 'xs') {
          resetStyleFunc = DialogLoginComponent.fullScreenOverlay();
        } else if (resetStyleFunc) {
          resetStyleFunc();
          resetStyleFunc = undefined;
        }
      })
    );

    if (this.data?.type && this.data?.roleName) {
      this.openDialog(this.data.type, this.data.roleName);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  openDialog(type: string, role: string): void {
    const params = { type: type };

    if (
      type ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationICO.dataType &&
      role === DialogLoginRegisterDialogComponent.tabsTypes.registrationICO.role
    ) {
      const icoDomain = this.configService.config.ggcoinDomain
        ? this.configService.config.ggcoinDomain
        : 'https://ggcoin.io';
      window.open(icoDomain, '_blank');
    } else {
      if (
        UserAvailableRoles[role] !== undefined &&
        UserAvailableRoles[role] !== null
      ) {
        Object.assign(params, { role: UserAvailableRoles[role] });
      }
      const dialogRef = this.dialog.open(DialogLoginRegisterDialogComponent, {
        data: params,
        panelClass: 'register-dialog--panel',
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.dialogRef.close();
      });
    }
    this.dialogRef.close({ goTo: 'register' });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialog-login-register',
  templateUrl: './new-register-dialog.component.html',
  styleUrls: ['./dialog-login-register-dialog.component.scss'],
  providers: [RegisterService, RegUserClient, MatTabGroup],
  standalone: true,
  imports: [
    ExtendedModule,
    NgIf,
    MatIconModule,
    FlexModule,
    SvgIconComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    L10nTranslatePipe,
    RouterLink,
  ],
})
export class DialogLoginRegisterDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public static tabsTypes = {
    login: {
      index: 0,
      dataType: 'login',
      icon: 'login',
    },
    registrationClient: {
      index: 1,
      dataType: 'registration',
      role: 'client',
      icon: 'client',
    },
    registrationGinger: {
      index: 1,
      dataType: 'registration',
      role: 'gingr',
      icon: 'gingr',
    },
    registrationBrothels: {
      index: 1,
      dataType: 'registration',
      role: 'brothels',
      icon: 'establishment',
    },
    registrationAgency: {
      index: 1,
      dataType: 'registration',
      role: 'agency',
      icon: 'agency',
    },
    registrationICO: {
      index: 1,
      dataType: 'registration',
      role: 'ico',
    },
  };

  @Input() lang: string;

  public popupTitle: string;
  public popupTitleSize: boolean = false;
  public popupRoleImage: string;

  dialogType: number = 0;
  submittingForm: boolean = false;
  step: number = 0;
  showPassword = false;
  showConfirmPassword = false;

  clientForm: UntypedFormGroup;

  validators;
  // Validation
  emailFormControl: UntypedFormControl = this.validator.EmailFormControl();
  // Password
  passwordFormControl: UntypedFormControl =
    this.validator.PasswordFormControl();
  // Password Confirm
  passwordCFormControl: UntypedFormControl =
    this.validator.PasswordConfirmFormControl();
  // Required
  requiredFormControl = this.validator.RequiredFormControl();
  // Custom Validation
  customFormControl: UntypedFormControl = this.validator.CustomControl([
    'required',
    'minLength(8)',
  ]);
  // captcha
  captchaFormControl = this.validator.CaptchaFormControl();

  isSubmitting = false;
  errors: Errors = new Errors();

  userLocation = {
    latitude: <number | boolean>false,
    longitude: <number | boolean>false,
  };
  tabsTypesData: any;
  public countryPhoneCodesList: Array<UserPhonePrefix>;

  public regClient: any;
  public confirmEmail: boolean = false;

  filteredStates: Observable<any[]>;

  private destroy$ = new Subject<boolean>();

  private readonly router = inject(Router);
  private referralService = inject(ReferralService);

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private locationService: LocationService,
    private store: Store<any>,
    private validator: ValidatorService,
    private userSettingsHelper: UserSettingsHelperService,
    private fb: UntypedFormBuilder,
    private notify: NotificationService,
    private changes: ChangeDetectorRef,
    private translate: L10nTranslationService,
    private observableMedia: MediaObserver,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogLoginRegisterDialogComponent>,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cognitoService: CognitoService
  ) {}

  confirmEmailDialog() {
    this.confirmEmail = true;
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialog.closeAll();
    });
  }

  ngAfterViewInit() {
    DialogLoginComponent.overflowY();
    if (this.clientForm.controls['prefix_phone']) {
      this.filteredStates = this.clientForm.controls[
        'prefix_phone'
      ].valueChanges.pipe(
        startWith(''),
        map((item) => {
          return item
            ? this.filterStates(item)
            : this.countryPhoneCodesList.slice();
        })
      );
      this.changes.detectChanges();
    }
  }

  navigateAway(url: string) {
    this.router.navigate([url]);
    this.dialogRef.close(true);
  }

  filterStates(name: string) {
    return this.countryPhoneCodesList.filter((item: any) => {
      item.prefix += '';
      if (typeof name === 'string') {
        return (
          item.countryName.toLowerCase().indexOf(name.toLowerCase()) === 0 ||
          item.prefix.toLowerCase().indexOf(name.toLowerCase()) === 0
        );
      } else {
        return item.prefix === name;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.tabsTypesData = DialogLoginRegisterDialogComponent.tabsTypes;
    this.validators = this.validator.getValidators();
    let resetStyleFunc;
    this.observableMedia
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((change) => {
        if (change[0].mqAlias === 'xs') {
          resetStyleFunc = DialogLoginComponent.fullScreenOverlay();
        } else if (resetStyleFunc) {
          resetStyleFunc();
          resetStyleFunc = undefined;
        }
      });

    this.locationService.currentDeviceLocation
      .pipe(takeUntil(this.destroy$))
      .subscribe((position) => {
        this.userLocation.latitude = position.latitude;
        this.userLocation.longitude = position.longitude;
        this.setLocation();
      });

    if (
      this.data.type ===
      DialogLoginRegisterDialogComponent.tabsTypes.login.dataType
    ) {
      this.dialogType = 0;
    } else {
      this.dialogType = 1;
      this.initRegistrationForm();
    }

    this.dialogRef.afterClosed().subscribe((afterClosed) => {
      if (afterClosed === undefined && !this.data.single) {
        this.dialog.open(DialogRegisterRoleComponent, {
          panelClass: 'register-dialog--panel',
          autoFocus: false,
        });
      }
    });
  }

  public initRegistrationForm(): void {
    let additionalFields: any = {};
    if (
      this.data.type ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationClient
          .dataType &&
      this.data.role.baseRoleName ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationClient.role
    ) {
      this.regClient = new RegUserClient();
      additionalFields.location = this.fb.group({
        latitude: new UntypedFormControl({
          value: this.userLocation.latitude,
          disabled: false,
        }),
        longitude: new UntypedFormControl({
          value: this.userLocation.longitude,
          disabled: false,
        }),
      });
      additionalFields.privacy_policy = new UntypedFormControl(false, [
        Validators.required,
        PrivacyPolicyValidator,
      ]);
      additionalFields.terms_conditions = new UntypedFormControl(false, [
        Validators.required,
        TermsConditionsValidator,
      ]);

      this.popupTitle = this.translate.translate(
        _t('DIALOG-LOGIN-REGISTER.Client')
      );
      this.popupRoleImage =
        DialogLoginRegisterDialogComponent.tabsTypes.registrationClient.icon;
    } else if (
      this.data.type ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationGinger
          .dataType &&
      this.data.role.baseRoleName ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationGinger.role
    ) {
      this.regClient = new RegUserGingr();
      additionalFields = this.getAdditionalRegFieldsByRole();
      this.popupTitle = this.translate.translate(
        _t('DIALOG-LOGIN-REGISTER.Escort')
      );
      this.popupRoleImage =
        DialogLoginRegisterDialogComponent.tabsTypes.registrationGinger.icon;
    } else if (
      this.data.type ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationAgency
          .dataType &&
      this.data.role.baseRoleName ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationAgency.role
    ) {
      this.regClient = new RegUserAgency();
      additionalFields = this.getAdditionalRegFieldsByRole();
      this.popupTitle = this.translate.translate(
        _t('DIALOG-LOGIN-REGISTER.Agency')
      );
      this.popupRoleImage =
        DialogLoginRegisterDialogComponent.tabsTypes.registrationAgency.icon;
    } else if (
      this.data.type ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationBrothels
          .dataType &&
      this.data.role.baseRoleName ===
        DialogLoginRegisterDialogComponent.tabsTypes.registrationBrothels.role
    ) {
      this.regClient = new RegUserBrothel();
      additionalFields = this.getAdditionalRegFieldsByRole();
      this.popupTitle = this.translate.translate(
        _t('DIALOG-LOGIN-REGISTER.Establishment')
      );
      this.popupRoleImage =
        DialogLoginRegisterDialogComponent.tabsTypes.registrationBrothels.icon;
      this.popupTitleSize = true;
    }

    this.store
      .pipe(select(getAllLanguageList), takeUntil(this.destroy$), take(1))
      .subscribe((languages) => {
        const formFields = Object.assign(
          {},
          {
            language: new UntypedFormControl({
              value: this.userSettingsHelper.getLanguageIdByLocale(
                languages,
                this.translate.getLocale().language
              ),
              disabled: false,
            }),
            email: this.customFormControl,
            passwords: new UntypedFormGroup({
              // in new group to have access to other inputs
              password: this.passwordFormControl,
              passwordc: this.passwordCFormControl,
            }),
            promocode: new UntypedFormControl(),
            location: new UntypedFormControl(),
            privacy_policy: new UntypedFormControl('', [Validators.required]),
            terms_conditions: new UntypedFormControl('', [Validators.required]),
          },
          additionalFields
        );

        //  init registration form
        this.clientForm = new UntypedFormGroup(formFields);
        this.setLocation();
        this.clientForm.valueChanges.subscribe(
          () => {},
          () => {}
        );
      });
  }

  public markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public setLocation() {
    if (
      this.clientForm instanceof UntypedFormGroup &&
      this.clientForm.controls &&
      this.clientForm.controls['location'] !== undefined
    ) {
      this.clientForm.controls['location'].setValue({
        latitude: this.userLocation.latitude,
        longitude: this.userLocation.longitude,
      });
    }
  }

  public getAdditionalRegFieldsByRole() {
    const formFields: any = {};
    if (
      this.data.role !== undefined &&
      this.data.role !==
        DialogLoginRegisterDialogComponent.tabsTypes.registrationClient.role
    ) {
      formFields.privacy_policy = new UntypedFormControl(false, [
        Validators.required,
        PrivacyPolicyValidator,
      ]);
      formFields.terms_conditions = new UntypedFormControl(false, [
        Validators.required,
        TermsConditionsValidator,
      ]);
    }

    return formFields;
  }

  /**
   * registration form check additional fields
   * @param {string} fieldName
   * @returns {boolean}
   */
  public showAdditionalField(fieldName: string): boolean {
    let result = false;
    if (
      this.clientForm instanceof UntypedFormGroup &&
      this.clientForm.controls[fieldName] !== undefined
    ) {
      result = true;
    }

    return result;
  }

  registerClient() {
    if (!this.clientForm.valid || this.submittingForm) {
      return;
    }
    this.submittingForm = true;

    const hostnameSplit = window.location.hostname.split('.');
    const domainName = hostnameSplit[hostnameSplit.length - 1];

    const newUser: CognitoRegisterModel = {
      username: this.clientForm.value.email,
      password: this.clientForm.value.passwords.password,
      location: this.clientForm.value.location,
      role: this.regClient.role,
      language: this.clientForm.value.language,
      countryCode: domainName,
    };

    this.cognitoService.registerBasic(newUser).then(
      (param: any) => {
        this.submittingForm = false;
        this.confirmEmailDialog();
        const referralCode = localStorage.getItem('referralCode');
        if (referralCode) {
          this.referralService
            .linkUserToReferral({
              newUserIdPid: param.userSub,
              referralCode: +referralCode,
            })
            .subscribe((result) => {
              if (result) {
                return;
              }

              localStorage.removeItem('referralCode');
            });
        }
      },
      (error) => {
        this.errors = error;
        this.submittingForm = false;
        this.isSubmitting = false;

        this.notify.error(
          'Error',
          error?.message || 'Problem with registering'
        );
      }
    );
  }

  resendEmailConfirm() {
    this.cognitoService.resendConfirmationCode(this.clientForm.value.email);
  }

  closeAllDialog() {
    this.dialog.closeAll();
    this.dialogRef.close({ goTo: false });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'gingr-dialog-login',
  templateUrl: './new-dialog-login.component.html',
  styleUrls: ['./dialog-login-register.component.scss'],
  providers: [LoginUser],
  standalone: true,
  imports: [
    FlexModule,
    MatIconModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    NgClass,
    ExtendedModule,
    SvgIconComponent,
    L10nTranslatePipe,
  ],
})
export class DialogLoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  passwordConfirmForm: UntypedFormGroup;
  submittingForm: boolean = false;
  private historyStatePushed: boolean = false;
  public isXs: boolean;
  public loginReadOnly: boolean = true;
  // Password
  passwordFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  // Email
  emailFormControl: UntypedFormControl = this.validator.RequiredFormControl();

  isSubmitting: boolean = false;
  errors: Errors = new Errors();
  showEmailNotConfirmed = false;
  showChangePassword = false;
  cognitoUserObj;
  showPassword = false;
  profileDetails = false;

  private destroy$ = new Subject<boolean>();

  public static fullScreenOverlay() {
    let modal: any = document.getElementsByClassName('cdk-overlay-pane');
    modal = modal[modal.length - 1];
    if (modal) {
      const styles = Object.assign({}, modal.style);
      modal.style.height = '100%';
      modal.style.width = '100%';
      modal.style.maxWidth = '100%';
      return () => {
        modal.style = Object.assign({}, styles);
      };
    }
  }

  public static overflowY() {
    let modal: any = document.getElementsByClassName('mat-dialog-container');
    modal = modal[modal.length - 1];
    modal.style.overflow = 'scroll';
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private localeService: L10nTranslationService,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private store: Store<any>,
    public dialog: MatDialog,
    private validator: ValidatorService,
    private router: Router,
    private modalService: ModalService,
    private observableMedia: MediaObserver,
    private notify: NotificationService,
    protected filling: InformationFillingService,
    public loginUser: LoginUser,
    public dialogRef: MatDialogRef<DialogLoginRegisterDialogComponent>,
    private cognitoService: CognitoService,
    private confirmationConnectionService: ConfirmationConnectionService,
    private translate: L10nTranslationService,
    private filterV2Service: FilterV2Service
  ) {}

  ngOnInit() {
    this.initLoginForm();
    let resetStyleFunc;
    this.observableMedia
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((change) => {
        this.isXs = this.observableMedia.isActive('xs');
        if (change[0].mqAlias === 'xs') {
          resetStyleFunc = DialogLoginComponent.fullScreenOverlay();
        } else if (resetStyleFunc) {
          resetStyleFunc();
          resetStyleFunc = undefined;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initLoginForm() {
    this.loginForm = new UntypedFormGroup({
      email: this.emailFormControl,
      password: this.passwordFormControl,
    });

    this.passwordConfirmForm =
      this.confirmationConnectionService.passwordFormGroup();
  }

  submitLogin() {
    if (this.isSubmitting) {
      return;
    }
    const credentials = this.loginForm.value;

    this.isSubmitting = true;
    this.showEmailNotConfirmed = false;
    this.errors = new Errors();

    this.cognitoService
      .loginBasic(credentials.email, credentials.password)
      .then(
        (data) => {
          if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.showChangePassword = true;
            this.cognitoUserObj = data;
          } else if (data?.signInUserSession) {
            this.finishLogin();
          } else {
            this.errorLogin();
          }
          this.isSubmitting = false;
        },
        (error) => {
          if (error.code === 'UserNotConfirmedException') {
            this.showEmailNotConfirmed = true;
          } else {
            this.errorLogin();
          }
          this.isSubmitting = false;
        }
      );
  }

  setPassword() {
    this.isSubmitting = true;

    this.cognitoService
      .completeNewPassword(
        this.cognitoUserObj,
        this.passwordConfirmForm.value.password
      )
      .then(
        () => {
          this.isSubmitting = false;
          this.finishLogin();
        },
        () => (this.isSubmitting = false)
      );
  }

  finishLogin() {
    this.cognitoService.checkUserSession();
    this.dialogRef.close();
    this.dialog.closeAll();

    this.store
      .pipe(
        select(getCurrentUser),
        filter((user) => !!user),
        take(1)
      )
      .subscribe((user) => {
        if (this.profileDetails) {
          return;
        }

        let navigateUrl = '/gingrs';
        if (
          user.role === UserRoles.CLIENT_BASIC ||
          user.role === UserRoles.CLIENT_PRO
        ) {
          navigateUrl =
            user.profileApprovedStatus === ProfileApprovedStatus.APPROVED
              ? '/gingrs'
              : user.profileApprovedStatus ===
                  ProfileApprovedStatus.PENDING_APPROVAL
                ? '/account/profile'
                : '/account/profile/phone';
        }

        if (
          user.role === UserRoles.SERVICE_PROVIDER_BASIC ||
          user.role === UserRoles.SERVICE_PROVIDER_PRO
        ) {
          navigateUrl = '/account/profile';
        } else if (
          user.role === UserRoles.CLIENT_BASIC ||
          (user.role === UserRoles.CLIENT_PRO &&
            this.router.url.includes('/gingrs'))
        ) {
          this.filterV2Service.updateSearch(null, 0);
        }
        this.router.navigate([navigateUrl]);
      });
  }

  errorLogin(translationId?: string) {
    this.notify.error(
      'Error',
      this.localeService.translate(
        _t(translationId || 'DIALOG-LOGIN-REGISTER.Incorrect_Login')
      )
    );
  }

  resendEmailConfirm() {
    this.showEmailNotConfirmed = false;
    this.cognitoService.resendConfirmationCode(this.loginForm.value.email);
  }

  goToRegistration(type: string): void {
    this.modalService.openDialog(
      DialogRegisterRoleComponent,
      {
        type: type,
        role: UserAvailableRoles.client,
        refer: 'login',
      },
      'register-dialog--panel'
    );
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  forgotPass(): boolean {
    this.dialogRef.close();
    this.modalService.openDialog(
      DialogForgotPasswordComponent,
      {},
      'forgot-password--panel'
    );
    return false;
  }
}

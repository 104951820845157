import { inject, Injectable } from '@angular/core';
import { Config } from '../../shared/models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private readonly http = inject(HttpClient);
  private readonly configService = inject(ConfigService);

  getAppConfig(): Observable<Config> {
    return this.http.get<Config>(`${this.configService.config.apiV3}/config`);
  }
}

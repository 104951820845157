import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout/flex';
import { isBot } from '@app/shared/helpers/user-agent.helper';

@Component({
  selector: 'location-selector-insta',
  templateUrl: './location-selector-insta.component.html',
  styleUrls: ['./location-selector-insta.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatIconModule,
  ],
})
export class LocationSelectorInstaComponent implements OnInit, OnDestroy {
  @Input() locationTextLabel: string = 'Locating...';
  @Output() place: EventEmitter<any> = new EventEmitter();

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  private disableUnfocus: boolean = false;
  private destroy$ = new Subject<boolean>();

  constructor(private ngZone: NgZone) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.initPlaces();
  }

  initPlaces() {
    if (isBot()) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      {}
    );
    autocomplete.addListener('place_changed', () => {
      this.disableUnfocus = true;
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.place.emit(place);
        this.locationTextLabel = place.formatted_address;
      });
    });
  }

  deleteLocation() {
    this.locationTextLabel = null;
    this.place.emit(null);
  }

  unfocus() {
    setTimeout(() => {
      if (this.disableUnfocus) {
        this.disableUnfocus = false;
      } else {
        this.getPredictions();
      }
    }, 200);
  }

  getPredictions() {
    const geocode = new google.maps.Geocoder();
    const auto = new google.maps.places.AutocompleteService();
    if (this.locationTextLabel) {
      auto.getPlacePredictions(
        {
          input: this.locationTextLabel,
        },
        (places) => {
          const place = places[0];
          geocode.geocode({ placeId: place.place_id }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const placeName = results[0].formatted_address;
              this.place.emit(results[0]);
              this.locationTextLabel = placeName;
            }
          });
        }
      );
    }
  }
}

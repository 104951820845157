import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'gingr-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatIconModule, MatFormFieldModule]
})
export class FormFieldComponent implements OnInit {
  @Input() matIcon: string = null;
  // @Input() inputType: string = 'text';
  // @Input() placeholder: string = null;
  // @Input() formControl: FormControl;

  constructor() { }

  ngOnInit() {
  }

}

<div
  class="filter bg-base-100 z-[100] fixed right-0 bottom-0 w-full md:w-[390px] top-0 md:top-[64px]"
>
  <div
    class="flex bg-base-200 shadow relative p-[19px] items-center justify-center"
    *ngIf="mobile"
  >
    <div class="capitalize text-[19px]">
      {{ 'FILTER.Filter' | translate: locale.language }}
    </div>
    <div (click)="filter()" class="absolute top-[26px] left-5">
      <img alt="close" src="assets/icons/back-white-arrow.svg" />
    </div>
  </div>

  <div class="fixedContent flex justify-between shadow">
    <div
      (click)="locationSelected = false"
      [ngClass]="{
        selected: !locationSelected
      }"
      class="option w-100 text-center pb-[19px] pt-[22px] cursor-pointer relative"
    >
      <span
        class="capitalize text-white"
        [class.text-primary]="locationSelected === false"
      >
        {{ 'INSTA.Offer' | translate: locale.language }}
      </span>
    </div>
    <div
      (click)="locationSelected = true"
      [ngClass]="{
        selected: locationSelected
      }"
      class="option w-100 text-center pb-[19px] pt-[22px] cursor-pointer relative"
    >
      <span
        class="capitalize text-white"
        [class.text-primary]="locationSelected === true"
      >
        {{ 'ACCOUNT.Location' | translate: locale.language }}</span
      >
    </div>
  </div>

  <div
    class="scroll-content overflow-auto"
    *ngIf="!locationSelected && formGroupLoaded"
  >
    <div class="content">
      <ng-container [formGroup]="formGroupTopTypes">
        <div class="px-5 pt-[32px] pb-[48px]">
          <div class="input-wrapper relative">
            <input
              formControlName="search"
              type="text"
              class="py-2.5 ps-2.5 pe-[40px] border-primary border-solid border-2 w-full text-white rounded bg-transparent focus:outline-0 filter-input placeholder:text-[#ffffff]"
              placeholder="{{ 'FILTER.Search' | translate: locale.language }}"
            />
            <img
              src="/assets/icons/filter-search.svg"
              class="searchIco absolute top-[15px] right-[10px]"
              alt="SearchIco"
            />
          </div>
        </div>
        <div class="list px-5 mb-[50px]">
          <div class="flex justify-between mb-4">
            <div class="flex w-100">
              <svg-icon
                src="assets/svg-icons/filters/filter-new-my-place.svg"
                [svgStyle]="{
                  'width.px': 20,
                  'height.px': 20,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name ms-[16px]"
                [ngClass]="
                  formGroupTopTypes.controls['myPlace']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'HOOK-UP.My place' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="myPlace"
                class="checkbox"
                id="myPlace"
              />
            </div>
          </div>
          <div class="check-list flex justify-between mb-[16px]">
            <div class="flex w-100">
              <svg-icon
                src="assets/svg-icons/filters/filter-new-their-place.svg"
                [svgStyle]="{
                  'width.px': 20,
                  'height.px': 20,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name ms-[16px]"
                [ngClass]="
                  formGroupTopTypes.controls['theirPlace']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'HOOK-UP.Their place' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="theirPlace"
                class="checkbox"
                id="theirPlace"
              />
            </div>
          </div>
        </div>
        <div class="list px-5">
          <div class="check-list flex justify-between mb-[16px]">
            <div class="flex w-100 items-center">
              <svg-icon
                src="assets/svg-icons/filters/filter-new-ready-now.svg"
                [svgStyle]="{
                  'width.px': 20,
                  'height.px': 20
                }"
                class="item-icon"
              ></svg-icon>

              <span
                class="check-list_name ms-[16px]"
                [ngClass]="
                  formGroupTopTypes.controls['readyNow']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'COMMON.Ready Now' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="readyNow"
                class="checkbox"
                id="readyNow"
              />
            </div>
          </div>
          <div class="check-list flex justify-between mb-[16px]">
            <div class="flex w-100 items-center">
              <svg-icon
                src="/assets/svg-icons/filters/filter-new-favourites.svg"
                [svgStyle]="{
                  'width.px': 20,
                  'height.px': 20,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name ms-[16px]"
                [ngClass]="
                  formGroupTopTypes.controls['favourites']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'COMMON.FAVOURITES' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="favourites"
                class="checkbox"
                id="favourites"
              />
            </div>
          </div>
          <div class="check-list flex justify-between mb-[16px]">
            <div class="flex items-center">
              <svg-icon
                src="/assets/svg-icons/filters/filter-new-new.svg"
                [svgStyle]="{
                  'width.px': 20,
                  'height.px': 20,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name ms-[16px]"
                [ngClass]="
                  formGroupTopTypes.controls['new']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
              >
                {{ 'COMMON.NEW' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="new"
                class="checkbox"
                id="new"
              />
            </div>
          </div>
          <div class="check-list flex justify-between mb-[16px]">
            <div class="flex items-center">
              <svg-icon
                src="/assets/svg-icons/filters/filter-new-verified.svg"
                [svgStyle]="{
                  'width.px': 20,
                  'height.px': 20,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name capitalize ms-[16px]"
                [ngClass]="
                  formGroupTopTypes.controls['verified']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'COMMON.VERIFIED' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="verified"
                class="checkbox"
                id="verified"
              />
            </div>
          </div>
          <div class="check-list flex justify-between">
            <div class="flex items-center">
              <svg-icon
                src="/assets/svg-icons/filters/filter-new-video.svg"
                [svgStyle]="{
                  'width.px': 20,
                  'height.px': 20,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name ms-[16px]"
                [ngClass]="
                  formGroupTopTypes.controls['video']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'FILTER.VIDEO' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="video"
                class="checkbox"
                id="video"
              />
            </div>
          </div>
        </div>
        <div class="clip"></div>
        <div
          class="px-5 rounded-0 bg-base-300 py-5"
          [class.pb-[50px]]="!locationSelected"
        >
          <div>
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('rating')"
            >
              <div class="flex flex-row items-center">
                <div class="w-5 h-5 flex justify-center items-center">
                  <svg-icon
                    src="/assets/svg-icons/filters/filter-new-rating.svg"
                    [svgStyle]="{
                      'width.px': 20,
                      'height.px': 20,
                      fill: '#ffd640'
                    }"
                  >
                  </svg-icon>
                </div>
                <span
                  class="text capitalize ms-[16px]"
                  [ngClass]="
                    this.rating && this.rating !== 1
                      ? 'text-primary'
                      : 'text-white'
                  "
                  >{{ 'FILTER.Rating' | translate: locale.language }}</span
                >
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.rating"
                  [class.rotate-[-90deg]]="expanded.rating"
                />
              </div>
            </div>
            <div class="collapse-slider py-8 ps-9" *ngIf="expanded.rating">
              <ngx-slider
                [(value)]="rating"
                [options]="ratingOptions"
                (userChangeEnd)="changeRating()"
              ></ngx-slider>
            </div>
          </div>
          <div [formGroup]="genderFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('gender')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-gender.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.GENDER' | translate: locale.language
                }}</span>
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.gender"
                  [class.rotate-[-90deg]]="expanded.gender"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.gender">
              <div class="list panel pt-[17px] pb-[32px] ps-[36px]">
                <div
                  class="check-list flex justify-between mb-[16px] last:mb-0"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let gender of config.genders; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title capitalize"
                        [ngClass]="
                          genderFormGroup.controls[gender.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'COMMON.SEX.' + gender.name
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="gender.id"
                      class="checkbox"
                      id="gender"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="sexualityFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('sexuality')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-sexuality.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.SEXUALITY' | translate: locale.language
                }}</span>
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.sexuality"
                  [class.rotate-[-90deg]]="expanded.sexuality"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.sexuality">
              <div class="list panel pt-[17px] pb-[32px] ps-[36px]">
                <div
                  class="check-list flex justify-between mb-[16px] last:mb-0"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let sexuality of config.sexualities; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          sexualityFormGroup.controls[sexuality.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'COMMON.SEXUALITY.' + sexuality.name
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="sexuality.id"
                      class="checkbox"
                      id="sexuality"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('price')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-price.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span
                  class="text ms-4"
                  [ngClass]="
                    priceLow !== 0 || priceHigh !== 500
                      ? 'text-primary'
                      : 'text-white'
                  "
                  >{{ 'FILTER.PRICES_HOUR' | translate: locale.language }}</span
                >
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down "
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.price"
                  [class.rotate-[-90deg]]="expanded.price"
                />
              </div>
            </div>
            <div class="collapse-slider py-8 ps-9" *ngIf="expanded.price">
              <ngx-slider
                [(value)]="priceLow"
                [(highValue)]="priceHigh"
                [options]="priceOptions"
                (userChangeEnd)="changePrices()"
              ></ngx-slider>
            </div>
          </div>
          <div>
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('age')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-age.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span
                  class="text ms-4"
                  [ngClass]="
                    ageLow !== 18 || ageHigh !== 100
                      ? 'text-primary'
                      : 'text-white'
                  "
                  >{{ 'FILTER.AGE' | translate: locale.language }}</span
                >
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.age"
                  [class.rotate-[-90deg]]="expanded.age"
                />
              </div>
            </div>
            <div class="collapse-slider py-8 ps-9" *ngIf="expanded.age">
              <ngx-slider
                [(value)]="ageLow"
                [(highValue)]="ageHigh"
                [options]="ageOptions"
                (userChangeEnd)="changeAge()"
              ></ngx-slider>
            </div>
          </div>
          <div class="services-panel" [formGroup]="servicesFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('services')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-services.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.SERVICES' | translate: locale.language
                }}</span>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.services"
                  [class.rotate-[-90deg]]="expanded.services"
                />
              </div>
            </div>
            <div class="w-100 pb-[32px] ps-9" *ngIf="expanded.services">
              <div
                class="content serviceType"
                *ngFor="let serviceType of servicesType"
              >
                <span
                  class="text-primary text-[16px] mb-[16px] block mt-[32px] capitalize"
                  >{{ serviceType.name | translate: locale.language }}</span
                >
                <div
                  class="check-list flex justify-between items-center mb-[16px]"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="
                    let service of servicesByCategory[serviceType.type];
                    let i = index
                  "
                >
                  <div class="language">
                    <div>
                      <span
                        class="title first-letter:capitalize"
                        [ngClass]="
                          servicesFormGroup.controls[service.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'SERVICES.' + service.name + '.NAME'
                            | translate: locale.language
                        }}</span
                      ><span class="capitalize text-white">
                        {{
                          service.activityType === 'ACTIVE'
                            ? ' (' +
                              ('FILTER.Active' | translate: locale.language) +
                              ')'
                            : service.activityType === 'PASSIVE'
                              ? ' (' +
                                ('FILTER.Passive'
                                  | translate: locale.language) +
                                ')'
                              : ''
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="service.id"
                      class="checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="languagesFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('languages')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-languages.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.LANGUAGES' | translate: locale.language
                }}</span>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.languages"
                  [class.rotate-[-90deg]]="expanded.languages"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.languages">
              <div class="list panel pt-[17px] pb-[32px] ps-[36px]">
                <div
                  class="check-list flex justify-between mb-[16px] last:mb-0"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let language of config.languages; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          languagesFormGroup.controls[language.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'COMMON.LANGUAGES.' + language.id
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="language.id"
                      class="checkbox"
                      id="language"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="hairColorFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('hairColor')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-hair-color.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.HAIR_COLOR' | translate: locale.language
                }}</span>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.hairColor"
                  [class.rotate-[-90deg]]="expanded.hairColor"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.hairColor">
              <div class="list panel pt-[17px] pb-[32px] ps-[36px]">
                <div
                  class="check-list flex justify-between mb-[16px] last:mb-0"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let hairColor of config.hairColors; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          hairColorFormGroup.controls[hairColor.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'COMMON.HAIRCOLORS.' + hairColor.name
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="hairColor.id"
                      class="checkbox"
                      id="hairColor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="ethnicityFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('ethnicity')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-ethnicity.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.ETHNICITY' | translate: locale.language
                }}</span>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.ethnicity"
                  [class.rotate-[-90deg]]="expanded.ethnicity"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.ethnicity">
              <div class="list panel pt-[17px] pb-[32px] ps-[36px]">
                <div
                  class="check-list flex justify-between mb-[16px] last:mb-0"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let origin of config.origins; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          ethnicityFormGroup.controls[origin.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'ETHNICITY.' + origin.name
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="origin.id"
                      class="checkbox"
                      id="ethnicity"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="bodyTypeFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('bodyType')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-body-type.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.BODY_TYPE' | translate: locale.language
                }}</span>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.bodyType"
                  [class.rotate-[-90deg]]="expanded.bodyType"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.bodyType">
              <div class="list panel pt-[17px] pb-[32px] ps-[36px]">
                <div
                  class="check-list flex justify-between mb-[16px] last:mb-0"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let bodyType of config.bodies; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          bodyTypeFormGroup.controls[bodyType.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'BODYTYPES.' + bodyType.name
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="bodyType.id"
                      class="checkbox"
                      id="bodyType"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="breastSizeFormGroup">
            <div
              class="flex justify-between collapse-parent mb-4 items-center cursor-pointer"
              (click)="expandItem('breastSize')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  src="/assets/svg-icons/filters/filter-new-breast-size.svg"
                  [svgStyle]="{
                    'width.px': 20,
                    'height.px': 20,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <span class="text text-white ms-4">{{
                  'FILTER.Breast_size' | translate: locale.language
                }}</span>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down rotate-90 cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.breastSize"
                  [class.rotate-[-90deg]]="expanded.breastSize"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.breastSize">
              <div class="list panel pt-[17px] pb-[32px] ps-[36px]">
                <div
                  class="check-list flex justify-between mb-[16px] last:mb-0"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let breastSize of breastSizes; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          breastSizeFormGroup.controls[breastSize]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{ breastSize }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="breastSize"
                      class="checkbox"
                      id="breastSize"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="locationSelected"
    class="pt-[32px] px-5 w-sm-screen scroll-content overflow-auto pb-12"
  >
    <ng-container *ngIf="!enterSpecificAddress">
      <div
        class="py-2.5 px-2.5 border-primary border-solid border-2 w-full text-white rounded bg-transparent focus:outline-0 text-center cursor-pointer"
        (click)="askForLocationAccess()"
      >
        {{
          ('COMMON.Nearby' | translate: locale.language) +
            (showRadiusSlider ? ' (' + selectedRadius + 'km)' : '')
        }}
      </div>
      <div (click)="enterAddress()" class="flex mt-[11px] cursor-pointer">
        <svg-icon
          src="assets/images/filters/pin.svg"
          [svgStyle]="{ 'width.px': 15, 'height.px': 22 }"
          class="item-icon"
        ></svg-icon>
        <span class="ms-3">{{
          'COMMON.Enter address' | translate: locale.language
        }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="enterSpecificAddress">
      <div class="text-primary pb-[6px]">
        {{ 'BOOKINGS.Meeting Point' | translate: locale.language }}
      </div>
      <div>
        <location-selector-insta
          (place)="locationSelectorChanged($event)"
          [locationTextLabel]="address"
          class="w-full cursor-pointer"
        >
        </location-selector-insta>
      </div>
      <div
        (click)="enterSpecificAddressControl()"
        class="flex mt-[11px] cursor-pointer"
      >
        <svg-icon
          src="assets/images/filters/pin.svg"
          [svgStyle]="{ 'width.px': 15, 'height.px': 22 }"
          class="item-icon"
        ></svg-icon>
        <span class="ms-3">{{
          'COMMON.In my area' | translate: locale.language
        }}</span>
      </div>
    </ng-container>

    <div class="collapse-slider mt-[18px]" *ngIf="showRadiusSlider">
      <ngx-slider
        [(value)]="selectedRadius"
        [options]="radiusOptions"
        (valueChange)="changeRadius($event)"
      ></ngx-slider>
    </div>
    <div class="pb-8">
      <div class="text-primary text-[18px] mb-8 mt-[50px]">
        {{ 'COMMON.REGIONS' | translate: locale.language }}
      </div>

      <div class="grid grid-cols-2 gap-4 text-white">
        <div *ngFor="let region of filterRegions" class="flex items-start">
          <input
            type="checkbox"
            class="checkbox"
            [ngModel]="region.selected"
            (ngModelChange)="selectRegion(region)"
          />

          <span class="ms-[12px]">{{ region.label }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="flex fixed bottom-[74px] px-[20px] w-100 md:w-[390px] justify-end text-primary cursor-pointer pt-[11px] pb-1 resetButton items-center shadow-[0_-3px_6px_0_rgb(0,0,0,0.16)] bg-base-300 z-50"
    (click)="resetFilter()"
  >
    <img
      alt="reset"
      src="assets/icons/resetFilters.svg"
      class="reset me-2"
      width="20"
      height="20"
    />
    {{ 'FILTER.Reset_Filter' | translate: locale.language }}
  </div>
  <div class="fixedBottom">
    <gingr-footer-button
      class="capitalize"
      [shadow]="false"
      [text]="
        '(' +
        (resultsCount$ | async) +
        ') ' +
        (((resultsCount$ | async) === 1 ? 'FILTER.Result' : 'FILTER.Results')
          | translate: locale.language)
      "
      (clicked)="filter()"
    ></gingr-footer-button>
  </div>
</div>

<div class="filterBg hidden md:block" (click)="filter()"></div>

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AccountSaveNextBarComponent } from '@app/account/components';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { _t } from '@app/shared/helpers';
import { InformationFillingService } from '@app/account/services/information-filling.service';
import { EventEmitter as Events } from 'events';
import {
  EstablishmentConnection,
  EstablishmentConnectionStatus,
  User,
  UserAvailableRoles,
} from '@app/shared/models';
import { ManageGingrsService } from '@app/manage-gingrs/services';
import { UserService } from '@app/shared/services';
import { L10nTranslationService } from 'angular-l10n';
import { UserGroups } from '@app/shared/models/enum/user-groups';
import { select, Store } from '@ngrx/store';
import { getManagedUser, getUser } from '@app/shared/reducers/user.selectors';
import { filter, take } from 'rxjs/operators';
import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AccountMenuService implements OnDestroy {
  private $router: Subscription;
  // to emit save action from gingr-account-save-next-bar
  private $actionEmitter: ReplaySubject<string>;
  private actionEmitter: Observable<string>;
  // event to know that data be saved emit('saved')
  public userDataEvents: Events = new Events();
  // variable to know sidenav account status
  private sidenavAccountState$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public sidenavAccountState: Observable<boolean> =
    this.sidenavAccountState$.asObservable();

  private mandatoryStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public mandatoryStatus: Observable<boolean> =
    this.mandatoryStatus$.asObservable();
  // account header text value
  private accountHeader$: BehaviorSubject<string> = new BehaviorSubject(null);
  public accountHeader: Observable<string> = this.accountHeader$.asObservable();
  private user: User;
  private slug: string;
  private formGroup: UntypedFormGroup;
  public saveNextMenuRef: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private filling: InformationFillingService,
    public translation: L10nTranslationService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private manageGignrsService: ManageGingrsService,
    private store: Store<any>,
    @Inject(MatBottomSheet) private saveNextMenu: MatBottomSheet
  ) {
    this.$router = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.saveNextMenuRef) {
          this.saveNextMenuRef.dismiss();
        }

        // this.saveNextMenuRef = null;
        this.subscriptions.forEach((subscription: Subscription) => {
          if (subscription) {
            subscription.unsubscribe();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.$router instanceof Subscription) {
      this.$router.unsubscribe();
    }
  }

  public checkMandatoryStatus(user: User): string {
    let total = 0;
    let dataItems = [];

    const baseRole = UserService.getUserBaseRole(user);

    if (baseRole === UserAvailableRoles.gingr.baseRoleName) {
      dataItems = this.getGingrMenu('mandatory');
    } else if (baseRole === UserAvailableRoles.agency.baseRoleName) {
      dataItems = this.getAgencyMenu('mandatory');
    } else if (baseRole === UserAvailableRoles.brothels.baseRoleName) {
      dataItems = this.getEstablishmentMenu('mandatory');
    }

    const itemLength = dataItems.length;
    dataItems.forEach((item) => {
      if (typeof item['fill_percent'] === 'number') {
        total += item['fill_percent'];
      } else {
        item['fill_percent']
          .subscribe((percent) => {
            total += percent;
          })
          .unsubscribe();
      }
    });

    return ((total / (itemLength * 100)) * 100).toFixed();
  }

  public setHeader(header: string) {
    this.accountHeader$.next(header);
  }

  public updateSidenavState(state: boolean) {
    this.sidenavAccountState$.next(state);
  }

  public openSaveNextMenu(
    formGroup: UntypedFormGroup,
    service: AccountMenuService,
    isLive?: boolean,
    manager?: boolean,
    defaultSettings?: boolean,
    isCustomSetting?: boolean
  ): Observable<string> {
    // const saveNextMenu = inject(MatBottomSheet);

    this.$actionEmitter = new ReplaySubject();
    this.formGroup = formGroup;
    this.actionEmitter = this.$actionEmitter.asObservable();

    this.subscriptions.push(
      this.store
        .pipe(
          select(manager ? getManagedUser : getUser),
          filter((user) => !!user),
          take(1)
        )
        .subscribe((user) => {
          this.user = _.cloneDeep(user);
          this.slug = user.slug;
          this.filling.refresh(user);
          const filledPercent = this.checkMandatoryStatus(user);
          this.mandatoryStatus$.next(filledPercent === '100');
        })
    );

    const launch = () => {
      this.saveNextMenuRef = this.saveNextMenu.open(
        AccountSaveNextBarComponent,
        {
          disableClose: true,
          hasBackdrop: false,
          panelClass: 'account-save-control',
          data: {
            formGroup: this.formGroup,
            service: service,
            isLive: isLive,
            manager: manager,
            defaultSettings: defaultSettings,
            defaultSettingsFilled:
              this.filling.INFORMATION_PARTS.defaultSettings.value$.getValue() ===
              100,
            isCustomSetting: isCustomSetting,
            mandatoryStatusObservable: this.mandatoryStatus,
            slug: this.slug,
            profileApproved: this.user.profile_approved,
          },
        }
      );
    };

    if (this.saveNextMenuRef && this.saveNextMenuRef.instance) {
      this.saveNextMenuRef.dismiss();
      setTimeout(() => {
        launch();
      }, 0);
    } else {
      setTimeout(() => {
        launch();
      }, 0);
    }
    return this.actionEmitter;
  }

  public emitAction(action: string) {
    this.$actionEmitter.next(action);
  }

  public emitSaved() {
    this.userDataEvents.emit('save');
  }

  getMandatoryMenu(
    profileType: UserGroups,
    managedUser?: boolean,
    establishmentConnection?: EstablishmentConnection
  ): Array<any> {
    const returnArray = [];
    let ordering = 1;

    if (
      !(
        profileType === UserGroups.GINGR &&
        managedUser &&
        establishmentConnection?.status === EstablishmentConnectionStatus.FREE
      )
    ) {
      returnArray.push({
        service_name: 'account_details',
        name: this.translation.translate(_t('ACCOUNT.Account Details')),
        fill_percent:
          profileType === UserGroups.GINGR
            ? establishmentConnection?.isManaged
              ? this.filling.INFORMATION_PARTS.connectedGingrAccountDetails
                  .value
              : this.filling.INFORMATION_PARTS.accountDetails.value
            : profileType === UserGroups.ESTABLISHMENT
            ? this.filling.INFORMATION_PARTS.establishmentAccountDetails.value
            : this.filling.INFORMATION_PARTS.agencyAccountDetails.value,
        icon: 'account_details',
        ordering: ordering,
        route: 'account-details',
      });
      ordering++;
    }

    if (
      profileType === UserGroups.GINGR &&
      !(
        managedUser &&
        establishmentConnection?.status === EstablishmentConnectionStatus.FREE
      )
    ) {
      returnArray.push({
        service_name: 'languages',
        name: this.translation.translate(_t('ACCOUNT.Nationality & Languages')),
        fill_percent: this.filling.INFORMATION_PARTS.languagesOrigin.value,
        icon: 'languages_origin',
        ordering: ordering,
        route: 'languages',
      });
      ordering++;
    }

    if (
      !(
        profileType === UserGroups.GINGR &&
        managedUser &&
        establishmentConnection?.status === EstablishmentConnectionStatus.FREE
      )
    ) {
      returnArray.push({
        service_name: 'location',
        name: this.translation.translate(_t('ACCOUNT.Location')),
        fill_percent: this.filling.INFORMATION_PARTS.location.value,
        icon: 'location',
        route: 'location',
        ordering: ordering,
        canBeDefault: 'location',
      });
      ordering++;
    }

    returnArray.push({
      service_name: 'photo_video',
      name: this.translation.translate(_t('ACCOUNT.Photos & Videos')),
      fill_percent: this.filling.INFORMATION_PARTS.media.value,
      icon: 'fotos_videos',
      ordering: ordering,
      route: 'media',
    });
    ordering++;

    if (profileType === UserGroups.GINGR) {
      returnArray.push({
        service_name: 'pricing',
        name: this.translation.translate(_t('ACCOUNT.Pricing')),
        fill_percent: this.filling.INFORMATION_PARTS.pricing.value,
        icon: 'incall_outcall_pricing',
        ordering: ordering,
        route: 'pricing',
        canBeDefault: 'isDefaultPricing',
      });
      ordering++;

      returnArray.push({
        service_name: 'services',
        name: this.translation.translate(_t('ACCOUNT.Services')),
        fill_percent: this.filling.INFORMATION_PARTS.services.value,
        icon: 'services',
        ordering: ordering,
        route: 'services',
        canBeDefault: 'isDefaultServices',
      });
      ordering++;
    }

    if (profileType === UserGroups.ESTABLISHMENT) {
      returnArray.push({
        service_name: 'facilities',
        name: this.translation.translate(_t('ACCOUNT.Facilities')),
        fill_percent: this.filling.INFORMATION_PARTS.facilities.value,
        icon: 'bed',
        ordering: ordering,
        route: 'facilities',
      });
      ordering++;

      returnArray.push({
        service_name: 'availability',
        name: this.translation.translate(_t('ACCOUNT.Opening hours')),
        fill_percent: this.filling.INFORMATION_PARTS.availability.value,
        icon: 'availability',
        route: 'opening-hours',
        ordering: ordering,
      });
      ordering++;
    }

    if (profileType === UserGroups.AGENCY) {
      returnArray.push({
        service_name: 'opening-hours',
        name: this.translation.translate(_t('ACCOUNT.Opening hours')),
        fill_percent: this.filling.INFORMATION_PARTS.availability.value,
        icon: 'availability',
        route: 'opening-hours',
        ordering: ordering,
      });
      ordering++;
    }

    return returnArray;
  }

  getOptionalMenu(
    profileType: UserGroups,
    managedUser?: boolean,
    establishmentConnection?: EstablishmentConnection
  ): Array<any> {
    const returnArray = [];
    let ordering = 1;

    if (profileType === UserGroups.GINGR) {
      returnArray.push({
        service_name: 'availability',
        name: this.translation.translate(_t('ACCOUNT.Availability')),
        fill_percent: this.filling.INFORMATION_PARTS.availability.value,
        icon: 'availability',
        ordering: ordering,
        route: 'availability',
        canBeDefault: 'isDefaultAvailability',
      });
      ordering++;

      returnArray.push({
        service_name: 'personal_details',
        name: this.translation.translate(_t('ACCOUNT.Personal Details')),
        fill_percent: this.filling.INFORMATION_PARTS.personalDetails.value,
        icon: 'personal_details',
        ordering: ordering,
        route: 'personal-details',
      });
      ordering++;
    }

    if (
      profileType === UserGroups.ESTABLISHMENT ||
      profileType === UserGroups.AGENCY
    ) {
      returnArray.push({
        service_name: 'extra-details',
        name: this.translation.translate(_t('ACCOUNT.Extra details')),
        fill_percent: this.filling.INFORMATION_PARTS.extraDetails.value,
        icon: 'extra-details-settings',
        ordering: ordering,
        route: 'extra-details',
      });
      ordering++;
    }

    if (profileType === UserGroups.GINGR) {
      returnArray.push({
        service_name: 'verified_user',
        name: this.translation.translate(_t('ACCOUNT.Verification')),
        fill_percent: establishmentConnection?.isManaged
          ? this.filling.INFORMATION_PARTS.connectedGingrVerification.value
          : this.filling.INFORMATION_PARTS.gingrVerification.value,
        icon: 'verified_user',
        ordering: ordering,
        route: 'verification',
      });
      ordering++;
    }

    if (
      profileType === UserGroups.ESTABLISHMENT ||
      profileType === UserGroups.AGENCY
    ) {
      returnArray.push({
        service_name: 'default-gingr-settings',
        name: this.translation.translate(_t('ACCOUNT.Default gingr settings')),
        fill_percent: this.filling.INFORMATION_PARTS.defaultSettings.value,
        icon: 'default-gingr-settings',
        ordering: ordering,
        transparent: true,
        route: 'default-gingr-settings',
      });
      ordering++;
    }

    return returnArray;
  }

  public getGingrMenu(
    type?: string,
    managedUser?: boolean,
    establishmentConnection?: EstablishmentConnection
  ): Array<any> {
    const mandatory: Array<any> = this.getMandatoryMenu(
      UserGroups.GINGR,
      managedUser,
      establishmentConnection
    );
    const optional: Array<any> = this.getOptionalMenu(
      UserGroups.GINGR,
      managedUser,
      establishmentConnection
    );

    if (!type) {
      return mandatory.concat(optional);
    }
    if (type === 'mandatory') {
      return mandatory;
    }
    if (type === 'optional') {
      return optional;
    }
  }

  public getEstablishmentMenu(type?: string): Array<any> {
    const mandatory: Array<any> = this.getMandatoryMenu(
      UserGroups.ESTABLISHMENT
    );
    const optional: Array<any> = this.getOptionalMenu(UserGroups.ESTABLISHMENT);

    if (!type) {
      return mandatory.concat(optional);
    }
    if (type === 'mandatory') {
      return mandatory;
    }
    if (type === 'optional' || !type) {
      return optional;
    }
  }

  public getAgencyMenu(type?: string): Array<any> {
    const mandatory: Array<any> = this.getMandatoryMenu(UserGroups.AGENCY);
    const optional: Array<any> = this.getOptionalMenu(UserGroups.AGENCY);

    if (!type) {
      return mandatory.concat(optional);
    }
    if (type === 'mandatory') {
      return mandatory;
    }
    if (type === 'optional') {
      return optional;
    }
  }

  public getDefaultGingrSettingsMenu(): Array<any> {
    return [
      {
        service_name: 'availability',
        name: this.translation.translate(_t('ACCOUNT.Availability')),
        fill_percent: this.filling.INFORMATION_PARTS.defaultAvailability.value,
        icon: 'availability',
        route: 'availability',
        ordering: 1,
      },
      {
        service_name: 'pricing',
        name: this.translation.translate(_t('ACCOUNT.Pricing')),
        subName: this.translation.translate(_t('ACCOUNT.(Incall & Outcall)')),
        fill_percent: this.filling.INFORMATION_PARTS.pricing.value,
        icon: 'incall_outcall_pricing',
        route: 'pricing',
        ordering: 2,
      },
      {
        service_name: 'services',
        name: this.translation.translate(_t('ACCOUNT.Services')),
        fill_percent: this.filling.INFORMATION_PARTS.services.value,
        icon: 'services',
        route: 'services',
        ordering: 3,
      },
    ];
  }
  public getEsbalishmentManagedMenu(): any[] {
    return [
      {
        service_name: 'photo_video',
        name: this.translation.translate(_t('ACCOUNT.Photos & Videos')),
        icon: 'fotos_videos',
        ordering: 1,
        route: 'media',
      },
      {
        service_name: 'location',
        name: this.translation.translate(_t('ACCOUNT.Location')),
        icon: 'location',
        ordering: 2,
        route: 'location',
      },
      {
        service_name: 'pricing',
        name: this.translation.translate(_t('ACCOUNT.Pricing')),
        icon: 'incall_outcall_pricing',
        ordering: 3,
        route: 'pricing',
      },
      {
        service_name: 'services',
        name: this.translation.translate(_t('ACCOUNT.Services')),
        icon: 'services',
        ordering: 4,
        route: 'services',
      },
      {
        service_name: 'availability',
        name: this.translation.translate(_t('ACCOUNT.Availability')),
        icon: 'availability',
        ordering: 5,
        route: 'availability',
      },
      {
        service_name: 'personal_details',
        name: this.translation.translate(_t('ACCOUNT.Personal Details')),
        icon: 'personal_details',
        ordering: 6,
        route: 'personal-details',
      },
      {
        service_name: 'verified_user',
        name: this.translation.translate(_t('ACCOUNT.Verification')),
        icon: 'verified_user',
        ordering: 7,
        route: 'verification',
        subName: this.translation.translate(
          _t('WORKPLACE.(Only selfi and ID)')
        ),
      },
    ];
  }
}

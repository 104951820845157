import { AbstractControl } from '@angular/forms';

// Privacy Policy Validator validation function
export function PrivacyPolicyValidator(control: AbstractControl) {
  if (control.value === undefined || control.value === false) {
    return { privacy_policy: true };
  }
  return null;
}

export function TermsConditionsValidator(control: AbstractControl) {
  if (control.value === undefined || control.value === false) {
    return { terms_conditions: true };
  }
  return null;
}

<div class="gingr-go-live-or-optional-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="close-row">
    <span class="close-row__btn close-btn" (click)="closeDialog()"><mat-icon>close</mat-icon></span>
  </div>

  <h3 class="gg-heading gingr-go-live-or-optional-container__title">
    <span class="congratulation">{{'ACCOUNT.Congratulations' | translate: locale.language}}!</span>
  </h3>
  <gingr-button class="go-live gingr-go-live-or-optional-container__btn" (update)="goLive()" type="secondary">
    go live
  </gingr-button>
  <h3 class="gg-heading gingr-go-live-or-optional-container__title">
    <span class="congratulation">{{'ACCOUNT.Or' | translate: locale.language}}</span>
  </h3>
  <gingr-button class="close gingr-go-live-or-optional-container__btn" (update)="continue()" type="secondary">
    continue
  </gingr-button>
</div>

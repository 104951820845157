<div class="register_role_container pt-[30px] pb-5 md:pr-[30px]">
  <h3 class="text-primary text-[24px] font-[600] mb-[40px] capitalize">
    {{ 'REGISTRATION.SELECT' | translate: locale.language }}
    <span class="lowercase"
      >{{ 'REGISTRATION.YOUR' | translate: locale.language }}
    </span>
    <span class="color-system-yellow lowercase">{{
      'COMMON.ROLE' | translate: locale.language
    }}</span>
  </h3>

  <img
    src="./assets/icons/cross.svg"
    alt="Close Icon"
    width="15"
    class="close absolute right-[20px] top-[20px] cursor-pointer block md:hidden"
    (click)="closeDialog()"
  />

  <div
    class="close absolute right-[-10px] top-[20px] cursor-pointer hidden lg:block"
  >
    <img
      src="./assets/icons/close-new.svg"
      alt="Close Icon new"
      width="24"
      (click)="closeDialog()"
    />
  </div>
  <div fxLayout="column" fxLayoutAlign="start center">
    <div
      class="border-2 border-primary flex mb-[20px] w-full py-[11px] px-20px rounded text-[18px] text-primary relative text-center justify-center cursor-pointer"
      (click)="
        openDialog(
          tabTypes.registrationClient.dataType,
          tabTypes.registrationClient.role
        )
      "
    >
      <img
        src="./assets/icons/client-image.svg"
        class="icon absolute top-[10px] left-[10px]"
      />
      <span class="text-lg">
        {{ 'DIALOG-LOGIN-REGISTER.Client' | translate: locale.language }}
      </span>
    </div>

    <div
      class="border-2 border-primary flex mb-[20px] w-full py-[11px] px-20px rounded text-[18px] text-primary relative text-center justify-center cursor-pointer"
      (click)="
        openDialog(
          tabTypes.registrationGinger.dataType,
          tabTypes.registrationGinger.role
        )
      "
    >
      <img
        src="./assets/icons/gingr-image.svg"
        class="icon absolute top-[10px] left-[10px]"
      />

      <span class="text-lg">
        {{ 'DIALOG-LOGIN-REGISTER.Escort' | translate: locale.language }}
      </span>
    </div>

    <ng-container *ngIf="false">
      <div
        class="border-2 border-primary flex mb-[20px] w-full py-[11px] px-20px rounded text-[18px] text-primary relative text-center justify-center cursor-pointer"
        (click)="
          openDialog(
            tabTypes.registrationBrothels.dataType,
            tabTypes.registrationBrothels.role
          )
        "
      >
        <img
          src="./assets/icons/est-image.svg"
          class="icon absolute top-[10px] left-[10px]"
        />
        <span class="text-lg">
          {{
            'DIALOG-LOGIN-REGISTER.Establishment' | translate: locale.language
          }}
        </span>
      </div>

      <div
        class="border-2 border-primary flex w-full py-[11px] px-20px rounded text-[18px] text-primary relative text-center justify-center cursor-pointer"
        (click)="
          openDialog(
            tabTypes.registrationAgency.dataType,
            tabTypes.registrationAgency.role
          )
        "
      >
        <img
          src="./assets/icons/agency-yellow.svg"
          class="icon absolute top-[10px] left-[10px]"
          width="31"
          height="30"
        />
        <span class="text-lg">
          {{ 'DIALOG-LOGIN-REGISTER.Agency' | translate: locale.language }}
        </span>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="false"
    ><div class="separate_container my-[30px] md:my-[50px]">
      <span class="line_helper"></span>
      <span class="text_helper">{{
        'COMMON.OR' | translate: locale.language
      }}</span>
      <span class="line_helper"></span>
    </div>

    <a
      [href]="ggCoinUrl"
      target="_blank"
      class="border-2 border-primary flex mb-[15px] w-full py-[11px] mt-[20px] px-20px rounded text-[18px] text-primary text-lg relative text-center justify-center"
    >
      <img
        src="./assets/icons/ico-image.svg"
        class="icon absolute top-[10px] left-[10px]"
      />
      {{ 'DIALOG-LOGIN-REGISTER.ICO Investor' | translate: locale.language }}
    </a></ng-container
  >
</div>

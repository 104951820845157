<section class="landingHeader" fxLayout="row">
  <div
    class="bgImage"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
    [style.background-image]="'url(' + bgImage + ')'"
  >
    <h1>{{ locationName }}</h1>
    <h2 *ngIf="landingContent[activeTabName]">
      {{ landingContent[activeTabName].name }}
    </h2>
  </div>
</section>

<section *ngIf="data">
  <mat-tab-group
    [(selectedIndex)]="activeTab"
    (selectedTabChange)="tabChange($event)"
  >
    <div *ngFor="let contentType of availableContentTypes">
      <mat-tab
        *ngIf="landingContent[contentType]"
        label="{{ contentType.toUpperCase() }}"
      >
        <section fxLayout="row">
          <div
            [innerHTML]="landingContent[contentType].content"
            class="infoSection"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxFlex="40"
          ></div>
          <div
            *ngIf="activeTab == landingContent[contentType].tabId"
            class="gridSection"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxFlex="60"
          >
            <gingr-profile-list
              [profiles]="profilesArray"
              (paginate)="paginate()"
              hideLastSeen="true"
              id="my-scroll-container"
              [showEstablishment]="true"
            ></gingr-profile-list>
          </div>
        </section>
      </mat-tab>
    </div>
  </mat-tab-group>
</section>

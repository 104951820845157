export const AUTH_KEY = 'AUTH';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
import { Action } from '../core.interfaces';

import { User } from '@app/shared/models/user';
export class Login implements Action {
  readonly type = AUTH_LOGIN;

  constructor(public payload: { user: User }) { }
}

export class Logout implements Action {
  readonly type = AUTH_LOGOUT;

  constructor(public payload: { user: null }) { }
}

export type Actions =
  | Login
  | Logout;

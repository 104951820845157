import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  HostListener,
  inject,
  EnvironmentInjector,
  runInInjectionContext,
  ViewChild,
  computed,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  Router,
  NavigationEnd,
  RouterOutlet,
  NavigationStart,
  NavigationSkipped,
  RouterLink,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import {
  UserService,
  MobileBottomNavigationService,
  LocationService,
} from './shared/services';

import { routerTransition } from '@app/core';
import { EventEmitter } from 'events';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { AccountMenuService } from '@app/account/services';
import { DeviceChecker } from '@app/shared/helpers/device-checker';
import { StorageService } from '@app/shared/services/storage.service';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { ConfigService } from './shared/services/config.service';
import {
  getAppConfigAction,
  getUserLocationAction,
  logoutAction,
} from '@app/shared/actions/shared.actions';
import {
  CacheAgencyProfiles,
  CacheAgencyProfilesScroll,
  CacheEstablishmentProfiles,
  CacheEstablishmentProfilesScroll,
  CacheGingrProfiles,
  CacheGingrProfilesScroll,
  FeatureFlags,
} from '@app/shared/models/constants';
import {
  getCurrentUser,
  isUserClient,
} from '@app/shared/reducers/user.selectors';
import { Amplify } from 'aws-amplify';
import { CognitoService } from '@app/shared/services/cognito.service';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { CookiebotModel } from '@app/shared/models/cookiebot.model';
import {
  MobileBottomNavigationComponent,
  ButtonComponent,
  HeaderComponent,
  DialogLoginComponent,
} from '@app/shared/components';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import {
  NgIf,
  NgClass,
  NgSwitch,
  AsyncPipe,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { SidenavDesktopComponent } from '@app/shared/components/sidenav-desktop/sidenav-desktop.component';
import { SidenavMobileComponent } from '@app/shared/components/sidenav-mobile/sidenav-mobile.component';
import { MatDialog } from '@angular/material/dialog';
import { isBot } from '@app/shared/helpers/user-agent.helper';
import { CurrentUser } from '@app/shared/models/current-user';
import { SvgIconComponent } from 'angular-svg-icon';
import { FiltersV2Component } from '@app/shared/components/filters-v2/filters-v2.component';
import { FilterV2Service } from '@app/shared/services/filter-v2.service';
import { ToastShowComponent } from '@app/shared/components/toast-show/toast-show.component';
import { InstaBookingPromoComponent } from '@app/shared/components/insta-booking-promo/insta-booking-promo.component';
import { InstaBookingRulesComponent } from '@app/shared/components/insta-booking-rules/insta-booking-rules.component';
import { getInstaState } from '@app/instafeature/store/instafeature.actions';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { BookingStateService } from '@app/instafeature/services/booking-state.service';
import { getInstaStateSelector } from '@app/instafeature/store/instafeature.selectors';
import { INSTA_STEP } from '@app/instafeature/models/insta-state';
import { FindGingrFilterComponent } from '@app/instafeature/components/find-gingr-filter/find-gingr-filter.component';

declare let gtag: Function;
declare let Cookiebot: CookiebotModel;

@Component({
  selector: 'gingr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    HeaderComponent,
    ButtonComponent,
    NgClass,
    ExtendedModule,
    MatSidenavModule,
    FlexModule,
    RouterOutlet,
    MobileBottomNavigationComponent,
    SidenavDesktopComponent,
    SidenavMobileComponent,
    L10nTranslatePipe,
    SvgIconComponent,
    FiltersV2Component,
    RouterLink,
    ToastShowComponent,
    InstaBookingPromoComponent,
    InstaBookingRulesComponent,
    NgSwitch,
    AsyncPipe,
    NgSwitchCase,
    NgSwitchDefault,
    FindGingrFilterComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly bookingStateService = inject(BookingStateService);
  readonly isUserClient = this.store.selectSignal(isUserClient);
  readonly instaState = this.store.selectSignal(getInstaStateSelector);
  readonly openSession = computed(() =>
    !this.instaState()
      ? false
      : this.instaState().stepId === INSTA_STEP.GingrFindIncall ||
        this.instaState().stepId === INSTA_STEP.GingrFindBoth ||
        this.instaState().stepId === INSTA_STEP.GingrFindOutcall
  );

  dialog = inject(MatDialog);

  private unsubscribe$: Subject<void> = new Subject<void>();
  showFilter = false;
  showBuildId: boolean;
  releaseId: string;
  env: string = this.configService.config.apiUrl;
  menuLinks = JSON.stringify(this.configService.config.menuLinks);
  center: google.maps.LatLng;
  warning: boolean;
  message: string;
  location: object;
  currentUser: CurrentUser;
  avatar: string;
  isProUser: boolean;
  featurePageSubscription: boolean;
  featureHasGGCoin: boolean;
  baseRole: string;
  mobileMenuOpen: boolean = false;
  isProfilePreview = false;
  isClientProfilePage = false;
  isSettingsTile = false;
  updateFilter: any = {};

  private body: HTMLElement = document.getElementsByTagName('body')[0];

  public sliderConfig: any = {
    start: [0, 5],
  };

  public isSafari: boolean = false;
  public isIOS: boolean = false;

  public routerState;
  // basic value, don't change it anymore!
  public isAccount: boolean = false;
  public isBackground: boolean = false;
  public bottomMargin: boolean = false;
  public isMobileDevice: boolean = false;
  public toggleEmitter: EventEmitter = new EventEmitter();
  public isAgeConsentSet: boolean = false;
  public isAgeConsentClicked: boolean = false;
  public isInstaSection: boolean = false;
  public isAccountProfile: boolean = false;
  public isAccountProfileTile: boolean = false;
  public isAppointmentSection: boolean = false;
  public isProfileOverview: boolean = false;
  public showCustomMenu: boolean = false;
  public isBookingDetailsSection: boolean = false;
  public isBookingListSection: boolean = false;
  public isCreditSubSection: boolean = false;
  public hasInstaButton: boolean = false;
  public isLanguagePage: boolean = false;
  isBot = isBot();
  screenWidth = window.innerWidth;
  previousUrl: string;
  isLoggedIn: boolean;
  accountMenuClosedRoutes = [
    '/welcome',
    '/gingrs',
    '/establishments',
    '/agencies',
    '/info',
    '/home/client',
    '/home/escort',
    '/home/establishment',
    '/home/agency',
    '/home/gingr-card-program',
  ];

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    StaticUtilsService.LdClient.close();
  }

  @HostListener('window:CookiebotOnDialogDisplay', ['$event'])
  cookiebotOnDisplay() {
    this.checkCookiebot();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.translationService.init();
  }

  constructor(
    matIconRegistry: MatIconRegistry,
    private storage: StorageService,
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private store: Store<any>,
    private translationService: L10nTranslationService,
    private router: Router,
    private mobileService: MobileBottomNavigationService,
    private observableMedia: MediaObserver,
    private accountMenu: AccountMenuService,
    private configService: ConfigService,
    private cognitoService: CognitoService,
    private injector: EnvironmentInjector,
    private locationService: LocationService,
    private filterV2Service: FilterV2Service
  ) {
    Amplify.configure({
      Auth: {
        userPoolId: configService.config.cognitoUserPoolId,
        userPoolWebClientId: configService.config.cognitoClientId,
      },
    });

    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');

    this.isIOS = DeviceChecker.isIOS();
    this.isSafari = DeviceChecker.isSafari();
  }
  async ngOnInit(): Promise<void> {
    this.checkCookiebot();

    await StaticUtilsService.LdClient.waitUntilReady().then();
    this.showBuildId = StaticUtilsService.LdClient.variation(
      FeatureFlags.ShowBuildId,
      false
    );
    this.releaseId = this.configService.config.releaseId;

    if (
      this.isBot ||
      !(StaticUtilsService.LdClient.variation(
        FeatureFlags.Over18,
        false
      ) as boolean) ||
      this.getCookie('age-consent')
    ) {
      this.isAgeConsentSet = true;
    }

    this.cognitoService.checkUserSession();

    // white stripe on mobile devices fix
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    // get route to determine which sidebar to show
    this.userService.resetFirstRunWizard();

    this.router.events.subscribe((event) => {
      this.showFilter = false;
      if (event instanceof NavigationStart) {
        this.mobileMenuOpen = false;
      }
      if (event instanceof NavigationSkipped) {
        this.mobileMenuOpen = false;
      }
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        this.isBookingListSection =
          currentUrl.endsWith('/account/bookings/upcoming') ||
          currentUrl.endsWith('/account/bookings/pending') ||
          currentUrl.endsWith('/account/bookings/history');
        this.hasInstaButton =
          currentUrl.endsWith('/gingrs') ||
          currentUrl.endsWith('/gingrs/map') ||
          currentUrl.endsWith('/establishments') ||
          currentUrl.endsWith('/agencies') ||
          currentUrl.endsWith('/account/profile') ||
          this.isBookingListSection ||
          currentUrl.endsWith('/account/dashboard/notifications') ||
          currentUrl.endsWith('/account/reviews') ||
          currentUrl.endsWith('/account/settings') ||
          currentUrl.endsWith('/account/dashboard/notification-settings') ||
          currentUrl.endsWith('/account/dashboard/security');

        this.isInstaSection = currentUrl.includes('/instafeature');
        this.isLanguagePage = currentUrl.includes('account/dashboard/language');
        this.isAccountProfile = currentUrl.includes('/account/profile');
        this.isBookingDetailsSection =
          !this.isBookingListSection &&
          currentUrl.includes('/account/bookings/');
        this.isAccountProfileTile = currentUrl.includes('/account/profile/');
        this.isAppointmentSection = currentUrl.startsWith('/book/');
        this.isCreditSubSection = currentUrl.startsWith('/credit/balance/');
        this.isProfileOverview =
          currentUrl.includes('/gingrs/profile/') &&
          !currentUrl.includes('/gingrs/profile/preview');
        this.isProfilePreview =
          currentUrl.includes('/gingrs/profile') &&
          currentUrl.includes('preview');
        this.isClientProfilePage = currentUrl.includes('clients/profile/');
        this.isSettingsTile = currentUrl.includes('/account/settings/');
        if (this.previousUrl === undefined) {
          // remove cached profiles on first load or listing reload
          if (!currentUrl.startsWith('/gingrs/profile/')) {
            this.storage.delete(CacheGingrProfiles, true);
            this.storage.delete(CacheGingrProfilesScroll, true);
          } else if (!currentUrl.startsWith('/establishments/profile/')) {
            this.storage.delete(CacheEstablishmentProfiles, true);
            this.storage.delete(CacheEstablishmentProfilesScroll, true);
          } else if (!currentUrl.startsWith('/agencies/profile/')) {
            this.storage.delete(CacheAgencyProfiles, true);
            this.storage.delete(CacheAgencyProfilesScroll, true);
          }
        } else {
          // remove cached profiles if navigate away from the listing pages
          if (
            !currentUrl.startsWith('/gingrs') &&
            !currentUrl.startsWith('/book')
          ) {
            this.storage.delete(CacheGingrProfiles, true);
            this.storage.delete(CacheGingrProfilesScroll, true);
          }
          if (!currentUrl.startsWith('/establishments')) {
            this.storage.delete(CacheEstablishmentProfiles, true);
            this.storage.delete(CacheEstablishmentProfilesScroll, true);
          }
          if (!currentUrl.startsWith('/agencies')) {
            this.storage.delete(CacheAgencyProfiles, true);
            this.storage.delete(CacheAgencyProfilesScroll, true);
          }

          // scroll top on route change
          const element = document.querySelector('.app-wrapper');
          if (element) {
            if (
              this.isMobileDevice ||
              !(
                (currentUrl.startsWith('/gingrs/profile/') &&
                  this.previousUrl.startsWith('/gingrs/profile/')) ||
                (currentUrl.startsWith('/establishments/profile/') &&
                  this.previousUrl.startsWith('/establishments/profile/')) ||
                (currentUrl.startsWith('/agencies/profile/') &&
                  this.previousUrl.startsWith('/agencies/profile/'))
              )
            ) {
              element.scrollTop = 0;
            }
          }
        }
        this.previousUrl = currentUrl;

        // Hide account sidebar on welcome page
        this.bottomMargin = !(
          currentUrl === '/welcome' ||
          currentUrl === '/account/dashboard/subscription' ||
          currentUrl === '/account/dashboard/contact-preferences' ||
          currentUrl === '/account/profile/account-details' ||
          currentUrl === '/account/profile/languages' ||
          currentUrl === '/account/profile/location' ||
          currentUrl === '/account/profile/media' ||
          currentUrl === '/account/profile/pricing' ||
          currentUrl === '/account/profile/services' ||
          currentUrl === '/account/profile/availability' ||
          currentUrl === '/account/profile/personal-details' ||
          currentUrl === '/account/dashboard/security' ||
          currentUrl === '/account/profile/verification' ||
          currentUrl === '/info/pricing/Gingr' ||
          currentUrl === '/info/pricing/Client' ||
          currentUrl === '/info/pricing/Establishment' ||
          currentUrl === '/info/pricing/Agency' ||
          currentUrl === '/info/pricing'
        );

        if (this.accountMenuClosedRoutes.indexOf(currentUrl) !== -1) {
          this.isAccount = false;
          this.accountMenu.updateSidenavState(this.isAccount);
        }

        // purple background on those pages
        this.isBackground =
          /account/.test(currentUrl.toLocaleLowerCase()) ||
          /workplace/.test(currentUrl.toLocaleLowerCase()) ||
          /manage-gingrs/.test(currentUrl.toLocaleLowerCase());

        this.routerState = currentUrl;
      }
    });

    this.store
      .pipe(
        select(getCurrentUser),
        filter((user) => user !== undefined)
      )
      .subscribe((user) => {
        if (
          (user?.role === UserRoles.SERVICE_PROVIDER_PRO ||
            user?.role === UserRoles.SERVICE_PROVIDER_BASIC) &&
          (!this.currentUser || this.currentUser.id !== user.id)
        ) {
          this.store.dispatch(
            getInstaState({ skipRedirect: true, checkBalance: false })
          );
        }
        this.currentUser = user;
        this.avatar = this.userService.getCurrentUserAvatar(user);
        this.baseRole = UserService.getUserBaseRole(user);
        this.isLoggedIn = !!user;

        if (!user) {
          this.isAccount = false;
          this.accountMenu.updateSidenavState(this.isAccount);
        } else if (
          this.accountMenuClosedRoutes.indexOf(this.router.url) === -1
        ) {
          this.accountMenu.updateSidenavState(this.isAccount);
        }
      });

    this.userService.isProUser.subscribe((isPro) => {
      this.isProUser = isPro;
    });

    this.featurePageSubscription = !!StaticUtilsService.LdClient.variation(
      FeatureFlags.PageSubscription,
      false
    );
    this.featureHasGGCoin = !!StaticUtilsService.LdClient.variation(
      FeatureFlags.PageGGCoins,
      false
    );

    this.accountMenu.sidenavAccountState.subscribe((isAccount: boolean) => {
      this.isAccount = isAccount;
    });

    this.filterV2Service.openCloseFilter.subscribe((data) => {
      this.showFilter = !!data;
    });

    // initialize app config
    this.store.dispatch(getAppConfigAction());

    // User Geolocation
    this.locationService.initializeUserLocation();
    this.store.dispatch(getUserLocationAction({ askAccess: false }));

    // Mobile device
    this.isMobileDevice = this.observableMedia.isActive('xs');
    this.observableMedia
      .asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((change: MediaChange[]) => {
        this.isMobileDevice = change[0].mqAlias === 'xs';
      });

    this.setUpAnalytics();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  checkCookiebot() {
    if (
      isBot() ||
      !StaticUtilsService.LdClient.variation(FeatureFlags.CookieBar, false)
    ) {
      if ((window as any).Cookiebot) {
        Cookiebot?.hide();
      }
    }
  }

  login(): void {
    this.dialog.open(DialogLoginComponent, {
      data: { type: 'login' },
      panelClass: 'login-dialog--panel',
      autoFocus: false,
    });
  }

  logout() {
    this.store.dispatch(logoutAction());
    this.filterV2Service.updateSearch(null, 0);
    this.router.navigateByUrl('/gingrs');
  }

  setUpAnalytics() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if ((window as any).gtag) {
          gtag('config', 'G-ZY1SWDMVQ8', {
            page_path: event.urlAfterRedirects,
          });
        }
      });
  }

  getCookie(key: string) {
    return runInInjectionContext(this.injector, () => {
      const cookieService = inject(CookieService);
      return cookieService.get(key);
    });
  }

  setCookie(key: string): void {
    runInInjectionContext(this.injector, () => {
      const cookieService = inject(CookieService);
      cookieService.put(key, 'true');
      this.isAgeConsentClicked = true;

      setTimeout(() => {
        this.isAgeConsentSet = true;
        this.mobileService.setShowValue({
          showMobileBottomMenu: true,
          url: '/account/dashboard/subscription',
        });
      }, 550);
    });
  }

  openInstantBooking() {
    this.userService.openInstantBooking();
  }

  deactivateInsta() {
    this.bookingStateService.closeInstaSession('/gingrs', true);
  }

  filterToggle() {
    if (this.showFilter) {
      this.updateFilter = { update: !this.isInstaSection };
      if (this.isInstaSection) {
        this.showFilter = !this.showFilter;
      }
    } else {
      this.showFilter = !this.showFilter;
    }
  }
}

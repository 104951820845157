import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  Inject,
  AfterViewInit,
  NgZone,
  forwardRef,
  HostListener,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Profile, User, UserAvailableRoles } from '@app/shared/models';
import {
  NotificationService,
  ModalService,
  UserService,
  LocationService,
  ProfilesService,
} from '@app/shared/services';
import { fadeInAnimation, gridAnimation } from '@app/shared/animations';

import { StorageService } from '@app/shared/services/storage.service';
import {
  DialogLoginComponent,
  DialogLoginRegisterDialogComponent,
  DialogRegisterRoleComponent,
} from '@app/shared/components/dialog-login-register/dialog-login-register.component';
import { ShowVideoComponent } from '@app/shared/components/show-video/show-video.component';
import { AccountMenuService, AccountService } from '@app/account/services';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslationService,
  L10nTranslatePipe,
} from 'angular-l10n';
import { ProfileObservableHelper } from '@app/shared/helpers/profileObservableHelper';
import { ManageGingrsService } from '@app/manage-gingrs/services';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfigService } from '@app/shared/services/config.service';
import { select, Store } from '@ngrx/store';
import { getManagedUser, getUser } from '@app/shared/reducers/user.selectors';
import * as _ from 'lodash-es';
import { UserRoles } from '@app/shared/models/enum/userroles';
import {
  BusyOverlayComponent,
  FiltersComponent,
  InstafuckButtonComponent,
  LastSeenComponent,
  LocationSelectorComponent,
} from '@app/shared/components';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FlexModule } from '@angular/flex-layout/flex';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatGridListModule } from '@angular/material/grid-list';
import {
  NgIf,
  NgFor,
  NgTemplateOutlet,
  AsyncPipe,
  NgClass,
  JsonPipe,
} from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { PaginatorModule } from 'primeng/paginator';
import { FiltersV2Component } from '@app/shared/components/filters-v2/filters-v2.component';
import { FilterV2Service } from '@app/shared/services/filter-v2.service';

@Component({
  selector: 'gingr-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
  animations: [fadeInAnimation, gridAnimation],
  standalone: true,
  imports: [
    NgIf,
    LocationSelectorComponent,
    LastSeenComponent,
    InstafuckButtonComponent,
    MatGridListModule,
    InfiniteScrollModule,
    FlexModule,
    NgFor,
    LazyLoadImageModule,
    NgTemplateOutlet,
    MatIconModule,
    BusyOverlayComponent,
    AsyncPipe,
    L10nTranslatePipe,
    forwardRef(() => ShowVideoComponent),
    SvgIconComponent,
    MatButtonModule,
    FiltersComponent,
    PaginatorModule,
    FiltersV2Component,
    NgClass,
    JsonPipe,
  ],
})
export class ProfileListComponent
  extends ProfileObservableHelper
  implements OnInit, OnDestroy, AfterViewInit
{
  @HostBinding('@fadeInAnimation')
  fadeInAnimation = '';

  @HostBinding('@gridAnimation')
  gridAnimation = '';

  @Input()
  showEstablishment = false;

  @Input()
  profiles: Profile[];

  @Input()
  registerPlate: any;

  @Input()
  currentRole: string;

  @Input()
  gutterSize = '4px';

  @Input()
  isLoading: boolean;

  @Input()
  semicolon: boolean;

  @Input()
  showVideo: boolean = true;

  @Input()
  showSlider: boolean = true;

  @Input()
  showVideoBlock: boolean = false;

  @Input()
  disableLazyLoad: boolean = false;

  @Input()
  showFavs: boolean = true;

  @Input()
  showWorkplace: boolean = true;

  @Input()
  currentComponent: boolean | string = true;

  @Input()
  hideLastSeen: boolean = false;

  @Output()
  paginate: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('theListRef', { read: ElementRef, static: true })
  theList: ElementRef;
  @Output() filterToggle = new EventEmitter<boolean>();

  profilesCacheKey: string;
  scrollCacheKey: string;
  selectedItem: string;
  userRoles = UserRoles;
  showFilter: boolean = true;

  private theCols = new ReplaySubject<number>(2);

  public defaultImage = '/assets/images/lazy.gif';
  public tabTypes = DialogLoginRegisterDialogComponent.tabsTypes;
  public cols = this.theCols.asObservable();
  public isMobile: boolean = false;
  public isScrolled: boolean = false;
  public isChrome: boolean = false;

  public shown: boolean = false;
  public denyRedirect: boolean = false;
  public denyModal: boolean = false;
  public iconsHiden: boolean = true;

  windowWidth = window.innerWidth;
  filtersSelectedMainGrid$: Observable<number>;

  user: any = new User();
  isEstbOrAgency = false;
  private destroy$ = new Subject<boolean>();

  private readonly filterV2Service = inject(FilterV2Service);

  constructor(
    private router: Router,
    private observableMedia: MediaObserver,
    private storage: StorageService,
    private dialog: MatDialog,
    private notify: NotificationService,
    private modalService: ModalService,
    private profileService: ProfilesService,
    private geolocation: LocationService,
    public changes: ChangeDetectorRef,
    public userService: UserService,
    public configService: ConfigService,
    public accountMenu: AccountMenuService,
    private store: Store<any>,
    private ngZone: NgZone,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public translation: L10nTranslationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    accountService: AccountService,
    manageGingrsService: ManageGingrsService,
    route: ActivatedRoute
  ) {
    super(userService, accountService, manageGingrsService, route);

    iconRegistry.addSvgIcon(
      'video',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/filters/video.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ggcoin',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/ggc_logo_white.svg'
      )
    );
  }

  ngOnInit() {
    this.filtersSelectedMainGrid$ =
      this.filterV2Service.filtersSelected.asObservable();

    this.profilesCacheKey = this.profileService.getProfilesCacheKey(
      this.currentRole
    );
    this.scrollCacheKey = this.profileService.getProfilesScrollCacheKey(
      this.currentRole
    );

    const w: any = window;
    this.isChrome = w.chrome;
    this.accountMenu.updateSidenavState(false);
    if (!this.semicolon) {
      if (this.observableMedia.isActive('xs')) {
        this.theCols.next(2);
      } else if (this.observableMedia.isActive('sm')) {
        this.theCols.next(3);
      } else if (this.observableMedia.isActive('md')) {
        this.theCols.next(4);
      } else if (this.observableMedia.isActive('lg')) {
        this.theCols.next(6);
      } else if (this.observableMedia.isActive('xl')) {
        this.theCols.next(8);
      }
      this.observableMedia
        .asObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe((change) => {
          this.isMobile = change[0].mqAlias === 'xs';

          switch (change[0].mqAlias) {
            case 'xs':
              this.theCols.next(2);
              break;
            case 'sm':
              this.theCols.next(3);
              break;
            case 'md':
              this.theCols.next(4);
              break;
            case 'lg':
              this.theCols.next(6);
              break;
            case 'xl':
              this.theCols.next(8);
              break;
          }
        });
    } else {
      this.theCols.next(2);
    }

    this.store
      .pipe(
        select(this.isManaged ? getManagedUser : getUser),
        filter((user) => user !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = _.cloneDeep(user);
        const userRole = UserService.getUserRole(this.user);
        this.isEstbOrAgency = userRole.isEstablishment || userRole.isAgency;
      });

    // subscribe for deleted favourites
    this.userService.followerDeleted
      .pipe(takeUntil(this.destroy$))
      .subscribe((slug: string) => {
        if (slug) {
          this.profiles.forEach((eachProfile) => {
            if (eachProfile.slug === slug) {
              eachProfile.isFavourite = false;
            }
          });
          this.detectChanges();
        }
      });

    this.translation.onChange().subscribe(() => {
      this.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.initScrollHandler();
  }

  showMoreInfo(selectedItemValue) {
    if (this.selectedItem === selectedItemValue) {
      this.selectedItem = '';
    } else {
      this.selectedItem = selectedItemValue;
    }
    this.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initScrollHandler() {
    const content: any = document.getElementsByClassName('app-wrapper')[0];
    if (content) {
      let prevScrollPos = content.scrollTop;
      content.onscroll = () => {
        const currentScrollPos = content.scrollTop;
        if (prevScrollPos > currentScrollPos && currentScrollPos > 64) {
          this.showFilter = true;
        }
        if (prevScrollPos < currentScrollPos && currentScrollPos > 64) {
          this.showFilter = false;
          if (!this.isScrolled) {
            this.isScrolled = true;
            this.detectChanges();
          }
        } else if (this.isScrolled) {
          this.isScrolled = false;
          this.detectChanges();
        }
        prevScrollPos = currentScrollPos;
      };
    }
  }

  goProfile(profile: any, mediaQuery?: string) {
    if (this.denyRedirect) {
      this.denyRedirect = false;
      return;
    }

    const element = document.querySelector('.app-wrapper');
    if (this.scrollCacheKey && element) {
      this.storage.set(this.scrollCacheKey, element.scrollTop, true);
    }
    if (this.profilesCacheKey) {
      this.storage.set(this.profilesCacheKey, this.profiles, true);
    }

    const navigationData = this.router.url.slice(1);
    const navigationEnding =
      navigationData === 'gingrs' ? 'overview' : 'gingrs';

    this.router.navigate(
      [navigationData, 'profile', profile.id, navigationEnding],
      {
        state: {
          profileListAfterClose: !!mediaQuery,
          media: mediaQuery || undefined,
          userId: profile.id,
        },
      }
    );
  }

  paginationControl(): void {
    this.paginate.emit(true);
    this.storage.delete(this.scrollCacheKey);
  }

  addToFavorite(id: number, profile: any): void {
    this.denyRedirect = true;
    if (!this.user) {
      this.unauthorizedFavorites();
    } else if (profile.isFavourite) {
      this.userService.deleteFromFavorite(id).subscribe({
        next: () => {
          profile.isFavourite = 0;
          this.userService.getFavourites(this.user?.id);
          this.detectChanges();
        },
      });
    } else {
      this.userService.addToFavourite(id).subscribe({
        next: () => {
          profile.isFavourite = 1;
          this.userService.getFavourites(this.user?.id);
          this.detectChanges();
        },
      });
    }
  }

  detectChanges() {
    if (!this.changes['destroyed']) {
      this.changes.detectChanges();
    }
  }

  unauthorizedFavorites(): void {
    this.notify.warn(
      'Warning',
      'User needs to be logged in to perform this action'
    );
    this.dialog.open(DialogLoginComponent, {
      data: { type: 'login' },
      panelClass: 'login-dialog--panel',
    });
  }

  showVideoModal(): void {
    if (this.denyModal) {
      this.denyModal = false;
    } else {
      this.denyRedirect = true;
      this.ngZone.run(() =>
        this.dialog.open(ShowVideoComponent, {
          panelClass: 'ShowVideoComponent-popup',
        })
      );
    }
  }

  openDialog(type: string, role: string): void {
    const params = { type: type };
    if (
      UserAvailableRoles[role] !== undefined &&
      UserAvailableRoles[role] !== null
    ) {
      Object.assign(params, { role: UserAvailableRoles[role] });
    }
    this.modalService.openDialog(DialogRegisterRoleComponent, params);
  }

  public checkVisibility() {
    let result = true;
    if (this.currentComponent && this.currentComponent === 'profile-gingrs') {
      result = false;
    }
    return result;
  }

  goToProfilePreview(slug) {
    if (!slug) {
      return;
    }
    this.router.navigate(['/establishments/profile/' + slug + '/preview']);
  }

  filterOpen() {
    this.filterV2Service.openCloseFilter.next(true);
  }
}

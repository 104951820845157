<ng-container *ngIf="isShowGlobe">
  <div class="absolute w-[120px] h-[120px] left-0 right-0 mx-auto top-[100px]">
    <span class="loading loading-spinner text-[#F49FFC] h-full w-full"></span>
    <span
      class="absolute left-0 w-full text-[#F49FFC] text-sm text-center leading-[120px]"
      *ngIf="text"
      >{{ text }}</span
    >
  </div>
</ng-container>

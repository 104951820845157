import { TopUpMethodType } from '@app/credit/models/top-up-method.type';
import { createReducer, on } from '@ngrx/store';
import {
  clearTopUpAction,
  setTopUpAmountAction,
  setTopUpPaymentMethodAction,
} from '@app/credit/store/credit.actions';

export interface CreditState {
  amount?: number;
  paymentMethod?: TopUpMethodType;
}

export const initialCreditState: CreditState = {
  amount: undefined,
  paymentMethod: undefined,
};

export const creditReducer = createReducer(
  initialCreditState,
  on(clearTopUpAction, () => initialCreditState),
  on(setTopUpPaymentMethodAction, (state, { paymentMethod }) => ({
    ...state,
    paymentMethod,
  })),
  on(setTopUpAmountAction, (state, { amount }) => ({
    ...state,
    amount,
  }))
);

import { createSelector } from '@ngrx/store';
import { Profile } from '@app/shared/models';
import * as profile from '../actions/profile.actions';
import * as collection from '../actions/collection.actions';

export interface State {
  ids: number[];
  entities: { [id: number]: Profile };
  selectedProfileId: number | null;
}

export const initialState: State = {
  ids: [],
  entities: {},
  selectedProfileId: null,
};

export function profileReducer(
  state = initialState,
  action: profile.Actions | collection.Actions
): State {
  switch (action.type) {
    case profile.LOAD_ALL:
    case profile.SEARCH_COMPLETE:
    case collection.LOAD_SUCCESS: {
      const profiles = action.payload;
      const newProfiles = profiles.filter((p) => !state.entities[p.id]);

      const newProfileIds = newProfiles.map((p) => p.id);
      const newProfileEntities = newProfiles.reduce(
        (entities: { [username: string]: Profile }, p: Profile) => {
          return Object.assign(entities, {
            [p.id]: p,
          });
        },
        {}
      );

      return {
        ids: [...state.ids, ...newProfileIds],
        entities: Object.assign({}, state.entities, newProfileEntities),
        selectedProfileId: state.selectedProfileId,
      };
    }

    case profile.LOAD: {
      const p = action.payload;

      if (state.ids.indexOf(p.id) > -1) {
        return state;
      }

      return {
        ids: [...state.ids, p.id],
        entities: Object.assign({}, state.entities, {
          [p.id]: p,
        }),
        selectedProfileId: state.selectedProfileId,
      };
    }

    case profile.SELECT: {
      return {
        ids: state.ids,
        entities: state.entities,
        selectedProfileId: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getEntities = (state: State) => state.entities;

export const getIds = (state: State) => state.ids;

export const getSelectedId = (state: State) => state.selectedProfileId;

export const getSelected = createSelector(
  getEntities,
  getSelectedId,
  (entities, selectedId) => {
    return entities[selectedId];
  }
);

export const getAll = createSelector(getEntities, getIds, (entities, ids) => {
  return ids.map((id) => entities[id]);
});

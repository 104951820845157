import { of as observableOf, Observable } from 'rxjs';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'gingr-press-downloads',
    templateUrl: './press-downloads.component.html',
    styleUrls: ['./press-downloads.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FlexModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        NgFor,
        MatCardModule,
        AsyncPipe,
        L10nTranslatePipe,
    ],
})
export class PressDownloadsComponent implements OnInit {
  @Input() pressDownloads: Array<any>;
  public cols: Observable<number>;
  public mediaCdnUrl: string = this.configService.config.mediaCdnUrl;

  constructor(
    private observableMedia: MediaObserver,
    private configService: ConfigService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    if (this.observableMedia.isActive('xs')) {
      this.cols = observableOf(1);
    } else if (this.observableMedia.isActive('sm')) {
      this.cols = observableOf(3);
    } else if (this.observableMedia.isActive('md')) {
      this.cols = observableOf(4);
    } else if (this.observableMedia.isActive('lg')) {
      this.cols = observableOf(4);
    } else if (this.observableMedia.isActive('xl')) {
      this.cols = observableOf(5);
    }

    this.observableMedia.asObservable().subscribe((change) => {
      switch (change[0].mqAlias) {
        case 'xs':
          return [(this.cols = observableOf(1))];
        case 'sm':
          return [(this.cols = observableOf(3))];
        case 'md':
          return [(this.cols = observableOf(4))];
        case 'lg':
          return [(this.cols = observableOf(4))];
        case 'xl':
          return [(this.cols = observableOf(5))];
      }
    });
  }
}

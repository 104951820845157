import { createSelector } from '@ngrx/store';
import { SharedState } from '@app/shared/reducers/shared.reducer';
import * as _ from 'lodash-es';
import { AppConfig, AppConfigItem } from '@app/shared/models';

export function getSharedState(state): SharedState {
  return state.shared;
}

export const getAppConfig = createSelector(
  getSharedState,
  (state: SharedState) => state?.appConfig
);

export const getServiceFeeAppointment = createSelector(
  getSharedState,
  (state: SharedState) =>
    +state?.appConfig?.generalProperties?.find(
      (item: AppConfigItem) => item.name === AppConfig.ESCORT_FEE_BOOK
    )?.value || 0
);

export const getCountryList = createSelector(
  getSharedState,
  (state: SharedState) => {
    let data =
      state?.appConfig?.countries !== undefined
        ? state.appConfig.countries
        : [];
    data = _.orderBy(data);
    return data;
  }
);

// getUserLocation is not necessary to real location of the device,
// but the location user selects in the search bar
export const getUserLocation = createSelector(
  getSharedState,
  (state: SharedState) => state?.userLocation
);

export const getLanguageList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const systemLanguages = Array.isArray(state?.appConfig?.languages)
      ? state.appConfig.languages
      : [];
    return systemLanguages.filter((lang: any) => {
      return lang.appLanguage === 1;
    });
  }
);

export const getAllLanguageList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const results = Array.isArray(state?.appConfig?.languages)
      ? state.appConfig.languages.filter((each) => {
          return each.name !== 'N/A';
        })
      : [];

    return _.orderBy(_.orderBy(results, ['name'], ['asc']), (item) =>
      item.name === 'Swiss German'
        ? 1
        : item.name === 'German'
          ? 2
          : item.name === 'English'
            ? 3
            : item.name === 'Spanish'
              ? 4
              : item.name === 'Portuguese'
                ? 5
                : item.name === 'Romanian'
                  ? 6
                  : 7
    );
  }
);

export const getNationalityList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const data = state?.appConfig?.nationalities;
    return _.orderBy(_.orderBy(data), (item) =>
      item === 'Swiss' ? 1 : item === 'German' ? 2 : item === 'Romanian' ? 3 : 4
    );
  }
);

export const getOriginsList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const origins = state?.appConfig?.origins ?? [];
    return origins.map((origin) => ({
      ...origin,
      name: `ETHNICITY.${origin.name}`,
    }));
  }
);

export const getSexualitiesList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const sexualities = state?.appConfig?.sexualities ?? [];
    return sexualities.map((sexuality) => ({
      ...sexuality,
      name: `COMMON.SEXUALITY.${sexuality.name}`,
    }));
  }
);

export const getGenitaliaAreaList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const talias = state?.appConfig?.talias ?? [];
    return talias.map((talia) => ({
      ...talia,
      name: `GENITALIA.${talia.name}`,
    }));
  }
);

export const getHearsList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const hearAboutUs = state?.appConfig?.hearAboutUs ?? [];
    return hearAboutUs.map((hear) => ({
      ...hear,
      name: `USERHEAR.${hear.name}`,
    }));
  }
);

export const getHairColorsList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const hairColors = state?.appConfig?.hairColors ?? [];
    return hairColors.map((hairColor) => ({
      ...hairColor,
      name: `COMMON.HAIRCOLORS.${hairColor.name}`,
    }));
  }
);

export const getEyeColorsList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const eyeColors = state?.appConfig?.eyeColors ?? [];
    return eyeColors.map((eyeColor) => ({
      ...eyeColor,
      name: `COMMON.EYECOLOR.${eyeColor.name}`,
    }));
  }
);

export const getBodiesList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const bodies = state?.appConfig?.bodies ?? [];
    return bodies.map((body) => ({
      ...body,
      name: `BODYTYPES.${body.name}`,
    }));
  }
);

export const getServicesList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.services !== undefined ? state.appConfig.services : []
);

export const getSexesList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const genders = state?.appConfig?.genders ?? [];
    return genders.map((gender) => ({
      ...gender,
      name: `COMMON.SEX.${gender.name}`,
    }));
  }
);

export const getGridProfilesFilters = createSelector(
  getSharedState,
  (state: SharedState) => state?.gridProfilesFilters
);

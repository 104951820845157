import { inject, Injectable } from '@angular/core';
import { NodeApiService } from '@app/shared/services/node-api.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PressCountryModel } from '@app/shared/models/press/press-country.model';
import { ConfigModel } from '@app/shared/models/config.model';
import { APP_CONFIG } from '@app/shared/models/constants';
import { PressReleaseModel } from '@app/shared/models/press/press-release.model';

@Injectable({
  providedIn: 'root',
})
export class PressService {
  private readonly http = inject(HttpClient);
  private readonly config: ConfigModel = inject(APP_CONFIG);
  private readonly nodeApiService = inject(NodeApiService);

  getReleasesByCountry(countryId: number): Observable<PressReleaseModel[]> {
    return this.http.get<PressReleaseModel[]>(
      `${this.config.apiV3}/press/countries/${countryId}`
    );
  }

  getPressResources(countryId: number): Observable<any> {
    return this.nodeApiService.get('/pressResources/' + countryId);
  }

  getDomains(): Observable<PressCountryModel[]> {
    return this.http.get<PressCountryModel[]>(
      `${this.config.apiV3}/press/countries`
    );
  }

  getFullDomain(url: string): Observable<any> {
    return this.nodeApiService.post('/pressCountryByDomain', { domain: url });
  }
}

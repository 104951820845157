<div class="signin">
  <!-- form -->

  <div class="signin__wrap">
    <h4 class="t-a-left">
      {{ 'LOGIN_FORM.LOGIN TO' | translate: locale.language
      }}<span class="color-system-yellow">
        {{ 'LOGIN_FORM.ACTIVATE' | translate: locale.language }} </span
      >{{ 'LOGIN_FORM.ACCOUNT' | translate: locale.language }}
    </h4>
    <form
      [formGroup]="loginForm"
      class="login-form"
      name="loginForm"
      #f="ngForm"
      (ngSubmit)="submitLogin()"
      fxLayout="column"
    >
      <mat-form-field class="form-input" ngClass="login-username login-inputs">
        <input
          formControlName="email"
          class="form-input__input"
          matInput
          placeholder="{{ 'LOGIN_FORM.Email' | translate: locale.language }}"
          [(ngModel)]="loginUser.email"
        />
        <mat-error *ngIf="loginForm.controls.email.hasError('required')">
          {{ 'LOGIN_FORM.email is' | translate: locale.language }}
          <strong>{{
            'LOGIN_FORM.required' | translate: locale.language
          }}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-input" ngClass="login-password login-inputs">
        <input
          formControlName="password"
          matInput
          name="password"
          [(ngModel)]="loginUser.password"
          [type]="showPassword ? 'text' : 'password'"
          placeholder="Password"
        />
        <mat-icon
          class="cursor-pointer show-pass"
          (click)="showPassword = !showPassword"
          >remove_red_eye</mat-icon
        >
        <mat-error *ngIf="loginForm.controls.password.hasError('pattern')">
          {{
            'LOGIN_FORM.Passwords should be like a pattern'
              | translate: locale.language
          }}
        </mat-error>
        <mat-error *ngIf="loginForm.controls.password.hasError('required')">
          {{ 'LOGIN_FORM.Password' | translate: locale.language }}
          <strong>
            {{ 'LOGIN_FORM.required' | translate: locale.language }}
          </strong>
        </mat-error>
      </mat-form-field>

      <div class="list-card__content">
        <!--!loginForm.valid || isSubmitting -->
        <button
          class="title submit-button"
          mat-raised-button
          [disabled]="false"
          type="submit"
        >
          {{ 'LOGIN_FORM.SIGN IN' | translate: locale.language }}
        </button>
      </div>
    </form>
    <div class="forgot-pass" *ngIf="false">
      <a href="/forgot-password" (click)="forgotPass()">{{
        'LOGIN_FORM.FORGOT_PASSWORD' | translate: locale.language
      }}</a>
    </div>
  </div>
</div>

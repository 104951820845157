import { Config, GetLoc, SummaryLocation, User } from '@app/shared/models';
import { createReducer, on } from '@ngrx/store';
import {
  addUnViewedNotificationsAction,
  emailVerifyAction,
  emailVerifyManagedAction,
  phoneVerifyAction,
  phoneVerifyManagedAction,
  setAuthTokenAction,
  setBalanceAction,
  setCurrentUserAction,
  setCurrentUserPreferenceAction,
  setGridProfilesFiltersAction,
  setHasNotificationSetAction,
  setIntercomVisible,
  setKycVerificationAction,
  setManagedUserAction,
  setUnViewedNotificationsAction,
  setUserAction,
  smsVerifyAction,
  smsVerifyManagedAction,
  storeAppConfigAction,
  storeFilterCallType,
  storeFilterLocation,
  storeUserLocationAction,
} from '@app/shared/actions/shared.actions';
import { CurrentUser } from '@app/shared/models/current-user';
import { UserPreferenceModel } from '@app/shared/models/user-preference.model';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { DataSpikeVerificationModel } from '@app/shared/models/data-spike/data-spike-verification.model';

export interface SharedState {
  appConfig: Config;
  gridProfilesFilters: GetLoc;
  userLocation: SummaryLocation;
  filterLocation: SummaryLocation;
  filterCallType: ConnectType;
  user: User;
  managedUser: User;
  currentUser: CurrentUser;
  currentUserPreference: UserPreferenceModel;
  hasNotificationSet: boolean;
  kcyVerification: DataSpikeVerificationModel;
  accessToken: string;
  unViewedNotifications: number;
  intercomVisible: boolean;
}

export const initialSharedState: SharedState = {
  appConfig: undefined,
  gridProfilesFilters: new GetLoc(),
  userLocation: undefined,
  filterLocation: undefined,
  filterCallType: undefined,
  user: undefined,
  currentUser: undefined,
  currentUserPreference: undefined,
  managedUser: undefined,
  hasNotificationSet: undefined,
  kcyVerification: undefined,
  accessToken: undefined,
  unViewedNotifications: undefined,
  intercomVisible: undefined,
};

export const sharedReducer = createReducer(
  initialSharedState,
  on(storeAppConfigAction, (state, { data }) => ({
    ...state,
    appConfig: data,
  })),
  on(setGridProfilesFiltersAction, (state, { data }) => ({
    ...state,
    gridProfilesFilters:
      !(
        state.gridProfilesFilters?.latitude &&
        state.gridProfilesFilters?.longitude
      ) ||
      (data?.latitude && data?.longitude)
        ? data
        : state.gridProfilesFilters,
  })),
  on(storeUserLocationAction, (state, { data }) => ({
    ...state,
    userLocation: data,
    filterLocation: state.filterLocation || data,
  })),
  on(storeFilterCallType, (state, { data }) => ({
    ...state,
    filterCallType: data,
  })),
  on(storeFilterLocation, (state, { data }) => ({
    ...state,
    filterLocation: data,
  })),
  on(setBalanceAction, (state, { data }) => ({
    ...state,
    currentUser: { ...state.currentUser, creditBalance: data },
  })),
  on(setUserAction, (state, { data }) => ({
    ...state,
    user: data,
  })),
  on(setHasNotificationSetAction, (state, { hasNotificationSet }) => ({
    ...state,
    hasNotificationSet: hasNotificationSet,
  })),
  on(setKycVerificationAction, (state, { verification }) => ({
    ...state,
    kcyVerification: verification,
  })),
  on(setCurrentUserAction, (state, { data }) => ({
    ...state,
    currentUser: data,
  })),
  on(setCurrentUserPreferenceAction, (state, { data }) => ({
    ...state,
    currentUserPreference: data,
  })),
  on(emailVerifyAction, (state) => ({
    ...state,
    user: { ...state.user, emailverification: 1 },
  })),
  on(smsVerifyAction, (state) => ({
    ...state,
    user: { ...state.user, smsverification: 1 },
  })),
  on(phoneVerifyAction, (state) => ({
    ...state,
    user: { ...state.user, phoneverification: 1 },
  })),
  on(setManagedUserAction, (state, { data }) => ({
    ...state,
    managedUser: data,
  })),
  on(emailVerifyManagedAction, (state) => ({
    ...state,
    managedUser: { ...state.managedUser, emailverification: 1 },
  })),
  on(smsVerifyManagedAction, (state) => ({
    ...state,
    managedUser: { ...state.managedUser, smsverification: 1 },
  })),
  on(phoneVerifyManagedAction, (state) => ({
    ...state,
    managedUser: { ...state.managedUser, phoneverification: 1 },
  })),
  on(setAuthTokenAction, (state, { accessToken }) => ({
    ...state,
    accessToken: accessToken,
  })),
  on(setUnViewedNotificationsAction, (state, { count }) => ({
    ...state,
    unViewedNotifications: count,
  })),
  on(setIntercomVisible, (state, { data }) => ({
    ...state,
    intercomVisible: data,
  })),
  on(addUnViewedNotificationsAction, (state, { count }) => ({
    ...state,
    unViewedNotifications: Math.max(
      0,
      (state?.unViewedNotifications || 0) + count
    ),
  }))
);

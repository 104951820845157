import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigModel } from '@app/shared/models/config.model';
import { APP_CONFIG } from '@app/shared/models/constants';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataSpikeApplicantModel } from '@app/shared/models/data-spike/data-spike-applicant.model';
import { DataSpikeVerificationModel } from '@app/shared/models/data-spike/data-spike-verification.model';

@Injectable({
  providedIn: 'root',
})
export class DataspikeService {
  private readonly http = inject(HttpClient);
  private readonly config: ConfigModel = inject(APP_CONFIG);

  getApplicant(): Observable<DataSpikeApplicantModel> {
    return this.http
      .get<DataSpikeApplicantModel>(`${this.config.apiV3}/dataspike/applicant`)
      .pipe(catchError(() => of(null)));
  }

  createApplicant(
    fullName: string,
    gender: string,
    birthday: string
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.config.apiV3}/dataspike/applicant`, {
        fullName,
        gender,
        birthday,
      })
      .pipe(catchError(() => of(null)));
  }

  updateApplicant(
    fullName: string,
    gender: string,
    birthday: string
  ): Observable<boolean> {
    return this.http
      .patch<boolean>(`${this.config.apiV3}/dataspike/applicant`, {
        fullName,
        gender,
        birthday,
      })
      .pipe(catchError(() => of(null)));
  }

  createVerification(): Observable<DataSpikeVerificationModel> {
    return this.http
      .post<DataSpikeVerificationModel>(
        `${this.config.apiV3}/dataspike/verification`,
        {}
      )
      .pipe(catchError(() => of(null)));
  }

  getVerification(): Observable<DataSpikeVerificationModel> {
    return this.http
      .get<DataSpikeVerificationModel>(
        `${this.config.apiV3}/dataspike/verification`
      )
      .pipe(catchError(() => of(null)));
  }
}

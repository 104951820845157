import { Injectable } from '@angular/core';
import { NodeApiService } from '@app/shared/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkplaceService {
  private establishments$ = new BehaviorSubject<any[]>(null);
  public establishments = this.establishments$.asObservable();

  constructor(private nodeApi: NodeApiService) {}

  public resreshEstablishments(): void {
    this.getEstablishments().subscribe(response => {
      if (response.status === 200) {
        this.establishments$.next(response.result);
      }
    });
  }

  private getEstablishments(): Observable<any> {
    return this.nodeApi.get('/workplace/establishments');
  }

  public blockEstablishment(slug: string): Observable<any> {
    return this.nodeApi.post('/manageGingrs/blockUser', {
      slug: slug,
      action: 'BLOCK',
    });
  }

  public unBlockEstablishment(slug: string): Observable<any> {
    return this.nodeApi.post('/manageGingrs/blockUser', {
      slug: slug,
      action: 'UNBLOCK',
    });
  }

  public disconnect(slug: string): Observable<any> {
    return this.nodeApi.post('/manageGingrs/disconnect', { slug: slug }).pipe(
      map(response => {
        if (response.status === 200) {
          this.resreshEstablishments();
        }
        return response;
      })
    );
  }

  public decline(slug: string): Observable<any> {
    return this.nodeApi
      .post('/manageGingrs/connect/decline', { slug: slug })
      .pipe(
        map(response => {
          if (response.status === 200) {
            this.resreshEstablishments();
          }
          return response;
        })
      );
  }

  public reconnect(slug: string): Observable<any> {
    return this.nodeApi.post('/manageGingrs/reconnect', { slug: slug }).pipe(
      map(response => {
        if (response.status === 200) {
          this.resreshEstablishments();
        }
        return response;
      })
    );
  }

  public acceptConnection(slug: string): Observable<any> {
    return this.nodeApi
      .post('/manageGingrs/acceptConnection', { slug: slug })
      .pipe(
        map(response => {
          if (response.status === 200) {
            this.resreshEstablishments();
          }
          return response;
        })
      );
  }
}

import { SummaryLocation, Profile } from '@app/shared/models';
import { Location, NgIf, NgFor } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService, AppService } from '@app/shared/services';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { GeocodingService } from '@app/shared/services/geocoding.service';
import { tap } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileListComponent } from '../../../shared/components/profile-list/profile-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexModule } from '@angular/flex-layout/flex';
import { HeaderComponent } from '@app/shared/components';

interface ICountryData {
  content: object[];
  country: string;
  country_code_2: string;
  country_code_3: string;
  created_at: string;
  id: number;
  updated_at: string;
}

interface ICityData {
  content: object[];
  name: string;
  slug: string;
  latitude: string;
  longitude: string;
  id: number;
  landing_pages_region_id: number;
  landing_pages_country_id: number;
  image: string;
}
interface ILandingPageData {
  status: number;
  result: ICountryData;
}

interface ILandingPageCityData {
  status: number;
  result: ICityData;
}
interface IParams {
  country: string;
  landingdata?: string;
}

interface IData {
  city: string;
  type: string;
}

@Component({
  selector: 'gingr-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    NgIf,
    MatTabsModule,
    NgFor,
    ProfileListComponent,
    HeaderComponent,
  ],
})
export class LandingPageComponent implements OnInit {
  bgImage: string;
  contentType: string;
  data: IData = {} as IData;
  activeTab: number = 0;
  activeTabName: string;
  geoloc: SummaryLocation = new SummaryLocation();
  profiles: Observable<Profile[]>;
  profilesArray: Profile[];
  availableContentTypes: Array<string> = [];
  landingContent: Array<object> = [];
  locationName: string;
  defaultDataType = 'escorts';

  currentPage = 0;
  pageSize = 50;

  private initTabChange: boolean = true;
  private theCols = new ReplaySubject<number>(1);
  private cols = this.theCols.asObservable();

  constructor(
    private route: ActivatedRoute,
    private configService: ConfigService,
    private appService: AppService,
    private apiService: ApiService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public translation: L10nTranslationService,
    private geocoder: GeocodingService,
    private profilesService: ProfilesService,
    private observableMedia: MediaObserver,
    private router: Router,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.theCols.next(4);
    this.observableMedia.asObservable().subscribe((change) => {
      switch (change[0].mqAlias) {
        case 'xs':
          this.theCols.next(2);
          break;
        case 'sm':
        case 'md':
          this.theCols.next(3);
          break;
        case 'lg':
        case 'xl':
          this.theCols.next(4);
          break;
      }
    });

    this.route.params.subscribe(async (params: IParams) => {
      this.getLocationAndType(params);
      this.loadLandingData();
    });
  }

  getLocationAndType(params: IParams): void {
    if (!params) {
      this.setDefaultLocation();
    }

    const splitData = params.landingdata.split('-');
    this.data ??= {} as IData;
    this.data.city = splitData?.[1].toLowerCase();
    this.data.type = splitData?.[0];

    if (!this.data.city || !this.data.type) {
      this.setDefaultLocation();
    }
  }

  loadLandingData(): void {
    if (this.data.city) {
      this.apiService
        .get(`/landingPageCityContentByName/${this.data.city}`)
        .subscribe((data: ILandingPageCityData) => {
          this.separateLandingContent(data.result);
          if (this.availableContentTypes?.length < 1) {
            return;
          }
          if (
            !this.data.type ||
            !this.availableContentTypes.find((item) => item === this.data.type)
          ) {
            this.data.type = this.availableContentTypes.find(
              (item) => item === this.defaultDataType
            )
              ? this.defaultDataType
              : this.availableContentTypes[0];
            this.setUrl(this.data.type);
          }
          this.setActiveTab(this.data.type);

          const location = data.result.name.toLowerCase();

          this.geocoder.codeAddress(location).subscribe((loc_geo) => {
            this.geoloc.latitude = loc_geo[0].geometry.location.lat();
            this.geoloc.longitude = loc_geo[0].geometry.location.lng();
            this.getProfiles(false);
          });
        });
    }
  }

  paginate() {
    this.currentPage++;
    this.getProfiles(true);
  }

  getProfiles(paginate: boolean) {
    if (!paginate) {
      this.currentPage = 0;
    }

    this.profiles = this.profilesService.getLanding(
      this.geoloc,
      this.currentPage * this.pageSize,
      this.pageSize
    );
    this.profiles
      .pipe(
        tap((profileData: Profile[]) => {
          if (this.paginate) {
            if (!this.profilesArray?.length) {
              this.profilesArray = [];
            }
            this.profilesArray.push(...profileData);
          } else {
            this.profilesArray = profileData;
          }
        })
      )
      .subscribe(() => {});
  }

  separateLandingContent(data: any): void {
    this.locationName = data.name;
    this.bgImage = `${this.configService.config.cdnSeoImagesUrl}keyword-landing-pages/switzerland/${data.id}.jpg`;

    for (const contentElement of data.content) {
      const name = contentElement.content_type.toLowerCase();
      this.availableContentTypes.push(name);
    }
    for (let i = 0; i < this.availableContentTypes.length; i++) {
      const filter = data.content.filter((content) => {
        const name = content.content_type.toLowerCase();
        return name === this.availableContentTypes[i];
      });

      if (filter.length > 0) {
        this.landingContent[this.availableContentTypes[i]] = filter[0];
        if (this.landingContent[this.availableContentTypes[i]]) {
          this.landingContent[this.availableContentTypes[i]].tabId = i;
          const content =
            this.landingContent[this.availableContentTypes[i]].content;
          if (content) {
            this.landingContent[this.availableContentTypes[i]].content =
              this.sanitizer.bypassSecurityTrustHtml(
                content.replace('\\"', "'")
              );
          }
        }
      }
    }
  }

  setActiveTab(key: string): void {
    const type = key.toLowerCase();

    for (let i = 0; i < this.availableContentTypes.length; i++) {
      if (this.availableContentTypes[i] === type) {
        this.activeTab = i;
        this.activeTabName = type;
        this.contentType = this.availableContentTypes[i];
      }
    }
  }

  setUrl(type: string): void {
    this.location.go('/content/' + type + '-' + this.data.city);
  }

  tabChange(tab): void {
    if (this.initTabChange) {
      this.initTabChange = false;
      return;
    }
    this.activeTabName = this.availableContentTypes[tab.index];
    this.setActiveTab(this.availableContentTypes[tab.index]);
    this.setUrl(this.activeTabName);
  }

  setDefaultLocation(): void {
    this.contentType = this.availableContentTypes[0];
  }
}

import { createAction, props } from '@ngrx/store';
import {
  Config,
  GetLoc,
  SummaryCoordinates,
  SummaryLocation,
  User,
} from '@app/shared/models';
import { CurrentUser } from '@app/shared/models/current-user';
import { UserPreferenceModel } from '@app/shared/models/user-preference.model';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { DataSpikeVerificationModel } from '@app/shared/models/data-spike/data-spike-verification.model';

export const getAppConfigAction = createAction('[Shared] Get app config');

export const storeAppConfigAction = createAction(
  '[Shared] Store app config',
  props<{ data: Config }>()
);

export const setGridProfilesFiltersAction = createAction(
  '[Shared] Set grid profiles filters',
  props<{ data: GetLoc }>()
);

export const getUserLocationAction = createAction(
  '[Shared] Get user location',
  props<{ askAccess: boolean }>()
);

export const setKycVerificationAction = createAction(
  '[Shared] Set kyc verification',
  props<{ verification: DataSpikeVerificationModel }>()
);

export const getKycVerificationAction = createAction(
  '[Shared] Get kyc verification'
);

export const setHasNotificationSetAction = createAction(
  '[Shared] Set has notification set',
  props<{ hasNotificationSet: boolean }>()
);

export const getHasNotificationSetAction = createAction(
  '[Shared] Get has notification set'
);

export const storeUserLocationAction = createAction(
  '[Shared] Store user location',
  props<{ data: SummaryLocation }>()
);

export const getFilterCallType = createAction('[Shared] Get filter call type');

export const storeFilterCallType = createAction(
  '[Shared] Store filter call type',
  props<{ data: ConnectType }>()
);

export const storeFilterLocation = createAction(
  '[Shared] Store filter location',
  props<{ data: SummaryLocation }>()
);

export const logoutAction = createAction('[Shared] Logout user');

export const setUserAction = createAction(
  '[Shared] Set user',
  props<{ data: User }>()
);

export const getBalanceAction = createAction('[Shared] Get user balance');

export const setBalanceAction = createAction(
  '[Shared] Set user balance',
  props<{ data: number }>()
);

export const setCurrentUserAction = createAction(
  '[Shared] Set user',
  props<{ data: CurrentUser }>()
);

export const getCurrentUserAction = createAction('[Shared] Get user');

export const getCurrentUserPreferenceAction = createAction(
  '[Shared] Get user preference'
);

export const setCurrentUserPreferenceAction = createAction(
  '[Shared] Set user preference',
  props<{ data: UserPreferenceModel }>()
);

export const emailVerifyAction = createAction('[Shared] Email verify');

export const smsVerifyAction = createAction('[Shared] Sms verify');

export const phoneVerifyAction = createAction('[Shared] Phone verify');

export const setManagedUserAction = createAction(
  '[Shared] Set user managed',
  props<{ data: User }>()
);

export const emailVerifyManagedAction = createAction(
  '[Shared] Email verify managed'
);

export const smsVerifyManagedAction = createAction(
  '[Shared] Sms verify managed'
);

export const phoneVerifyManagedAction = createAction(
  '[Shared] Phone verify managed'
);

export const setAuthTokenAction = createAction(
  '[Shared] Set auth token',
  props<{ accessToken: string }>()
);

export const getUnViewedNotificationsAction = createAction(
  '[Shared] Get unViewed notifications'
);

export const setUnViewedNotificationsAction = createAction(
  '[Shared] Set unViewed notifications',
  props<{ count: number }>()
);

export const setIntercomVisible = createAction(
  '[Shared] Set intercom visible',
  props<{ data: boolean }>()
);

export const addUnViewedNotificationsAction = createAction(
  '[Shared] Add unViewed notifications',
  props<{ count: number }>()
);

<div class="reset-pass-container" ngClass.xs="mobile" ngClass.sm="mobile">
  <h4 mat-dialog-title class="color-system-yellow">{{ 'CONNECTION.Set password to activate your account' | translate: locale.language }}</h4>

  <form [formGroup]="setPasswordForm" name="setPasswordForm" id="set-password-form" #f="ngForm" (ngSubmit)="submitForm()" fxLayout="column">

    <mat-form-field class="form-input">
      <input formControlName="password" matInput placeholder="{{'CONNECTION.Password' | translate: locale.language }}" type="password">
      <mat-error *ngIf="setPasswordForm.controls['password'].hasError('required')">
        {{'RESET_PASSWORD_DIALOG.required' | translate: locale.language }}
      </mat-error>
      <mat-error *ngIf="setPasswordForm.controls['password'].hasError('pattern')">
        {{'RESET_PASSWORD_DIALOG.pattern' | translate: locale.language }}
      </mat-error>
      <mat-error *ngIf="setPasswordForm.controls['password'].hasError('server')">
        {{setPasswordForm.controls['password'].getError('server')}}
      </mat-error>
      <mat-error *ngIf="setPasswordForm.controls['password'].hasError('noMatch') ">
        {{'RESET_PASSWORD_DIALOG.noMatch' | translate: locale.language }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input">
      <input formControlName="password_confirmation" matInput type="password" placeholder="{{'CONNECTION.Confirm Password' | translate: locale.language }}" >
      <mat-error *ngIf="setPasswordForm.controls['password_confirmation'].hasError('required')">
        {{'RESET_PASSWORD_DIALOG.required' | translate: locale.language }}
      </mat-error>
      <mat-error *ngIf="setPasswordForm.controls['password_confirmation'].hasError('noMatch') ">
        {{'RESET_PASSWORD_DIALOG.noMatch' | translate: locale.language }}
      </mat-error>
    </mat-form-field>

    <button mat-raised-button [disabled]="!setPasswordForm.valid || isSubmitting" type="submit" color="accent">
      {{'CONNECTION.Set password' | translate: locale.language }}
    </button>
  </form>
</div>


import { Action } from '@ngrx/store';
import { Profile } from '@app/shared/models';

export const SEARCH = '[Profile] Search';
export const SEARCH_COMPLETE = '[Profile] Search Complete';
export const LOAD_ALL = '[Profile] Load all profiles';
export const LOAD_ALL_SUCCESS = '[Profile] Load All Profiles Complete';
export const LOAD = '[Profile] Load';
export const SELECT = '[Profile] Select';

export class SearchAction implements Action {
  readonly type = SEARCH;

  constructor(public payload: string) {}
}

export class SearchCompleteAction implements Action {
  readonly type = SEARCH_COMPLETE;

  constructor(public payload: Profile[]) {}
}

export class LoadAllAction implements Action {
  readonly type = LOAD_ALL;

  constructor(public payload: Profile[]) {}
}

export class LoadAllSuccessAction implements Action {
  readonly type = LOAD_ALL_SUCCESS;

  constructor(public payload: Profile[]) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: Profile) {}
}

export class SelectAction implements Action {
  readonly type = SELECT;

  constructor(public payload: number) {}
}

export type Actions =
  | SearchAction
  | SearchCompleteAction
  | LoadAllAction
  | LoadAllSuccessAction
  | LoadAction
  | SelectAction;

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LocationService, UserService } from '@app/shared/services';
import {
  addInfoToBooking,
  checkIntervalsExtend,
  getClientCountByLocation,
  getClientCountInArea,
  getGingrCountInArea,
  getInstaState,
  getProfile,
  getProfileExtraDetails,
  saveClientCountInArea,
  saveGingrCountInArea,
  saveInstaState,
  saveIntervalsExtend,
  saveProfile,
  startBookingForProfile,
} from '@app/instafeature/store/instafeature.actions';
import { CommonService } from '@app/instafeature/services/common.service';
import { Store } from '@ngrx/store';
import {
  getInstaStateSelector,
  getProfileSelector,
} from '@app/instafeature/store/instafeature.selectors';
import { InstaState } from '@app/instafeature/models/insta-state';
import { combineLatest, forkJoin, of } from 'rxjs';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { SocketService } from '@app/instafeature/services/socket.service';
import * as _ from 'lodash-es';
import { getAppConfig } from '@app/shared/reducers/shared.selectors';
import { ServiceProviders } from '@app/instafeature/models/service-provider';
import { Config } from '@app/shared/models';
import { UserAddressType } from '@app/shared/models/user-address.model';
import { getBookPlaceType } from '@app/book/store/book.selectors';
import { ConfigService } from '@app/shared/services/config.service';
import { getCurrentUser } from '@app/shared/reducers/user.selectors';
import { CurrentUser } from '@app/shared/models/current-user';
import { Router } from '@angular/router';
import { UserRoles } from '@app/shared/models/enum/userroles';

@Injectable({
  providedIn: 'root',
})
export class InstaFeatureEffects {
  private readonly configService = inject(ConfigService);
  private readonly router = inject(Router);

  getGingrCountInArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGingrCountInArea),
      switchMap(() =>
        this.locationService.currentDeviceLocation.pipe(
          filter((location) => !!location),
          take(1)
        )
      ),
      switchMap((location) => this.service.getGingrCountInArea(location)),
      map((data) => {
        return saveGingrCountInArea({ gingrs: data });
      })
    )
  );

  getClientCountInArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientCountInArea),
      switchMap(() => this.service.getClientsCountInArea()),
      map((data) => {
        return saveClientCountInArea({ clients: data });
      })
    )
  );

  getClientCountByLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientCountByLocation),
      switchMap(() =>
        this.locationService.currentDeviceLocation.pipe(
          filter((location) => !!location),
          take(1)
        )
      ),
      filter((location) => !!location),
      switchMap((location) => this.service.getClientsCountInArea()),
      map((data) => {
        return saveClientCountInArea({ clients: data });
      })
    )
  );

  getInstaState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstaState),
      switchMap((payload) =>
        combineLatest([
          of(payload.skipRedirect),
          of(payload.checkBalance),
          this.service.getInstaState(),
        ])
      ),
      withLatestFrom(this.store.select(getInstaStateSelector)),
      withLatestFrom(this.store.select(getCurrentUser)),
      tap(
        ([
          [[skipRedirect, checkBalance, instaState], currentState],
          currentUser,
        ]: [[[boolean, boolean, InstaState], InstaState], CurrentUser]) => {
          if (
            checkBalance !== false &&
            !instaState?.id &&
            currentUser.creditBalance < 0 &&
            (currentUser.role === UserRoles.SERVICE_PROVIDER_PRO ||
              currentUser.role === UserRoles.SERVICE_PROVIDER_BASIC)
          ) {
            this.router.navigate(['/credit/balance']);
          }
        }
      ),
      filter(
        ([
          [[skipRedirect, checkBalance, instaState], currentState],
          currentUser,
        ]: [[[boolean, boolean, InstaState], InstaState], CurrentUser]) =>
          !!instaState?.id ||
          checkBalance === false ||
          currentUser.creditBalance >= 0 ||
          (currentUser.role !== UserRoles.SERVICE_PROVIDER_PRO &&
            currentUser.role !== UserRoles.SERVICE_PROVIDER_BASIC)
      ),
      map(
        ([
          [[skipRedirect, checkBalance, instaState], currentState],
          currentUser,
        ]: [[[boolean, boolean, InstaState], InstaState], CurrentUser]) => {
          return saveInstaState({
            instaState: {
              ...instaState,
              skipRedirect: skipRedirect,
              outcallType: currentState?.outcallType || instaState.outcallType,
              gingrCallType:
                currentState?.callType === ConnectType.BOTH
                  ? currentState.callType
                  : null,
            },
          });
        }
      )
    )
  );

  checkIntervalsExtend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkIntervalsExtend),
      withLatestFrom(this.store.select(getInstaStateSelector)),
      switchMap(([action, insta]) =>
        this.service.checkIntervalsExtend(insta.id, insta.serviceProviderId)
      ),
      map((result: any) =>
        saveIntervalsExtend({ maxDuration: result.maxDuration })
      )
    )
  );

  getProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfile),
      withLatestFrom(this.locationService.currentDeviceLocation),
      withLatestFrom(this.store.select(getInstaStateSelector)),
      switchMap(([[action, location], instaState]) =>
        this.service.getProfile({
          providerId: action.id,
          latitude:
            instaState.callType === ConnectType.OUTCALL &&
            instaState?.address?.latitude
              ? instaState.address.latitude
              : location.latitude,
          longitude:
            instaState.callType === ConnectType.OUTCALL &&
            instaState?.address?.longitude
              ? instaState.address.longitude
              : location.longitude,
        })
      ),
      withLatestFrom(this.store.select(getProfileSelector)),
      filter(([profile]) => !!profile),
      withLatestFrom(this.store.select(getAppConfig)),
      map(([[profile, oldProfile], config]) => {
        if (oldProfile && oldProfile.id !== profile.id) {
          this.store.dispatch(getInstaState({ skipRedirect: true }));
        }
        this.parseProfile(profile, config);
        this.socketService.registerGingrBecomesBooked(profile.id);
        this.store.dispatch(getProfileExtraDetails({ id: profile.id }));
        return saveProfile({ profile: profile });
      })
    )
  );

  startBookingForProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(startBookingForProfile),
      withLatestFrom(
        this.locationService.currentDeviceLocation.pipe(
          filter((location) => !!location && !location.isFallback),
          take(1)
        ),
        this.store.select(getInstaStateSelector)
      ),
      switchMap(([action, location, instaState]) =>
        forkJoin({
          connect: this.service.connectUser(instaState.callType, location),
          profileId: of(action.profileId),
        })
      ),
      map(({ connect, profileId }) => {
        this.userService.openInstantBooking(
          { ignoreApi: true, prev: `/book/${profileId}` },
          '/instafeature/select-duration'
        );
        return getProfile({ id: profileId });
      })
    )
  );

  addInfoToBooking = createEffect(() =>
    this.actions$.pipe(
      ofType(addInfoToBooking),
      switchMap(() =>
        this.store.select(getProfileSelector).pipe(
          withLatestFrom(this.store.select(getBookPlaceType)),
          filter(
            ([profile, callType]) =>
              !!profile &&
              ((callType === ConnectType.OUTCALL &&
                profile.location.addressType === UserAddressType.WORK_RANGE) ||
                (callType === ConnectType.INCALL &&
                  (profile.location.addressType ===
                    UserAddressType.WORK_PICKUP ||
                    profile.location.addressType ===
                      UserAddressType.WORK_PRIVATE ||
                    profile.location.addressType ===
                      UserAddressType.WORK_PUBLIC)))
          ),
          take(1),
          map(([profile, _]) => profile)
        )
      ),
      withLatestFrom(this.store.select(getInstaStateSelector)),
      map(([profile, instaState]) => {
        return saveInstaState({
          instaState: this.fillInInstaState(profile, instaState),
        });
      })
    )
  );

  getProfileExtraDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileExtraDetails),
      switchMap((action) =>
        this.service.getProfileExtraDetails({ id: action.id })
      ),
      withLatestFrom(this.store.select(getProfileSelector)),
      map(([profile, oldProfile]) => {
        return saveProfile({
          profile: {
            ...oldProfile,
            services: profile.services,
            reviews: profile.reviews,
            rating: profile.rating,
          },
        });
      })
    )
  );

  fillInInstaState(profile: ServiceProviders, instaState: InstaState) {
    const profileAvatar = profile.medias?.find((media) => media.isAvatar);
    const newState: InstaState = {
      ..._.cloneDeep(instaState),
      skipRedirect: true,
      bookingPartnerDto: {
        id: profile.id,
        avatarUrl: profileAvatar?.thumb,
        displayName: profile.displayName,
        latitude: profile.location.latitude,
        longitude: profile.location.longitude,
      },
      serviceProvider: _.cloneDeep(profile),
      travelType: google.maps.TravelMode.DRIVING,
    };
    if (instaState.callType === ConnectType.INCALL) {
      newState.address = {
        ...profile.location,
        address: profile.location.formattedAddress,
      };
    }

    return newState;
  }

  constructor(
    private actions$: Actions,
    private locationService: LocationService,
    private service: CommonService,
    private store: Store,
    private socketService: SocketService,
    private userService: UserService
  ) {}

  getConfigValue(item: string, value: any, property: string, config: Config) {
    const itemFound = config[item].find((d: any) => d.id === value);
    if (itemFound?.[property]) {
      return itemFound[property];
    } else {
      return '-';
    }
  }

  parseProfile(profile: ServiceProviders, config: Config) {
    profile.physicalDetails.ethnicity = config.origins.find(
      (item) => item.id === profile.physicalDetails.ethnicity
    )?.name;

    profile.physicalDetails.eyeColor = config.eyeColors.find(
      (item) => item.id === profile.physicalDetails.eyeColor
    )?.name;

    profile.physicalDetails.hairColor = config.hairColors.find(
      (item) => item.id === profile.physicalDetails.hairColor
    )?.name;

    profile.physicalDetails.genitaliaArea = config.talias.find(
      (item) => item.id === profile.physicalDetails.genitaliaArea
    )?.name;

    profile.medias.forEach((media) => {
      media.media = `${this.configService.config.mediaCdnUrl.slice(0, -1)}${
        media.media
      }`;

      media.thumb = `${this.configService.config.mediaCdnUrl.slice(0, -1)}${
        media.thumb
      }`;
    });
  }
}

<div
  *ngIf="releases && releases.length !== 0"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="content-container"
>
  <div fxFlexFill fxLayout="column">
    <h3 class="gg-heading">
      {{ 'PRESS.Press releases' | translate: locale.language }}
    </h3>
  </div>
  <mat-grid-list
    [cols]="cols | async"
    [rowHeight]="rowHeight | async"
    gutterSize="10px"
  >
    <mat-grid-tile *ngFor="let release of releases">
      <mat-card appearance="outlined" fxLayout="column">
        <mat-card-header>
          <div mat-card-avatar>
            <img
              class="mat-card-avatar__image"
              [src]="release.avatar"
              [alt]="release.title"
            />
          </div>
          <mat-card-title>{{ release.title }}</mat-card-title>
          <mat-card-subtitle>{{
            release.subTitle | truncate: 18
          }}</mat-card-subtitle>
        </mat-card-header>
        <img
          *ngIf="!release.video"
          mat-card-image
          [alt]="release.title"
          [src]="release.imageSrc"
        />
        <video
          preload="metadata"
          class="releaseVideo"
          (mouseenter)="release.hover = true"
          (mouseleave)="release.hover = false"
          *ngIf="release.video"
          mat-card-image
          width="400"
          height="205"
          [controls]="release.hover"
          controlsList="nodownload"
        >
          <source
            [src]="release.video"
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
        </video>
        <mat-card-content class="press-relises-cart-title">
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="date-overlay"
            [class.hover]="release.hover"
          >
            <span>{{ getDayMonthYear(release.date, 'dayMonth') }}</span>
            <span class="date-overlay__year">{{
              getDayMonthYear(release.date, 'year')
            }}</span>
          </div>
          <audio
            preload="metadata"
            *ngIf="release.audio"
            controls
            controlsList="nodownload"
          >
            <source [src]="release.audio" type="audio/mp3" />
          </audio>
          <p>{{ release.description | truncate: 60 }}</p>
        </mat-card-content>
        <span fxFlex></span>
        <mat-card-actions fxLayout="row">
          <a
            *ngIf="release.link"
            [href]="release.link"
            target="_blank"
            mat-button
            class="mat-button mat-accent mat-ink-ripple go-to-article-btn"
            >Go to Article</a
          >
          <div fxLayout="row" class="social-logos" fxLayoutAlign="end center">
            <div *ngIf="release.facebookLink" class="social-logos__logo">
              <a [href]="release.facebookLink" target="_blank">
                <mat-icon
                  fontSet="fontawesome"
                  fontIcon="fa-facebook-square"
                  class="fa"
                ></mat-icon>
              </a>
            </div>
            <div *ngIf="release.twitterLink" class="social-logos__logo">
              <a [href]="release.twitterLink" target="_blank">
                <mat-icon
                  fontSet="fontawesome"
                  fontIcon="fa-twitter-square"
                  class="fa"
                ></mat-icon>
              </a>
            </div>
            <div *ngIf="release.youtubeLink" class="social-logos__logo">
              <a [href]="release.youtubeLink" target="_blank">
                <mat-icon
                  fontSet="fontawesome"
                  fontIcon="fa-youtube-square"
                  class="fa"
                ></mat-icon>
              </a>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>

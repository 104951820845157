import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PasswordMatcher, CaptchaValidator } from '@app/shared/validators';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  // Regex
  public static EMAIL_REGEX: RegExp =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  public PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  public PHONE_REGEX: RegExp = /^\d{6,}$/;
  public URL_REGEX: RegExp =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
  public HTTP_URL_REGEX: RegExp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

  // Custom validators object
  public validators: Validators = new Validators();

  constructor() {}

  getValidators(): Validators {
    return Validators;
  }
  // Required
  RequiredFormControl(): UntypedFormControl {
    return new UntypedFormControl('', [Validators.required]);
  }

  // Email
  EmailFormControl(): UntypedFormControl {
    return new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(ValidatorService.EMAIL_REGEX),
    ]);
  }

  // Phone
  PhoneFormControl(): UntypedFormControl {
    return new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(this.PHONE_REGEX),
    ]);
  }

  getPhoneFormControlValidators(): any[] {
    return [Validators.required, Validators.pattern(this.PHONE_REGEX)];
  }

  // Password
  PasswordFormControl(): UntypedFormControl {
    return new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(this.PASSWORD_REGEX),
    ]);
  }

  PasswordConfirmFormControl(): UntypedFormControl {
    return new UntypedFormControl('', [
      PasswordMatcher,
      Validators.required,
      Validators.pattern(this.PASSWORD_REGEX),
    ]);
  }

  // Captcha
  CaptchaFormControl(): UntypedFormControl {
    return new UntypedFormControl('', [CaptchaValidator, Validators.required]);
  }

  CustomControl(validators: Array<any>): UntypedFormControl {
    const tmpArray = Array();

    for (let i = 0; i < validators.length; i++) {
      const cv = validators[i];
      if (cv.indexOf('(') > -1) {
        const method = cv.split('(')[0];
        let argument = cv.split('(')[1].replace(')', '');

        if (!isNaN(parseInt(argument, 10))) {
          argument = parseInt(argument, 10);
        }

        tmpArray.push(Validators[method].apply(null, [argument]));
      } else {
        tmpArray.push(Validators[validators[i]]);
      }
    }

    return new UntypedFormControl('', tmpArray);
  }
}

import {UserService} from '@app/shared/services';
import {ActivatedRoute} from '@angular/router';
import {AccountService} from '@app/account/services';
import {ManageGingrsService} from '@app/manage-gingrs/services';

export class ProfileObservableHelper {
  public userDataService: any;
  public controlService: any;
  public isManaged: boolean;
  public isDefaultSettings: boolean;
  constructor(protected userService: UserService,
              protected accountService: AccountService,
              protected manageGingrsService: ManageGingrsService,
              protected route: ActivatedRoute) {
    const routeDataSnapshot: any = this.route.data;
    this.isManaged = routeDataSnapshot.value.isManager;
    this.isDefaultSettings = routeDataSnapshot.value.isDefaultSettings;
    if (this.isManaged) {
      this.userDataService = this.manageGingrsService;
      this.controlService = this.manageGingrsService;
    } else {
      this.userDataService = this.userService;
      this.controlService = this.accountService;
    }
  }
}

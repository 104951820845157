import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { L10N_LOCALE, L10nLocale, L10nTranslationService, L10nTranslatePipe } from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'gingr-show-video',
    templateUrl: './show-video.component.html',
    styleUrls: ['./show-video.component.scss'],
    standalone: true,
    imports: [
        FlexModule,
        MatIconModule,
        NgIf,
        NgTemplateOutlet,
        L10nTranslatePipe,
    ],
})
export class ShowVideoComponent implements OnInit {
  public playersStates: any = {
    gingr: false,
    client: false,
    agency: false,
  };

  lang: string;
  mediaCdnUrl = this.configService.config.mediaCdnUrl;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translationService: L10nTranslationService,
    private dialogRef: MatDialogRef<ShowVideoComponent>,
    private configService: ConfigService,
    private ngZone: NgZone
  ) {}

  stopVideo() {
    if (
      !this.playersStates.agency &&
      !this.playersStates.gingr &&
      !this.playersStates.client
    ) {
      this.ngZone.run(() => this.dialogRef.close());
    } else {
      this.playersStates = {
        gingr: false,
        client: false,
        agency: false,
      };
    }
  }

  ngOnInit() {
    const matDialog: any = document.getElementsByTagName(
      'mat-dialog-container'
    );
    matDialog[0].style.border = '2px solid #FFD95F';
    matDialog[0].style.padding = '0';

    this.lang = this.translationService.getLocale().language;
  }
}

<ng-container *ngIf="checkVisibility()">
  <gingr-last-seen [isAuth]="user"></gingr-last-seen>
</ng-container>

<div
  class="md:hidden bg-base-100 shadow justify-center py-[20px] flex items-center cursor-pointer fixed left-0 right-0 z-50 transition-all"
  [ngClass]="{ 'top-[-5px]': !showFilter, 'top-[64px]': showFilter }"
  (click)="filterOpen()"
>
  <span class="text-primary text-lg capitalize me-2">
    {{ 'FILTER.Filter' | translate: locale.language }}</span
  ><span
    *ngIf="filtersSelectedMainGrid$ | async as filtersSelected"
    class="text-primary text-lg me-2"
    >{{ filtersSelected > 9 ? ' (9+)' : ' (' + filtersSelected + ') ' }}</span
  >
  <svg-icon
    src="/assets/icons/filter-new-primary.svg"
    [svgStyle]="{
      'width.px': 24,
      'height.px': 24,
      fill: '#FFD640'
    }"
  ></svg-icon>
</div>

<ng-container *ngIf="!isLoading; else loadingGlobe">
  <mat-grid-list
    *ngIf="profiles?.length"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    [infiniteScrollContainer]="'.app-wrapper'"
    [fromRoot]="true"
    (scrolled)="paginationControl()"
    class="profile-grid-list pt-[64px] md:pt-0 ml-1 mr-1 mt-1"
    #theListRef
    [cols]="cols | async"
    rowHeight="3:4"
    [gutterSize]="gutterSize"
  >
    <div [@gridAnimation]="profiles?.length">
      <mat-grid-tile
        class="grid-tile rounded shadow-xs"
        *ngIf="!isMobile && showVideoBlock"
      >
        <div class="videos-container opened" fxLayout="row">
          <div
            class="content"
            fxFlex="100"
            fxLayout="column"
            (click)="showVideoModal()"
          >
            <div
              fxLayout="row"
              class="text my-6 text-white"
              fxLayoutAlign="center center"
            >
              <p>
                {{ 'COMMON.How Gingr' | translate: locale.language }}
                <span class="text-primary">{{
                  'COMMON.Works' | translate: locale.language
                }}</span>
              </p>
            </div>
            <div fxLayout="column" class="icons" fxLayoutAlign="end center">
              <div
                fxLayout="row"
                class="dynamic"
                fxLayoutAlign="space-around start"
              >
                <img
                  class="gingr"
                  alt="gingr"
                  src="/assets/images/video/Gingr.svg"
                />
                <img
                  class="hookup"
                  alt="hookup"
                  src="/assets/images/video/HookUp.svg"
                />
                <img
                  class="agency"
                  alt="agency"
                  src="/assets/images/video/Agency.svg"
                />
                <img
                  class="instafuck"
                  alt="instafuck"
                  src="/assets/images/video/Instafuck.svg"
                />
                <img
                  class="radar"
                  alt="radar"
                  src="/assets/images/video/Radar.svg"
                />
              </div>
              <img
                class="cloud"
                alt="cloud"
                src="/assets/images/video/Cloud.svg"
              />
            </div>
          </div>
        </div>
      </mat-grid-tile>

      <mat-grid-tile
        *ngFor="let profile of profiles; index as i"
        [id]="profile.id"
        class="grid-tile profile-tiles-new rounded shadow-xs group"
      >
        <div class="tag_wrapper">
          <div
            class="gingr-coming-soon-block"
            *ngIf="profile.coming_soon_days && profile.coming_soon_days > 0"
          >
            <h5 class="gingr-coming-soon-title">
              {{ 'PROFILE_LIST.Back in' | translate: locale.language }}
              <span
                >{{ profile.coming_soon_days }}
                {{ 'PROFILE_LIST.Days' | translate: locale.language }}</span
              >
            </h5>
          </div>
          <div
            class="gingr-coming-soon-block"
            *ngIf="profile.vacation && !profile.coming_soon_days"
          >
            <h5 class="gingr-coming-soon-title">
              {{ 'PROFILE_LIST.On vacation' | translate: locale.language }}
            </h5>
          </div>
          <swiper-container
            #swiperContainer
            *ngIf="profile.medias?.length"
            (mouseenter)="
              swiperContainer.navigation =
                !profile.vacation &&
                profile.medias?.length > 1 &&
                windowWidth >= 600
            "
            (mouseleave)="swiperContainer.navigation = false"
          >
            <swiper-slide
              *ngFor="let image of profile.medias"
              slides-per-view="1"
            >
              <div
                class="slide-img hover:scale-110 ease-out duration-300"
                [defaultImage]="defaultImage"
                [lazyLoad]="
                  configService.config.mediaCdnUrl.slice(0, -1) + image.media
                "
                (click)="goProfile(profile)"
              ></div>
            </swiper-slide>
          </swiper-container>

          <div
            *ngIf="profile.new"
            class="flex items-end absolute w-[100px] h-[48px] -top-[17px] -left-[45px] z-20 -rotate-45 bg-info-content shadow-md pb-1"
          >
            <span
              class="text-center text-[11px] text-base-200 font-extrabold w-100 lowercase first-letter:capitalize"
              >{{ 'COMMON.NEW' | translate: locale.language }}</span
            >
          </div>
          <div
            *ngIf="profile.verified"
            class="flex items-end absolute w-[150px] h-[24px] top-[17px] -left-[45px] z-10 -rotate-45 bg-base-200 shadow-md pb-1"
          >
            <span
              class="text-center text-[11px] text-white font-bold w-100 lowercase first-letter:capitalize"
              >{{ 'COMMON.VERIFIED' | translate: locale.language }}</span
            >
          </div>
          <div
            *ngIf="profile.premium"
            class="vacation-tag vacation-tag-premium"
          >
            {{ 'COMMON.PREMIUM' | translate: locale.language }}
          </div>

          <div
            *ngIf="!!profile.lowestPrice"
            class="flex flex-row items-center justify-center absolute top-3 right-0 p-1 bg-base-200 rounded-l-full shadow-md z-10"
          >
            <ng-container
              *ngTemplateOutlet="
                pricingTemplate;
                context: {
                  symbol: profile.currencySymbol,
                  currency: profile.currency,
                  amount: profile.lowestPrice
                }
              "
            ></ng-container>
          </div>

          <div
            class="flex flex-row items-center justify-center absolute top-3 right-0 p-1 bg-base-200 rounded-l-full shadow-md z-10"
            *ngIf="
              profile.admissionFee &&
              (profile.role === 'ESTABLISHMENT_BASIC' ||
                profile.role === 'ESTABLISHMENT_PRO')
            "
          >
            <ng-container
              *ngTemplateOutlet="
                pricingTemplate;
                context: {
                  symbol: profile.symbol,
                  currency: profile.currency,
                  amount: profile.admissionFee
                }
              "
            ></ng-container>
          </div>

          <div
            class="grid-footer bg-gradient-to-t from-black to-transparent absolute md:-bottom-[43px] group-hover:md:bottom-0 bottom-0 w-full z-50 p-4 transition-all"
            [class.isOpen]="selectedItem === profile.id"
          >
            <div class="footer-row-location">
              <ng-container *ngIf="profile.distance">
                <div class="flex pt-3 justify-between md:pb-3 pb-0">
                  <div class="flex flex-row items-center">
                    <svg-icon
                      src="assets/svg-icons/booking/meeting-point.svg"
                      [svgStyle]="{
                        'width.px': 14,
                        'height.px': 14,
                        fill: '#FFD640'
                      }"
                    ></svg-icon>
                    <span class="text-sm ms-1"
                      >{{
                        profile.distance.toFixed(profile.distance < 10 ? 1 : 0)
                      }}
                      km</span
                    >
                  </div>
                  <div
                    class="flex flex-row items-center"
                    *ngIf="profile.readyNow"
                  >
                    <svg-icon
                      src="assets/svg-icons/filters/ready-now.svg"
                      [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
                    ></svg-icon>
                    <span class="text-[#00FF00] text-sm font-[500] ms-1">{{
                      'COMMON.Ready Now' | translate: locale.language
                    }}</span>
                  </div>
                </div>
              </ng-container>
            </div>

            <div
              class="footer-row-name hidden md:flex justify-between items-center"
            >
              <span class="profile-name">
                <mat-icon
                  *ngIf="profile.vacation"
                  class="material-icons vacation"
                  >airplanemode_active</mat-icon
                >
                <span class="name text-[18px]">{{ profile.displayName }}</span>
              </span>
              <div class="right-card-controls">
                <svg-icon
                  src="assets/svg-icons/filters/video.svg"
                  [svgStyle]="{
                    'width.px': 16,
                    'height.px': 19,
                    fill: '#FFD640'
                  }"
                  *ngIf="profile.video > 0"
                  class="item-icon"
                  (click)="goProfile(profile, 'video')"
                ></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </div>
  </mat-grid-list>
</ng-container>
<ng-template #pricingTemplate let-amount="amount">
  <span
    class="flex items-center justify-center bg-white rounded-full text-base-200 text-[9px] font-bold aspect-square w-[21px] h-[21px] mr-1"
    >CHF</span
  >
  <span class="text-[13px] font-medium mr-1">{{ amount }}</span>
</ng-template>

<ng-template #loadingGlobe>
  <gingr-busy-overlay [text]="'Loading'"></gingr-busy-overlay>
</ng-template>

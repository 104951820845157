import { catchError, map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { ApiService, NodeApiService, UserService } from '@app/shared/services';
import { Observable, of } from 'rxjs';
import { APP_CONFIG } from '@app/shared/models/constants';
import { ClientAccountCommand } from '@app/account/containers/profile-v2/models/client-account.command';
import { HttpClient } from '@angular/common/http';
import { GingrAccountCommand } from '@app/account/containers/profile-v2/models/gingr-account.command';
import { CurrentUser } from '@app/shared/models/current-user';
import { ClientPersonalDetails } from '@app/account/containers/profile-v2/models/client-personal-details';
import { GingrPersonalDetails } from '@app/account/containers/profile-v2/models/gingr-personal-details';
import { GingrExtraDetailsCommand } from '@app/account/containers/profile-v2/models/gingr-extra-details.command';
import { GingrPricingCommand } from '@app/account/containers/profile-v2/models/gingr-pricing.command';
import { GingrAvailabilityCommand } from '@app/account/containers/profile-v2/models/gingr-availability.command';
import { GingrServicesCommand } from '@app/account/containers/profile-v2/models/gingr-services.command';
import { BlockedCountriesCommand } from '@app/account/containers/settings-v2/models/blocked-countries.command';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly config = inject(APP_CONFIG);
  private readonly http = inject(HttpClient);

  constructor(
    private api: ApiService,
    private nodeApi: NodeApiService,
    private userService: UserService
  ) {}

  /**
   * Update general using for
   * @param key
   * @param data
   */
  update(key, data): Observable<any> {
    return this.api.post(key, data);
  }

  updateVacation(data) {
    return this.nodeApi.post('/vacationMode', data);
  }

  updateAccountLanguage(language: number): Observable<any> {
    return this.http.post(
      `${this.config.apiV3}/user/account/language/${language}`,
      {}
    );
  }

  createMasterKey(masterKey): Observable<any> {
    return this.api.post('/createMasterKey', masterKey).pipe(
      map((result) => {
        return result;
      })
    );
  }

  updateMasterKey(masterKey): Observable<any> {
    return this.api.post('/updateMasterKey', masterKey).pipe(
      map((result) => {
        return result;
      })
    );
  }

  /**
   * Change password
   * @param password
   */
  changePassword(password: string): Observable<Response> {
    const pass = { password: password };
    return this.api.post('/updatePassword', pass);
  }

  disableMode(disable: boolean): Observable<any> {
    return this.http.post(
      `${this.config.apiV3}/user/account/disable/${disable}`,
      {}
    );
  }

  delete(): Observable<Response> {
    return this.api.post('/delete');
  }

  /**
   * Send Email Verification
   */
  sendEmailVerification(slug?: string): Observable<Response> {
    return this.api.post('/sendEmailVerification');
  }

  /**
   * Send Sms Verification
   */
  sendSmsVerification(slug?: string): Observable<Response> {
    return this.api.post('/sendSmsVerification');
  }

  /**
   * Pending inserting code
   * @param code
   * @param slug
   */
  sendSmsConfirmation(code: string, slug?: string): Observable<Response> {
    const codeJson = { sms: code };
    return this.api.post('/confirmationSms', codeJson).pipe(
      map((result) => {
        if (result.status === 200) {
          this.userService.smsVerifiedSuccess();
        }
        return result;
      })
    );
  }

  /**
   * Pending inserting code
   * @param code
   */
  sendLandLineConfirmation(code: string): Observable<Response> {
    const codeJson = { voice: code };
    return this.api.post('/confirmationLandLine', codeJson).pipe(
      map((result) => {
        if (result.status === 200) {
          this.userService.landLineVerifiedSuccess();
        }
        return result;
      })
    );
  }

  /**
   * Send Identify
   */
  sendIdentify(file: any, slug?: string): Observable<Response> {
    const fileJson = { identifyMedia: file };
    return this.nodeApi.post('/identify/id', fileJson);
  }

  /**
   * Send Identify Photo
   */
  sendIdentifyPhoto(file: any, slug?: string): Observable<Response> {
    const fileJson = { identifyMedia: file };
    return this.nodeApi.post('/identify/image', fileJson);
  }

  getUserData(): Observable<any> {
    return this.nodeApi.post('/userData');
  }

  setUserAccountClient(data: ClientAccountCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/client`,
      data
    );
  }

  setBlockedCountries(data: BlockedCountriesCommand): Observable<any> {
    return this.http.post(
      `${this.config.apiV3}/user/account/blocked-countries`,
      data
    );
  }

  setUserAccountGingr(data: GingrAccountCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/gingr`,
      data
    );
  }

  setUserPersonalDetailsGingr(
    data: GingrPersonalDetails
  ): Observable<CurrentUser> {
    return this.http
      .post<CurrentUser>(
        `${this.config.apiV3}/user/account/gingr-personal-details`,
        data
      )
      .pipe(catchError(() => of(null)));
  }

  setUserPersonalDetailsClient(
    data: ClientPersonalDetails
  ): Observable<CurrentUser> {
    return this.http
      .post<CurrentUser>(
        `${this.config.apiV3}/user/account/client-personal-details`,
        data
      )
      .pipe(catchError(() => of(null)));
  }

  setExtraDetailsGingr(
    data: GingrExtraDetailsCommand
  ): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/gingr/extra`,
      data
    );
  }

  setPricing(data: GingrPricingCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/pricing`,
      data
    );
  }

  setAvailability(data: GingrAvailabilityCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/availability`,
      data
    );
  }

  setServices(data: GingrServicesCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/services`,
      data
    );
  }

  goLive(): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/goLive`,
      {}
    );
  }
}

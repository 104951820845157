import { catchError, map, switchMap, debounceTime } from 'rxjs/operators';
import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, Scheduler, of, async } from 'rxjs';

import { ProfilesService } from '@app/shared/services';
import * as prof from '../actions/profile.actions';
import { Profile } from '@app/shared/models';

export const SEARCH_DEBOUNCE = new InjectionToken<number>('Search Debounce');
export const SEARCH_SCHEDULER = new InjectionToken<Scheduler>(
  'Search Scheduler'
);

@Injectable({
  providedIn: 'root',
})
export class ProfileEffects {
  
  loadAllProfiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<prof.LoadAllAction>(prof.LOAD_ALL),
    map((action) => action.payload),
    switchMap(() =>
      this.profilesService.getAll().pipe(
        map((profiles: Profile[]) => new prof.SearchCompleteAction(profiles)),
        catchError(() => of(new prof.SearchCompleteAction([])))
      )
    )
  ));

  
  filterProfiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<prof.SearchAction>(prof.SEARCH),
    debounceTime(this.debounce, this.scheduler || async),
    map((action) => action.payload),
    switchMap((query) => {
      if (Object.keys(query).length === 0 && query.constructor === Object) {
        return this.profilesService.getAll().pipe(
          map((profiles: Profile[]) => new prof.SearchCompleteAction(profiles)),
          catchError(() => of(new prof.SearchCompleteAction([])))
        );
      }

      return this.profilesService.getAll().pipe(
        map((profiles: Profile[]) => new prof.SearchCompleteAction(profiles)),
        catchError(() => of(new prof.SearchCompleteAction([])))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private profilesService: ProfilesService,
    @Optional()
    @Inject(SEARCH_DEBOUNCE)
    private debounce: number = 300,
    @Optional()
    @Inject(SEARCH_SCHEDULER)
    private scheduler: Scheduler
  ) {}
}

<!--<h1 mat-dialog-title>Login</h1>-->
<div
  class="new-register-container pt-[30px] md:pr-[30px]"
  [class.new-confirmEmail-container]="confirmEmail"
  ngClass.xs="mobile"
  ngClass.sm="mobile"
>
  <ng-container *ngIf="!confirmEmail">
    <img
      src="./assets/icons/cross.svg"
      alt="Close Icon"
      width="15"
      class="close absolute right-[20px] top-[20px] cursor-pointer block md:hidden"
      (click)="closeDialog()"
    />

    <div
      class="close absolute right-[-10px] top-[20px] cursor-pointer hidden lg:block"
    >
      <img
        src="./assets/icons/close-new.svg"
        alt="Close Icon new"
        width="24"
        (click)="closeDialog()"
      />
    </div>
    <div>
      <!-- form -->
      <h4
        class="text-primary text-[24px] font-[600] mb-[40px] capitalize"
        [ngClass]="{ est: popupTitleSize }"
      >
        {{ popupTitle }}
      </h4>
      <div class="w-100">
        <form
          [formGroup]="clientForm"
          name="clientForm"
          id="registration-form"
          #f="ngForm"
          (ngSubmit)="registerClient()"
          class="reg-form"
        >
          <div fxLayout="column">
            <mat-form-field>
              <mat-label
                >{{
                  'DIALOG-LOGIN-REGISTER.Email' | translate: locale.language
                }}
              </mat-label>
              <input
                formControlName="email"
                matInput
                [(ngModel)]="regClient.email"
              />
              <mat-error
                *ngIf="customFormControl.hasError('minlength')"
                class="leading-[1.3]"
                >{{
                  'DIALOG-LOGIN-REGISTER.Please enter a valid email address'
                    | translate: locale.language
                }}</mat-error
              >
              <mat-error
                *ngIf="customFormControl.hasError('required')"
                class="leading-[1.3]"
                >{{
                  'DIALOG-LOGIN-REGISTER.Email is required'
                    | translate: locale.language
                }}</mat-error
              >
            </mat-form-field>
          </div>

          <div formGroupName="passwords" fxLayout="column">
            <mat-form-field>
              <mat-label
                >{{
                  'DIALOG-LOGIN-REGISTER.Password ' | translate: locale.language
                }}
              </mat-label>
              <input
                formControlName="password"
                matInput
                name="password"
                id="password2"
                [(ngModel)]="regClient.password"
                [type]="showPassword ? 'text' : 'password'"
              />
              <mat-icon
                class="cursor-pointer show-pass text-primary"
                (click)="showPassword = !showPassword"
                >remove_red_eye</mat-icon
              >
              <mat-error
                *ngIf="passwordFormControl.hasError('pattern')"
                class="leading-[1.3]"
                >{{
                  'DIALOG-LOGIN-REGISTER.Password must contain letters, numbers, uppercase letters'
                    | translate: locale.language
                }}</mat-error
              >
              <mat-error
                *ngIf="passwordFormControl.hasError('required')"
                class="leading-[1.3]"
                >{{
                  'DIALOG-LOGIN-REGISTER.Password is required'
                    | translate: locale.language
                }}</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <mat-label
                >{{
                  'DIALOG-LOGIN-REGISTER.Confirm Password '
                    | translate: locale.language
                }}
              </mat-label>
              <input
                testval="password"
                formControlName="passwordc"
                matInput
                name="passwordc"
                id="passwordc"
                [(ngModel)]="regClient.passwordc"
                [type]="showConfirmPassword ? 'text' : 'password'"
              />
              <mat-icon
                class="cursor-pointer how-pass text-primary"
                (click)="showConfirmPassword = !showConfirmPassword"
                >remove_red_eye</mat-icon
              >
              <mat-error
                *ngIf="passwordCFormControl.hasError('pattern')"
                class="leading-[1.3]"
                >{{
                  'DIALOG-LOGIN-REGISTER.Password must contain letters, numbers, uppercase letters'
                    | translate: locale.language
                }}</mat-error
              >
              <mat-error
                *ngIf="passwordCFormControl.hasError('required')"
                class="leading-[1.3]"
                >{{
                  'DIALOG-LOGIN-REGISTER.Password is required'
                    | translate: locale.language
                }}</mat-error
              >
              <mat-error
                *ngIf="passwordCFormControl.hasError('noMatch')"
                class="leading-[1.3]"
                >{{
                  'DIALOG-LOGIN-REGISTER.Password does not match'
                    | translate: locale.language
                }}</mat-error
              >
            </mat-form-field>
          </div>

          <div fxLayout="column" class="mt-4">
            <mat-form-field class="mb-0">
              <mat-label
                >{{
                  'DIALOG-LOGIN-REGISTER.Promocode' | translate: locale.language
                }}
              </mat-label>
              <input
                formControlName="promocode"
                matInput
                [(ngModel)]="regClient.promocode"
              />
            </mat-form-field>
          </div>

          <mat-card
            appearance="outlined"
            class="privacy-policy p-0"
            *ngIf="showAdditionalField('privacy_policy')"
          >
            <mat-card-content>
              <section>
                <mat-checkbox
                  formControlName="terms_conditions"
                  [(ngModel)]="regClient.terms_conditions"
                >
                  {{
                    'DIALOG-LOGIN-REGISTER.I agree to the'
                      | translate: locale.language
                  }}
                  <a
                    (click)="navigateAway('/info/terms')"
                    class="underline text-primary"
                    >{{
                      'DIALOG-LOGIN-REGISTER.terms and conditions,'
                        | translate: locale.language
                    }}</a
                  >
                </mat-checkbox>
                <mat-checkbox
                  formControlName="privacy_policy"
                  [(ngModel)]="regClient.privacy_policy"
                  class="items-start"
                >
                  {{
                    'DIALOG-LOGIN-REGISTER.I am over 18 years old and accept the'
                      | translate: locale.language
                  }}
                  <a
                    (click)="navigateAway('/info/privacy-policy')"
                    class="underline text-primary"
                    >{{
                      'DIALOG-LOGIN-REGISTER.privacy policy'
                        | translate: locale.language
                    }}.</a
                  >&nbsp;
                  <a
                    (click)="navigateAway('/info/content-monitor')"
                    class="underline text-primary"
                    >{{
                      'DIALOG-LOGIN-REGISTER.Content monitoring policy'
                        | translate: locale.language
                    }}.</a
                  >
                </mat-checkbox>

                <mat-error
                  class="pt-2 leading-[1.3] text-xs"
                  [style.visibility]="
                    (clientForm.controls['privacy_policy'].touched &&
                      (clientForm.controls['privacy_policy'].hasError(
                        'privacy_policy'
                      ) ||
                        clientForm.controls['privacy_policy'].hasError(
                          'required'
                        ))) ||
                    (clientForm.controls['terms_conditions'].touched &&
                      (clientForm.controls['terms_conditions'].hasError(
                        'terms_conditions'
                      ) ||
                        clientForm.controls['terms_conditions'].hasError(
                          'required'
                        )))
                      ? 'visible'
                      : 'collapse'
                  "
                  >{{
                    'DIALOG-LOGIN-REGISTER.You must accept LONG TEXT'
                      | translate: locale.language
                  }}</mat-error
                >
              </section>
            </mat-card-content>
          </mat-card>
          <mat-card
            appearance="outlined"
            *ngIf="clientForm.hasError('serverErrors')"
            class="serverErrrors"
          >
            <mat-card-content>
              <section>
                <mat-error class="leading-[1.3]">{{
                  clientForm.getError('serverErrors')
                }}</mat-error>
              </section>
            </mat-card-content>
          </mat-card>

          <button
            class="btn btn-primary bg-primary mb-5 md:mb-0 mt-5 text-[18px] w-full shadow"
            (click)="markFormGroupTouched(clientForm)"
            type="submit"
          >
            {{ 'DIALOG-LOGIN-REGISTER.Register' | translate: locale.language }}
          </button>
        </form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="confirmEmail">
    <div>
      <img
        src="./assets/icons/cross.svg"
        alt="Close Icon"
        width="15"
        class="close absolute right-[20px] top-[20px] cursor-pointer block md:hidden"
        (click)="closeAllDialog()"
      />

      <div
        class="close absolute right-[-10px] top-[20px] cursor-pointer hidden lg:block"
      >
        <img
          src="./assets/icons/close-new.svg"
          alt="Close Icon new"
          width="24"
          (click)="closeAllDialog()"
        />
      </div>

      <div class="pt-[30px]">
        <h4 class="capitalize font-semibold mb-[30px] text-primary text-[23px]">
          {{
            'DIALOG-LOGIN-REGISTER.Check your email'
              | translate: locale.language
          }}
        </h4>
        <p class="text-white mb-[30px]">
          {{
            'DIALOG-LOGIN-REGISTER.Thank you for registering with Gingr New'
              | translate: locale.language
          }}
          <span class="text-primary">
            {{
              'DIALOG-LOGIN-REGISTER.instructions' | translate: locale.language
            }}
          </span>
        </p>

        <p class="text-white">
          {{
            'DIALOG-LOGIN-REGISTER.If you do not receive the email login and you will be prompted with a'
              | translate: locale.language
          }}
          <span class="confirmRegEmail__text_activate lowercase">{{
            'DIALOG-LOGIN-REGISTER.Resend' | translate: locale.language
          }}</span>
          {{ 'DIALOG-LOGIN-REGISTER.option' | translate: locale.language }}.
        </p>
      </div>
    </div>
  </ng-container>
</div>

import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@app/shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  PaymentMethod,
  TransactionModel,
  TransactionStatus,
  TransactionType,
} from '@app/credit/models/transaction.model';
import { TransactionDetailsModel } from '@app/credit/models/transaction-details.model';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  private readonly configService = inject(ConfigService);
  private readonly http = inject(HttpClient);

  getBalance(): Observable<number> {
    return this.http
      .get<number>(`${this.configService.config.apiV3}/credit-balance`)
      .pipe(catchError(() => of(null)));
  }

  topUp(
    amount: number,
    paymentMethod: PaymentMethod = PaymentMethod.BankTransfer
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this.configService.config.apiV3}/credit-balance/top-up`,
        { amount, paymentMethod }
      )
      .pipe(catchError(() => of(false)));
  }

  getTransactionList(
    skip: number,
    take: number,
    status: TransactionStatus,
    filter?: TransactionType
  ): Observable<TransactionModel[]> {
    return this.http
      .post<
        TransactionModel[]
      >(`${this.configService.config.apiV3}/credit-balance/list`, { skip, take, status, type: filter })
      .pipe(catchError(() => of([])));
  }

  getTransaction(id: number): Observable<TransactionDetailsModel> {
    return this.http
      .get<TransactionDetailsModel>(
        `${this.configService.config.apiV3}/credit-balance/${id}`
      )
      .pipe(catchError(() => of(null)));
  }
}

import { UserAvailableRoles } from '@app/shared/models/user';
import { Injectable } from '@angular/core';
export class RegUser {
  role: number;
  email: string;
  password: string;
  passwordc: string;
  language: number;
}
@Injectable({
  providedIn: 'root',
})
export class RegUserClient implements RegUser {
  role: number = UserAvailableRoles.client.baseRole;
  email: string;
  password: string;
  passwordc: string;
  captcha: string;
  language: number;
  location: {
    latitude: number;
    longitude: number;
  };
  promocode: string;
}

export class RegUserGingr {
  email: string;
  role: number = UserAvailableRoles.gingr.baseRole;
  phone: number;
  prefix_phone: string;
  language: number;
  password: string;
  passwordc: string;
  promocode: string;
}

export class RegUserAgency {
  email: string;
  role: number = UserAvailableRoles.agency.baseRole;
  phone: number;
  prefix_phone: string;
  language: number;
  password: string;
  passwordc: string;
  promocode: string;
}

export class RegUserBrothel {
  email: string;
  role: number = UserAvailableRoles.brothels.baseRole;
  phone: number;
  prefix_phone: string;
  language: number;
  password: string;
  passwordc: string;
  promocode: string;
}

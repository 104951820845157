import {
  enableProdMode,
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { ConfigModel } from '@app/shared/models/config.model';
import * as LD from 'launchdarkly-js-client-sdk';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { AppComponent } from '@app/app.component';
import { CookieModule } from 'ngx-cookie';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { appRoutes } from '@app/app.routing';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideL10nIntl, provideL10nTranslation } from 'angular-l10n';
import { authReducer } from '@app/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import {
  BrowserModule,
  bootstrapApplication,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { ApiInterceptor } from '@app/shared/helpers/api.interceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { AuthEffects } from '@app/core/auth/auth.effects';
import { StoreModule } from '@ngrx/store';
import { sharedReducer } from '@app/shared/reducers/shared.reducer';
import { SharedEffects } from '@app/shared/effects/shared.effects';
import { APP_CONFIG } from '@app/shared/models/constants';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {
  TranslationLoader,
  l10nConfig,
} from '@app/shared/helpers/translation-loader';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { instafeatureReducer } from '@app/instafeature/store/instafeature.reducer';
import { InstaFeatureEffects } from '@app/instafeature/store/instafeature.effects';
import { profileReducer } from '@app/profiles/reducers/profile.reducer';
import { collectionReducer } from '@app/profiles/reducers/collection.reducer';
import { searchReducer } from '@app/profiles/reducers/search.reducer';
import { ProfileEffects } from '@app/profiles/effects/profile.effects';
import { environment } from '@env/environment';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { isBot } from '@app/shared/helpers/user-agent.helper';
import { HammerConfig } from '@app/shared/helpers/hammer-config';
import { bookReducer } from '@app/book/store/book.reducer';
import { profileV2Reducer } from '@app/account/containers/profile-v2/store/profile-v2.reducer';
import { creditReducer } from '@app/credit/store/credit.reducer';
import { registerLocaleData } from '@angular/common';
import localeCh from '@angular/common/locales/de-CH';

registerLocaleData(localeCh);

fetch(`./assets/config.json?v=${Date.now()}`)
  .then((response) => response.json())
  .then(async (config: ConfigModel) => {
    const isNavigatorBot = isBot();
    const additionalProviders = [];
    const additionalProvidersFrom = [];

    if (isNavigatorBot) {
      additionalProviders.push({
        provide: APP_INITIALIZER,
        useFactory: () => {
          return () => {
            enableProdMode();
          };
        },
        multi: true,
      });
    } else {
      additionalProviders.push(
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => {
            return () => {
              if (environment.production) {
                enableProdMode();

                Sentry.init({
                  environment: config.sentryEnvironmentId,
                  dsn: config.sentryDsn,
                  tracesSampleRate: +config.sentrySampleRate,
                  release: `${config.sentryProjectName}@${config.releaseId}`,
                  ignoreErrors: ['Non-Error exception captured'],
                  integrations: [
                    new Sentry.BrowserTracing({
                      routingInstrumentation: Sentry.routingInstrumentation,
                    }),
                  ],
                  tracePropagationTargets: [
                    'localhost',
                    config.apiUrl.replace(/(^\w+:|^)\/\/|(\/+$)/g, ''),
                  ],
                });
              }
            };
          },
          deps: [Sentry.TraceService],
          multi: true,
        }
      );

      additionalProvidersFrom.push(
        StoreDevtoolsModule.instrument({
          maxAge: 25, //  Retains last 25 states
        })
      );
    }

    const context: LD.LDContext = {
      kind: 'user',
      anonymous: true,
      key: 'anon-user',
    };
    StaticUtilsService.LdClient = LD.initialize(
      config.launchDarklyKey,
      context
    );

    bootstrapApplication(AppComponent, {
      providers: [
        importProvidersFrom(
          BrowserModule,
          HammerModule,
          MatBottomSheetModule,
          MatDialogModule,
          ...[
            isNavigatorBot
              ? []
              : [
                  provideFirebaseApp(() => initializeApp(config.firebase)),
                  provideMessaging(() => getMessaging()),
                ],
          ],
          StoreModule.forRoot({
            auth: authReducer,
            shared: sharedReducer,
            instafeature: instafeatureReducer,
            profiles: profileReducer,
            collection: collectionReducer,
            search: searchReducer,
            book: bookReducer,
            profileV2: profileV2Reducer,
            credit: creditReducer,
          }),
          NgCircleProgressModule.forRoot({
            radius: 100,
            outerStrokeWidth: 6,
            innerStrokeWidth: 6,
            outerStrokeColor: '#ffd640',
            innerStrokeColor: '#ffd64030',
            animationDuration: 0,
          }),
          EffectsModule.forRoot([
            AuthEffects,
            SharedEffects,
            InstaFeatureEffects,
            ProfileEffects,
          ]),
          CookieModule.withOptions(),
          ...additionalProvidersFrom
        ),
        {
          provide: MatDialogRef,
          useValue: {},
        },
        provideAnimations(),
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig, deps: [] },
        provideHttpClient(withInterceptorsFromDi()),
        provideAngularSvgIcon(),
        provideRouter(appRoutes),
        provideL10nTranslation(l10nConfig, {
          translationLoader: TranslationLoader,
        }),
        provideL10nIntl(),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiInterceptor,
          multi: true,
        },
        { provide: APP_CONFIG, useValue: config },
        ...additionalProviders,
      ],
    }).catch((err) => console.error(err));
  });

<div
  class="last-seen-container"
  fxLayout="row"
  fxHide.xs
  [class.opened]="shown"
  [class.lsOpened]="shownLs"
>
  <div fxLayout="column">
    <div class="ls-trigger ls-first-trigger" (click)="toggleLastSeen()">
      <mat-icon class="lsIcon">chevron_left</mat-icon>
      <span>{{ 'COMMON.LAST_SEEN' | translate: locale.language }}</span>
    </div>
  </div>
  <div class="ls-content">
    <mat-list *ngIf="shownLs" class="items-list">
      <ng-container *ngFor="let item of lastSeen">
        <mat-list-item (click)="goToProfile(item.id)">
          <div fxLayout="column" fxLayoutAlign="center center">
            <img [src]="item.avatar" alt="icon" />
          </div>
        </mat-list-item>
      </ng-container>
      <mat-list-item *ngIf="!lastSeen?.length">
        <mat-icon class="empty"> person </mat-icon>
      </mat-list-item>
    </mat-list>
  </div>
</div>

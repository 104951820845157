import { createAction, props } from '@ngrx/store';
import { TopUpMethodType } from '@app/credit/models/top-up-method.type';

export const clearTopUpAction = createAction('[Book] Clear Top Up');

export const setTopUpAmountAction = createAction(
  '[Book] Set Top Up amount',
  props<{ amount: number }>()
);

export const setTopUpPaymentMethodAction = createAction(
  '[Book] Set Top Up payment method',
  props<{ paymentMethod: TopUpMethodType }>()
);

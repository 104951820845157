
<div class="press_login_container" ngClass.xs="mobile" ngClass.sm="mobile">

  <mat-icon class="material-icons close" (click)="closeDialog()">
    close
  </mat-icon>


  <!-- form -->
  <h4  class="t-a-left"><span class="color-system-yellow">{{'LOGIN_FORM.LOGIN' | translate: locale.language}}</span></h4>
  <form [formGroup]="pressLoginForm" class="login-form" name="pressLoginForm" id="press-login-form" #f="ngForm" (ngSubmit)="submitLogin()" fxLayout="column">

    <mat-form-field class="form-input" ngClass="login-username login-inputs">
      <input formControlName="email" matInput placeholder="{{'LOGIN_FORM.Email' | translate: locale.language}}">
      <mat-error *ngIf="pressLoginForm.controls.email.hasError('required')">
        {{'LOGIN_FORM.email is' | translate: locale.language}} <strong>{{'LOGIN_FORM.required' | translate: locale.language}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input" ngClass="login-password login-inputs">
      <input formControlName="password" matInput name="password" id="password" type="password" placeholder="Password">

      <mat-error *ngIf="pressLoginForm.controls.password.hasError('required')">
        {{'LOGIN_FORM.Password' | translate: locale.language}}
        <strong> {{'LOGIN_FORM.required' | translate: locale.language}} </strong>
      </mat-error>
    </mat-form-field>

    <div class="list-card__content">
      <button class="title submit-button" mat-raised-button [disabled]="!pressLoginForm.valid || isSubmittingForm" type="submit" >
        {{'LOGIN_FORM.LOGIN' | translate: locale.language}}
      </button>
    </div>
  </form>
  <!--end form-->
  <!--help text-->

  <h4 class="t-a-left contact-us">
    {{'LOGIN_FORM.If you would like access to our press materials please contact us at' | translate: locale.language}} <a href="mailto:{{pressEmail}}"  class="color-system-yellow">{{pressEmail}}</a>
  </h4>


</div>

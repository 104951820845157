import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { PressCountryModel } from '@app/shared/models/press/press-country.model';
declare const Datamap: any;

@Component({
  selector: 'gingr-press-map',
  templateUrl: './press-map.component.html',
  styleUrls: ['./press-map.component.scss'],
  standalone: true,
})
export class PressMapComponent implements OnInit, OnDestroy {
  private map: any;
  private countries: any;
  private mediaSubscription: Subscription;
  private screen: string;
  @Input() origins: PressCountryModel[];
  @Input() originsLoadedEmitter: any;
  @Output()
  private countrySelected: EventEmitter<any> = new EventEmitter<any>();

  private static getFillKey(number): string {
    if (number >= 100) {
      return 'c10';
    } else if (number === 0) {
      return 'cDefault';
    } else {
      number = ('' + number).split('');
      number.pop();
      return number.length ? 'c' + number[0] : 'c0';
    }
  }

  constructor(private observableMedia: MediaObserver) {
    this.countries = Datamap.prototype.worldTopo.objects.world.geometries;
  }

  ngOnInit() {
    // observe changes
    this.originsLoadedEmitter.on('loaded', () => {
      this.mediaSubscription = this.observableMedia
        .asObservable()
        .subscribe((change) => {
          this.screen = change[0].mqAlias;
          if (change[0].mqAlias !== 'xs' && this.map === undefined) {
            this.initMap();
            this.initColors();
          }
        });
    });
  }

  ngOnDestroy() {
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
  }
  private initMap() {
    this.map = new Datamap({
      element: document.getElementById('container'),
      projection: 'mercator',
      fills: {
        defaultFill: 'rgba(255, 228, 250, 0.3)',
        cDefault: '#d9d9d9',
        c0: '#d9cadf',
        c1: '#dfb7da',
        c2: '#e69cdc',
        c3: '#e37ed5',
        c4: '#e95ad5',
        c5: '#d226b8',
        c6: '#cc10b2',
        c7: '#a5078d',
        c8: '#871873',
        c9: '#7a0465',
        c10: '#5c004f',
      },
      data: {},
      geographyConfig: {
        borderColor: '#de90db',
        highlightBorderWidth: 2,
        highlightFillColor: (geo) => {
          return geo['fillColor'] || '#fa0fa0';
        },
        highlightBorderColor: '#b755ab',
        popupTemplate: (geo: any, data: any) => {
          return '';
        },
      },
      done: (datamap) => {
        // set country click handler
        datamap.svg.selectAll('.datamaps-subunit').on('click', (country) => {
          this.countrySelected.emit(country);
        });
      },
    });
  }

  private initColors() {
    if (this.screen === 'xs') {
      return;
    }
    const colors = {};
    this.origins.forEach((country) => {
      const fillKey = PressMapComponent.getFillKey(country.releasesCount);
      colors[country.countryCode3] = { fillKey: fillKey };
    });
    this.map.updateChoropleth(colors);
  }
}

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UserSettingsHelperService } from '@app/account/services/user-settings-helper.service';
import * as _ from 'lodash-es';
import { LocationService, NotificationService } from '@app/shared/services';
import { Subscription } from 'rxjs';
import { EventEmitter as Emitter } from 'events';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { select, Store } from '@ngrx/store';
import { SummaryLocation } from '@app/shared/models';
import { filter, take } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormFieldComponent } from '../../../../shared/components/form-field/form-field.component';
import { MatCardModule } from '@angular/material/card';
import { NgFor, LowerCasePipe } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { PressCountryModel } from '@app/shared/models/press/press-country.model';

@Component({
  selector: 'gingr-press-domains',
  templateUrl: './press-domains.component.html',
  styleUrls: ['./press-domains.component.scss'],
  standalone: true,
  imports: [
    ExtendedModule,
    FlexModule,
    MatGridListModule,
    NgFor,
    MatCardModule,
    FormFieldComponent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatOptionModule,
    MatIconModule,
    LowerCasePipe,
    L10nTranslatePipe,
  ],
})
export class PressDomainsComponent implements OnInit, OnDestroy {
  @Input() extraCountry: string;
  @Input() originsLoadedEmitter: Emitter;
  @Output() setOrigin: EventEmitter<PressCountryModel> =
    new EventEmitter<PressCountryModel>();
  @Input() origins: PressCountryModel[];
  private subscriptions: Subscription[] = [];
  public selectedCountry: string;
  public static scrollTo() {
    const selected: any = document.getElementsByClassName('countrySelected');
    const container: any = document.getElementById('domains');
    if (selected && selected[0] && container) {
      container.scrollTo({
        left: 0,
        top: selected[0].offsetTop,
        behavior: 'smooth',
      });
    }
  }
  constructor(
    private settingsHelper: UserSettingsHelperService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private locationService: LocationService,
    private notify: NotificationService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.originsLoadedEmitter.on('loaded', () => {
      this.locationService
        .getUserLocation()
        .pipe(
          filter((location: SummaryLocation) => !!location),
          take(1)
        )
        .subscribe((location: SummaryLocation) => {
          this.selectOrigin(location.country);
          this.selectedCountry = location.country;
        });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.originsLoadedEmitter.removeAllListeners();
  }

  emitOrigin(origin: PressCountryModel) {
    this.extraCountry = origin.country;
    this.selectedCountry = origin.countryCode2;
    setTimeout(PressDomainsComponent.scrollTo, 100);
    this.setOrigin.emit(origin);
  }

  selectOrigin(countryCode2: string) {
    const origin = _.find(this.origins, { countryCode2 });
    if (origin) {
      this.emitOrigin(origin);
    } else {
      // Switzerland by default
      this.emitOrigin(_.find(this.origins, { countryCode2: 'CH' }));
      this.notify.info(
        'Country unavailable',
        'Sorry, our service is not available in your country'
      );
    }
  }
}

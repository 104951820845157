import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from '@app/shared/models/user';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NotificationService, UserService } from '@app/shared/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalService } from '@app/shared/services/modal.service';
import { Errors, UserAvailableRoles } from '@app/shared/models';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ValidatorService } from '@app/shared/services/validator.service';
import { Observable } from 'rxjs';
import { _t } from '@app/shared/helpers';
import {
  L10N_CONFIG,
  L10N_LOCALE,
  L10nConfig,
  L10nLocale,
  L10nTranslationService,
  L10nTranslatePipe,
} from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgClass, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';

@Component({
  selector: 'gingr-dialog-press-login',
  templateUrl: './dialog-press-login.component.html',
  styleUrls: ['./dialog-press-login.component.scss'],
  standalone: true,
  imports: [
    ExtendedModule,
    MatIconModule,
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgClass,
    MatInputModule,
    NgIf,
    MatButtonModule,
    L10nTranslatePipe,
  ],
})
export class DialogPressLoginComponent implements OnInit, OnDestroy {
  public pressLoginForm: UntypedFormGroup;
  public isSubmittingForm: boolean = false;

  public pressEmail: string;

  public errors: Errors = new Errors();

  constructor(
    // private
    private store: Store<any>,
    private validator: ValidatorService,
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private localeService: L10nTranslationService,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private router: Router,
    private modalService: ModalService,
    private notify: NotificationService,
    private configService: ConfigService,
    private translate: L10nTranslationService,
    // public
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogPressLoginComponent>
  ) {}

  ngOnInit() {
    this.initForm();
    this.getPressEmailData();
  }

  ngOnDestroy() {
    this.resetPressLoginForm();
  }

  initForm() {
    this.pressLoginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
    });
  }

  private resetPressLoginForm() {
    this.pressLoginForm.reset();
  }
  /**
   * submit login for authenticate user
   */
  submitLogin() {
    const credentials = this.pressLoginForm.value;

    this.isSubmittingForm = true;
    this.errors = new Errors();

    this.userService.attemptAuthPress(credentials).subscribe(
      (data: any) => {
        const user: User = data['result'].user;
        const userBaseRole = UserService.getUserBaseRole(user);
        if (userBaseRole !== UserAvailableRoles.press.baseRoleName) {
          this.notify.error(
            this.translate.translate(_t('LOGIN_FORM.Sorry')),
            this.translate.translate(
              _t(
                'LOGIN_FORM.For view list of available materials to download, please login as press user'
              )
            ) + '.'
          );
        } else {
          this.userService.setAuth(data.result);
          this.dialogRef.close();
          this.dialog.closeAll();
          this.router.navigate(['info', 'press']);
        }

        this.isSubmittingForm = false;
      },
      (err) => {
        this.notify.error(
          'Error',
          err[0] ? err[0].message : 'Problem with authorization'
        );
        this.isSubmittingForm = false;
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * create press email from location if not exist create from env appUrl
   */
  private getPressEmailData() {
    if (location.hostname !== undefined) {
      this.pressEmail = 'press@' + location.hostname;
    } else {
      if (
        this.configService.config.appUrl !== undefined &&
        location.protocol !== undefined
      ) {
        this.pressEmail =
          'press@' +
          this.configService.config.appUrl.replace(
            location.protocol + '//',
            ''
          );
      } else {
        this.pressEmail = 'press@gingr.ch';
      }
    }
  }
}

<div
  fxLayout="row"
  class="account-save-next-container"
  [class.visible]="isFormChanged"
  *ngIf="visible"
>
  <div
    fxLayout="row"
    class="controls-left"
    fxLayoutAlign="start center"
    fxFlex="50"
  >
    <ng-container
      *ngIf="
        !isLive &&
        profileApproved !== approvingStates.PENDING &&
        profileApproved !== approvingStates.EDITED &&
        previousUrl
      "
    >
      <gingr-button
        fxHide.xs
        (update)="navigatePrevious()"
        [type]="'secondary'"
      >
        <mat-icon> keyboard_arrow_left</mat-icon>
        {{ 'ACCOUNT.MENU.previous' | translate: locale.language }}
      </gingr-button>
    </ng-container>
    <ng-container *ngIf="isManager; else profile">
      <gingr-button
        (click)="onCancel('/manage-gingrs/' + slug)"
        [type]="'secondary'"
      >
        {{ 'ACCOUNT.MENU.cancel' | translate: locale.language }}
      </gingr-button>
    </ng-container>
    <ng-template #profile>
      <gingr-button
        *ngIf="!isDefaultSettings; else defaultCancel"
        (click)="onCancel('/account/profile')"
        [type]="'secondary'"
      >
        {{ 'ACCOUNT.MENU.cancel' | translate: locale.language }}
      </gingr-button>
      <ng-template #defaultCancel>
        <gingr-button
          (click)="onCancel('/account/profile/default-gingr-settings')"
          [type]="'secondary'"
        >
          {{ 'ACCOUNT.MENU.cancel' | translate: locale.language }}
        </gingr-button>
      </ng-template>
    </ng-template>
  </div>

  <div
    fxLayout="row"
    class="controls-right"
    fxLayoutAlign="end center"
    fxFlex="50"
  >
    <ng-container
      *ngIf="
        (!isDefaultSettings &&
          !isLive &&
          profileApproved !== approvingStates.PENDING &&
          profileApproved !== approvingStates.EDITED) ||
          (isDefaultSettings && !defaultSettingsFilled);
        else justSave
      "
    >
      <ng-container *ngIf="!nextUrl">
        <ng-container *ngIf="isManager">
          <gingr-button
            (update)="lastManagedSave()"
            [btnDisabled]="formInvalid || data.disabled"
            [type]="'secondary'"
          >
            {{ 'ACCOUNT.MENU.Finish' | translate: locale.language }}
          </gingr-button>
        </ng-container>
        <ng-container *ngIf="isDefaultSettings">
          <gingr-button
            (update)="lastDefaultSave()"
            [btnDisabled]="formInvalid || data.disabled"
            [type]="'secondary'"
          >
            {{ 'ACCOUNT.MENU.Finish' | translate: locale.language }}
          </gingr-button>
        </ng-container>
        <ng-container *ngIf="!isDefaultSettings && !isManager">
          <gingr-button (update)="save()" [type]="'secondary'">
            {{ 'ACCOUNT.MENU.Finish' | translate: locale.language }}
          </gingr-button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="nextUrl">
        <gingr-button
          *ngIf="!justNext"
          (update)="saveAndNext()"
          [btnDisabled]="formInvalid || data.disabled"
          [type]="'secondary'"
        >
          {{ 'ACCOUNT.MENU.save&next' | translate: locale.language }}
          <mat-icon> keyboard_arrow_right</mat-icon>
        </gingr-button>
        <gingr-button
          *ngIf="justNext"
          (update)="navigateNext()"
          [btnDisabled]="formInvalid || data.disabled"
          [type]="'secondary'"
        >
          {{ 'ACCOUNT.MENU.next' | translate: locale.language }}
          <mat-icon> keyboard_arrow_right</mat-icon>
        </gingr-button>
      </ng-container>
    </ng-container>
    <ng-template #justSave>
      <gingr-button
        (update)="save()"
        [btnDisabled]="formInvalid || data.disabled || !isFormChanged"
        [type]="'secondary'"
      >
        {{ 'ACCOUNT.MENU.Save' | translate: locale.language }}
      </gingr-button>
    </ng-template>
  </div>
</div>

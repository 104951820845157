import { Component, inject, OnInit } from '@angular/core';
import { UserService } from '@app/shared/services';
import { FlexModule } from '@angular/flex-layout/flex';
import { InfoPressComponent } from '../../components/info-press/info-press.component';
import { InfoBackBtnComponent } from '../../components/info-back-btn/info-back-btn.component';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { FeatureFlags } from '@app/shared/models/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'gingr-info-press-page',
  templateUrl: './info-press-page.component.html',
  styleUrls: ['./info-press-page.component.scss'],
  standalone: true,
  imports: [InfoBackBtnComponent, InfoPressComponent, FlexModule],
})
export class InfoPressPageComponent implements OnInit {
  public static pageUrl = 'press';
  showSection = false;
  private readonly router = inject(Router);
  constructor(public userService: UserService) {}

  async ngOnInit() {
    await StaticUtilsService.LdClient.waitUntilReady().then();
    this.showSection = StaticUtilsService.LdClient.variation(
      FeatureFlags.PressReleases,
      false
    );
    if (!this.showSection) {
      this.router.navigate(['/info']);
    }
  }
}

import { Routes } from '@angular/router';
import {
  ConfirmationConnectionComponent,
  ConfirmationTransferComponent,
  ConfirmEstablishmentConnectionComponent,
} from '@app/shared/components/confirmation-connection/confirmation-connection.component';
import {
  ConfirmationEmailComponent,
  ActivationEmailPageComponent,
} from '@app/shared/components/confirmation-email/confirmation-email.component';

import { LandingPageComponent } from './landing-pages/containers/landing-page/landing-page.component';
import { LocationGuard } from '@app/shared/guards/location.guard';
import { LoginComponent } from '@app/pages/login/login.component';
import { AuthGuard, NoAuthGuard } from '@app/shared/guards';
import {
  ForgotPasswordComponent,
  ResetPasswordComponent,
} from '@app/pages/login/components/reset-password/reset-password.component';
import { AcceptReferralComponent } from '@app/account/containers/referral/accept-referral/accept-referral.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'gingrs',
    pathMatch: 'full',
  },
  {
    path: 'book',
    loadChildren: () =>
      import('./book/book.routing').then((m) => m.bookRouting),
  },
  {
    path: 'gingrs',
    loadChildren: () =>
      import('./profiles/profiles.routing').then((m) => m.profilesRoutes),
  },
  // {
  //   path: 'establishments',
  //   loadChildren: () =>
  //     import('./profiles/profiles.routing').then((m) => m.profilesRoutes),
  // },
  // {
  //   path: 'agencies',
  //   loadChildren: () =>
  //     import('./profiles/profiles.routing').then((m) => m.profilesRoutes),
  // },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./clients/clients.routing').then((m) => m.clientsRoutes),
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.routing').then((m) => m.infoRoutes),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.routing').then((m) => m.accountRoutes),
  },
  {
    path: 'instafeature',
    canActivate: [LocationGuard],
    loadChildren: () =>
      import('./instafeature/instafeature.routing').then((m) => m.instaRoutes),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./homepage/homepage.routing').then((m) => m.homepageRoutes),
  },
  {
    path: 'credit',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./credit/credit.routing').then((m) => m.creditRouting),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'confirmationEmail',
    component: ConfirmationEmailComponent,
  },
  {
    path: 'activationEmail',
    component: ActivationEmailPageComponent,
  },
  {
    path: 'confirmTransfer',
    component: ConfirmationTransferComponent,
  },
  {
    path: 'confirmIndependent',
    component: ConfirmationConnectionComponent,
  },
  {
    path: 'confirmConnection',
    component: ConfirmEstablishmentConnectionComponent,
  },
  {
    path: 'manage-gingrs',
    loadChildren: () =>
      import('./manage-gingrs/manage-gingrs.routing').then(
        (m) => m.manageGingrsRoutes
      ),
  },
  {
    path: 'workplace',
    loadChildren: () =>
      import('./workplace/workplace.routing').then((m) => m.workplaceRoutes),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./welcome/welcome.routing').then((m) => m.welcomeRoutes),
  },
  {
    path: 'referral/:code',
    component: AcceptReferralComponent,
  },
  {
    path: 'content/:landingdata',
    component: LandingPageComponent,
  },
  { path: '**', redirectTo: '/gingrs' },
];

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PressDomainsComponent } from './press-domains/press-domains.component';
import { PressService, UserService } from '@app/shared/services';
import { UserAvailableRoles } from '@app/shared/models';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { EventEmitter as Emitter } from 'events';
import { DeviceChecker } from '@app/shared/helpers/device-checker';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { select, Store } from '@ngrx/store';
import { getUser } from '@app/shared/reducers/user.selectors';
import { filter } from 'rxjs/operators';
import { PressReleasesComponent } from './press-releases/press-releases.component';
import { PressDownloadsComponent } from './press-downloads/press-downloads.component';
import { PressMapComponent } from './press-map/press-map.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { MatListModule } from '@angular/material/list';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { FeatureWillBeEnabledComponent } from '../../../shared/components/feature-will-be-enabled/feature-will-be-enabled.component';
import { NgIf, NgFor } from '@angular/common';
import { PressCountryModel } from '@app/shared/models/press/press-country.model';
import { PressReleaseModel } from '@app/shared/models/press/press-release.model';

@Component({
  selector: 'gingr-info-press',
  templateUrl: './info-press.component.html',
  styleUrls: ['./info-press.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FeatureWillBeEnabledComponent,
    FlexModule,
    ExtendedModule,
    MatListModule,
    NgFor,
    SvgIconComponent,
    PressMapComponent,
    PressDomainsComponent,
    PressDownloadsComponent,
    PressReleasesComponent,
    L10nTranslatePipe,
  ],
})
export class InfoPressComponent implements OnInit, OnDestroy {
  public selectedCountry: string;
  public pressReleases: PressReleaseModel[] = [];
  public origins: PressCountryModel[] = [];
  public pressDownloads: Array<any>;
  public isPressUser: boolean = false;
  // show materials for press users
  public showDownloadMaterial: boolean = false;
  // hide map for press users
  public showMap: boolean = true;
  // top bar press logos
  public pressLogos = [
    [
      '/assets/images/press-release/keystone.min.svg',
      '/assets/images/press-release/luzerner_zeitung.min.svg',
      '/assets/images/press-release/radio1.min.svg',
      '/assets/images/press-release/tele_zurich.min.svg',
      '/assets/images/press-release/watson_logo.min.svg',
      '/assets/images/press-release/kabel1.svg',
    ],
    [
      '/assets/images/press-release/tagesanzeiger.svg',
      '/assets/images/press-release/annabelle.svg',
      '/assets/images/press-release/articleLogo.svg',
      '/assets/images/press-release/derbund.svg',
      '/assets/images/press-release/zhurgauerZeitung.svg',
    ],
  ];

  public isPreICO: boolean = this.configService.config.isPreICO === 'true';

  private refreshReleasesView: any;
  private subsctiptions: Subscription[] = [];
  public originsLoadedEmitter = new Emitter();

  public static scrollToReleases() {
    const releases: any = document.getElementsByClassName('releases');
    const container: any = document.getElementsByClassName(
      'mat-drawer-content mat-sidenav-content ng-star-inserted'
    );
    if (releases && releases[0] && container && container[0]) {
      setTimeout(() => {
        container[0].scrollTo({
          left: 0,
          top: releases[0].offsetTop,
          behavior: 'smooth',
        });
      }, 800);
    }
  }

  constructor(
    private pressService: PressService,
    private userService: UserService,
    private configService: ConfigService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private _sanitizer: DomSanitizer,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.pressService.getDomains().subscribe((domains) => {
      this.origins = domains;
      setTimeout(() => {
        this.originsLoadedEmitter.emit('loaded');
      }, 0);
      setTimeout(PressDomainsComponent.scrollTo, 100);
    });
    this.checkUser();
  }

  ngOnDestroy() {
    this.subsctiptions.forEach((subsciption: Subscription) => {
      if (subsciption instanceof Subscription) {
        subsciption.unsubscribe();
      }
    });
  }

  refreshReleases(countryId: number) {
    this.pressService.getReleasesByCountry(countryId).subscribe((releases) => {
      this.pressReleases = releases;
      if (this.refreshReleasesView) {
        this.refreshReleasesView();
      }
    });
  }

  takeChanges(changes) {
    this.refreshReleasesView = changes;
  }

  setOrigin(origin) {
    this.refreshReleases(origin.id);
    if (this.showDownloadMaterial) {
      this.refreshDownlodMaterials(origin.id);
    }
    if (!DeviceChecker.isMobile()) {
      InfoPressComponent.scrollToReleases();
    }
  }

  refreshDownlodMaterials(domainId: number) {
    this.pressService.getPressResources(domainId).subscribe((resources) => {
      this.pressDownloads = resources.result;
      this.pressDownloads.forEach((resource) => {
        if (!resource.image && resource.fileSrc) {
          if (
            resource.fileSrc.indexOf('.jpg') !== -1 ||
            resource.fileSrc.indexOf('.png') !== -1
          ) {
            resource.image = this._sanitizer.bypassSecurityTrustStyle(
              `url(${this.configService.config.mediaCdnUrl + resource.fileSrc})`
            );
          } else if (resource.fileSrc.indexOf('.pdf') !== -1) {
            resource.pdf = true;
          } else if (!resource.video) {
            resource.unknownFormat = true;
          }
        } else if (resource.image) {
          resource.image = this._sanitizer.bypassSecurityTrustStyle(
            `url(${this.configService.config.mediaCdnUrl + resource.image})`
          );
        }
      });
    });
  }

  takeByThree(idThree: string) {
    if (this.origins) {
      this.origins.forEach((origin) => {
        if (origin.countryCode3 === idThree) {
          this.refreshReleases(origin.id);
          if (this.showDownloadMaterial) {
            this.refreshDownlodMaterials(origin.id);
          }
        }
      });
    }
  }

  countrySelected(country) {
    this.takeByThree(country.id);
    this.selectedCountry = country.properties.name;
    setTimeout(PressDomainsComponent.scrollTo, 150);
    InfoPressComponent.scrollToReleases();
  }

  checkUser() {
    this.subsctiptions.push(
      this.store
        .pipe(
          select(getUser),
          filter((user) => !!user)
        )
        .subscribe((user) => {
          this.isPressUser = UserService.checkUserRole(
            UserAvailableRoles.press.baseRoleName,
            user
          );
          if (this.isPressUser) {
            this.showMap = false;
            this.showDownloadMaterial = true;
          } else {
            this.showMap = true;
            this.showDownloadMaterial = false;
          }
        })
    );
  }
}

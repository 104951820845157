<div class="forgot-pass-container pt-[30px] pb-5 md:pr-[30px]">
  <img
    src="./assets/icons/cross.svg"
    alt="Close Icon"
    width="15"
    class="close absolute right-[20px] top-[20px] cursor-pointer block md:hidden"
    (click)="closeDialog()"
  />

  <div
    class="close absolute right-[-10px] top-[20px] cursor-pointer hidden lg:block"
  >
    <img
      src="./assets/icons/close-new.svg"
      alt="Close Icon new"
      width="24"
      (click)="closeDialog()"
    />
  </div>

  <h4 class="text-primary text-[24px] font-[600] mb-[40px] capitalize">
    {{ 'FORGOT_PASSWORD_DIALOG.Forgot password' | translate: locale.language }}
  </h4>
  <p class="text-white mb-[60px]">
    {{
      'FORGOT_PASSWORD_DIALOG.Please enter your email address and we will send you an email with instructions to reset your password'
        | translate: locale.language
    }}.
  </p>
  <form
    [formGroup]="forgotPasswordForm"
    name="forgotPasswordForm"
    id="forgot-password-form"
    #f="ngForm"
    (ngSubmit)="submitForm()"
    fxLayout="column"
  >
    <mat-form-field class="form-input">
      <mat-label>Email</mat-label>
      <input
        formControlName="email"
        matInput
        [(ngModel)]="forgotPasswordModel.email"
      />
      <mat-error
        *ngIf="forgotPasswordForm.controls['email'].hasError('required')"
      >
        {{
          'FORGOT_PASSWORD_DIALOG.Email is required'
            | translate: locale.language
        }}
      </mat-error>
      <mat-error
        *ngIf="
          forgotPasswordForm.controls['email'].hasError('pattern') ||
          forgotPasswordForm.controls['email'].hasError('server')
        "
      >
        {{
          'FORGOT_PASSWORD_DIALOG.Please enter a valid email address'
            | translate: locale.language
        }}
      </mat-error>
    </mat-form-field>

    <button
      type="submit"
      class="btn btn-primary mt-[40px] bg-primary mb-5 md:mb-0 text-[18px] w-full shadow"
    >
      {{ 'FORGOT_PASSWORD_DIALOG.Submit' | translate: locale.language }}
    </button>
  </form>
</div>

<!--<h1 mat-dialog-title>Login</h1>-->
<div class="login_container pb-[20px] pr-[30px]">
  <img
    src="./assets/icons/cross.svg"
    alt="Close Icon"
    width="15"
    class="close absolute right-[20px] top-[20px] cursor-pointer block md:hidden"
    (click)="closeDialog()"
  />

  <div
    class="close absolute right-[-10px] top-[20px] cursor-pointer hidden lg:block"
  >
    <img
      src="./assets/icons/close-new.svg"
      alt="Close Icon new"
      width="24"
      (click)="closeDialog()"
    />
  </div>

  <div class="signin md:pt-[20px] pt-[30px]">
    <!-- form -->
    <h4 class="t-a-left mb-[30px]">
      <span class="color-system-yellow title capitalize font-semibold">{{
        (showChangePassword
          ? 'CONNECTION.Set password to activate your account'
          : 'LOGIN_FORM.LOGIN'
        ) | translate: locale.language
      }}</span>
    </h4>

    <form
      *ngIf="showChangePassword"
      [formGroup]="passwordConfirmForm"
      (ngSubmit)="setPassword()"
      fxLayout="column"
      name="setPasswordForm"
      id="set-password-form"
      #f="ngForm"
    >
      <mat-form-field class="form-input">
        <input
          formControlName="password"
          matInput
          placeholder="{{ 'CONNECTION.Password' | translate: locale.language }}"
          type="password"
        />
        <div>
          <mat-error
            *ngIf="
              passwordConfirmForm.controls['password'].hasError('required')
            "
          >
            {{ 'RESET_PASSWORD_DIALOG.required' | translate: locale.language }}
          </mat-error>
          <mat-error
            *ngIf="passwordConfirmForm.controls['password'].hasError('pattern')"
          >
            {{ 'RESET_PASSWORD_DIALOG.pattern' | translate: locale.language }}
          </mat-error>
          <mat-error
            *ngIf="passwordConfirmForm.controls['password'].hasError('server')"
          >
            {{ passwordConfirmForm.controls['password'].getError('server') }}
          </mat-error>
          <mat-error
            *ngIf="passwordConfirmForm.controls['password'].hasError('noMatch')"
          >
            {{ 'RESET_PASSWORD_DIALOG.noMatch' | translate: locale.language }}
          </mat-error>
        </div>
      </mat-form-field>

      <mat-form-field class="form-input">
        <input
          formControlName="password_confirmation"
          matInput
          type="password"
          placeholder="{{
            'CONNECTION.Confirm Password' | translate: locale.language
          }}"
        />
        <div>
          <mat-error
            *ngIf="
              passwordConfirmForm.controls['password_confirmation'].hasError(
                'required'
              )
            "
          >
            {{ 'RESET_PASSWORD_DIALOG.required' | translate: locale.language }}
          </mat-error>
          <mat-error
            *ngIf="
              passwordConfirmForm.controls['password_confirmation'].hasError(
                'noMatch'
              )
            "
          >
            {{ 'RESET_PASSWORD_DIALOG.noMatch' | translate: locale.language }}
          </mat-error>
        </div>
      </mat-form-field>

      <button
        mat-raised-button
        [disabled]="!passwordConfirmForm.valid || isSubmitting"
        type="submit"
        color="accent"
      >
        {{ 'CONNECTION.Set password' | translate: locale.language }}
      </button>
    </form>

    <form
      *ngIf="!showChangePassword"
      [formGroup]="loginForm"
      class="login-form"
      name="loginForm"
      id="login-form"
      #f="ngForm"
      (ngSubmit)="submitLogin()"
      fxLayout="column"
    >
      <mat-form-field
        class="form-input"
        ngClass="login-username login-inputs  "
        *ngIf="loginReadOnly && isXs"
      >
        <mat-label>{{
          'LOGIN_FORM.Email' | translate: locale.language
        }}</mat-label>
        <input
          [readonly]
          (click)="this.loginReadOnly = false"
          type="email"
          formControlName="email"
          id="email-read-only"
          matInput
          [(ngModel)]="loginUser.email"
        />
        <mat-error *ngIf="emailFormControl.hasError('required')">
          {{ 'LOGIN_FORM.email is' | translate: locale.language }}
          <strong>{{
            'LOGIN_FORM.required' | translate: locale.language
          }}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="form-input"
        ngClass="login-username login-inputs"
        *ngIf="!(loginReadOnly && isXs)"
      >
        <mat-label>{{
          'LOGIN_FORM.Email' | translate: locale.language
        }}</mat-label>
        <input
          type="email"
          formControlName="email"
          id="email2"
          matInput
          [(ngModel)]="loginUser.email"
        />
        <mat-error *ngIf="emailFormControl.hasError('required')">
          {{ 'LOGIN_FORM.email is' | translate: locale.language }}
          <strong>{{
            'LOGIN_FORM.required' | translate: locale.language
          }}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="form-input mt-5"
        ngClass="login-password login-inputs"
      >
        <mat-label>{{
          'LOGIN_FORM.Password' | translate: locale.language
        }}</mat-label>
        <input
          formControlName="password"
          matInput
          name="password"
          id="password"
          [(ngModel)]="loginUser.password"
          [type]="showPassword ? 'text' : 'password'"
        />
        <mat-icon
          class="cursor-pointer show-pass text-primary"
          (click)="showPassword = !showPassword"
          >remove_red_eye</mat-icon
        >
        <mat-error *ngIf="passwordFormControl.hasError('pattern')">
          {{
            'LOGIN_FORM.Passwords should be like a pattern'
              | translate: locale.language
          }}
        </mat-error>
        <mat-error *ngIf="passwordFormControl.hasError('required')">
          {{ 'LOGIN_FORM.Password' | translate: locale.language }}
          <strong>
            {{ 'LOGIN_FORM.required' | translate: locale.language }}
          </strong>
        </mat-error>
      </mat-form-field>

      <button
        class="btn btn-primary mt-5 bg-primary mb-5 text-[18px] shadow"
        [disabled]="!loginForm.valid || isSubmitting"
        type="submit"
      >
        {{ 'LOGIN_FORM.LOGIN' | translate: locale.language }}
      </button>
    </form>

    <div class="text-primary" *ngIf="!showChangePassword">
      <a href="/forgot-password" (click)="forgotPass()" class="text-primary">{{
        'LOGIN_FORM.FORGOT_PASSWORD' | translate: locale.language
      }}</a>
    </div>

    <div
      class="email-not-confirmed"
      *ngIf="showEmailNotConfirmed && loginForm.value.email"
    >
      {{
        'ACCOUNT.Your account has not been activated'
          | translate: locale.language
      }}
      <a (click)="resendEmailConfirm()" class="confirmEmailLine__text_link">{{
        'ACCOUNT.Re-send' | translate: locale.language
      }}</a>
    </div>
  </div>
  <div class="separate_container my-[30px]" *ngIf="!showChangePassword">
    <span class="line_helper"></span>
    <span class="text_helper lowercase">{{
      'LOGIN_FORM.OR' | translate: locale.language
    }}</span>
    <span class="line_helper"></span>
  </div>
  <div class="register" *ngIf="!showChangePassword">
    <div class="flex justify-between">
      <p class="text-primary text-2xl font-semibold leading-[1.2]">
        {{ 'LOGIN_FORM.Sign up to use our' | translate: locale.language }}
        <br />
        {{ 'LOGIN_FORM.booking features' | translate: locale.language }}
      </p>

      <div class="anonymus flex justify-center flex-col">
        <div class="icon flex justify-center pt-2">
          <img
            src="../../../assets/images/icons/registration/anonymus.svg"
            class="Anonymus"
            width="39"
            height="16"
          />
        </div>
        <div class="text capitalize text-white">
          <span>{{ 'LOGIN_FORM.anonymous' | translate: locale.language }}</span>
        </div>
      </div>
    </div>
    <div class="register_block mb-0">
      <div
        class="btn btn-primary mt-[30px] bg-primary mb-5 md:mb-0 text-[18px] w-full shadow"
        (click)="goToRegistration('registration')"
      >
        <div class="title lowercase first-letter:capitalize">
          {{ 'LOGIN_FORM.SIGN UP' | translate: locale.language }}
        </div>
      </div>
    </div>
  </div>
</div>

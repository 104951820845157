import { Router } from '@angular/router';
import { Component, OnInit, Inject, inject } from '@angular/core';
import { UserService } from '@app/shared/services';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { ConfigService } from '@app/shared/services/config.service';

@Component({
  selector: 'gingr-last-seen',
  templateUrl: './last-seen.component.html',
  styleUrls: ['./last-seen.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    ExtendedModule,
    MatIconModule,
    NgIf,
    MatListModule,
    NgFor,
    L10nTranslatePipe,
  ],
})
export class LastSeenComponent implements OnInit {
  lastSeen = [];
  shown: boolean = false;
  shownLs: boolean = true;

  readonly configService = inject(ConfigService);

  constructor(
    private userService: UserService,
    private router: Router,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.lastSeen = this.userService.getLocalLastSeen();
  }

  toggleLastSeen() {
    this.shown = !this.shown;
    this.shownLs = true;
  }

  goToProfile(id: number) {
    this.router.navigateByUrl('/gingrs/profile/' + id);
  }
}

import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { UserService } from '@app/shared/services';
import * as _ from 'lodash-es';
import { MediaObserver } from '@angular/flex-layout';
import { GoLiveOrOptionalModalComponent } from '@app/account/components';
import { ManageGingrsService } from '@app/manage-gingrs/services';
import { ProfileApprovingStates } from '@app/shared/models/enum/profileApprovingStates';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { select, Store } from '@ngrx/store';
import {
  getManagedUser,
  getUser,
  getUserRole,
} from '@app/shared/reducers/user.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgIf } from '@angular/common';

declare global {
  interface Window {
    golive_shown: boolean;
  }
}

@Component({
    selector: 'gingr-account-save-next-bar',
    templateUrl: './account-save-next-bar.component.html',
    styleUrls: ['./account-save-next-bar.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FlexModule,
        ButtonComponent,
        ExtendedModule,
        MatIconModule,
        L10nTranslatePipe,
    ],
})
export class AccountSaveNextBarComponent implements OnInit, OnDestroy {
  private menu: any[];
  private subscriptions: Subscription[] = [];
  public nextUrl: string;
  public previousUrl: string;
  public slug: string;
  public isFormChanged: boolean = false;
  public formInvalid: boolean = true;
  public showBottomMenu: boolean = true;
  public isLive: boolean = false;
  public isSidenavAccountOpened: boolean = false;
  public isManager: boolean = false;
  public isDefaultSettings: boolean = false;
  public defaultSettingsFilled = false;
  public isCustomSetting: boolean;
  public mandatoryFilled: boolean = false;
  public justNext: boolean = false;
  public profileApproved: number;
  public approvingStates: any = ProfileApprovingStates;
  private user: any = {
    isDefaultAvailability: false,
    isDefaultPricing: false,
    isDefaultServices: false,
  };
  private goLiveDialog: MatDialogRef<GoLiveOrOptionalModalComponent>;
  public visible: boolean = true;
  private set_menu = 0;
  public golive_dialog_count = 0;

  private destroy$ = new Subject<boolean>();

  private static hasProperty(object: any): boolean {
    for (const prop in object) {
      if (object.hasOwnProperty(prop)) {
        return true;
      }
    }
    return false;
  }

  private static addMarginToContent() {
    const content: any = document.getElementsByClassName('gg-page')[0];
    if (content && content.style) {
      content.style.marginBottom = '64px';
    }
  }

  private static removeMargin() {
    const content: any = document.getElementsByClassName('gg-page')[0];
    if (content && content.style) {
      content.style.marginBottom = '0';
    }
  }

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private router: Router,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private changes: ChangeDetectorRef,
    private userService: UserService,
    private store: Store<any>,
    private observableMedia: MediaObserver,
    private dialog: MatDialog,
    private manageGingrsService: ManageGingrsService
  ) {}

  ngOnInit() {
    this.isLive = this.data.isLive;
    this.isManager = this.data.manager;
    this.slug = this.data.slug;
    this.isDefaultSettings = this.data.defaultSettings;
    this.defaultSettingsFilled = this.data.defaultSettingsFilled;
    this.profileApproved = this.data.profileApproved;
    this.isCustomSetting = this.data.isCustomSetting;

    if (this.data.mandatoryStatusObservable) {
      this.subscriptions.push(
        this.data.mandatoryStatusObservable.subscribe((isMandatoryFilled) => {
          this.mandatoryFilled = isMandatoryFilled;
        })
      );
    }

    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          const links = [
            '/welcome',
            '/account/dashboard/subscription',
            '/account/dashboard/contact-preferences',
            '/account/dashboard/verification',
            '/account/profile/account-details',
            '/account/profile/languages',
            '/account/profile/location',
            '/account/profile/media',
            '/account/profile/pricing',
            '/account/profile/services',
            '/account/profile/availability',
            '/account/profile/personal-details',
            '/account/profile/verification',
            '/info/pricing/Gingr',
            '/info/pricing/Client',
            '/info/pricing/Establishment',
            '/info/pricing/Agency',
            '/info/pricing',
          ];
          this.showBottomMenu = !links.includes(event.url);
        }
      })
    );

    if (this.isManager && this.slug) {
      // if manage gingr menu
      const mandatory = this.data.service.getGingrMenu('mandatory');
      const optional = this.data.service.getGingrMenu('optional');

      optional[0].show_go_live = true;
      optional[0].shown = false;

      this.menu = [...mandatory, ...optional];
      this.store
        .pipe(
          select(getManagedUser),
          filter((user) => !!user),
          takeUntil(this.destroy$)
        )
        .subscribe((user) => {
          this.user = user;
          this.lookAround(mandatory);
        });
    } else if (this.isDefaultSettings) {
      // if default gingr settings menu
      this.menu = this.data.service.getDefaultGingrSettingsMenu();
      this.lookAround(this.menu);
    } else {
      this.store
        .pipe(
          select(getUser),
          filter((user) => !!user),
          takeUntil(this.destroy$)
        )
        .subscribe((user) => {
          this.slug = user.slug;
          this.user = _.cloneDeep(user);
        });

      this.store
        .pipe(
          select(getUserRole),
          filter((role) => !!role),
          takeUntil(this.destroy$)
        )
        .subscribe((role) => {
          let mandatory = [];

          if (role.isEstablishment) {
            // Establishment
            mandatory = this.data.service.getEstablishmentMenu('mandatory');
            const optional = this.data.service.getEstablishmentMenu('optional');

            optional[0].show_go_live = true;
            optional[0].shown = false;

            this.menu = [...mandatory, ...optional];
            // pop to delete default gingr settings tab
            this.menu.pop();
          } else if (role.isAgency) {
            // Establishment
            mandatory = this.data.service.getAgencyMenu('mandatory');
            const optional = this.data.service.getAgencyMenu('optional');

            optional[0].show_go_live = true;
            optional[0].shown = false;

            this.menu = [...mandatory, ...optional];
            // pop to delete default gingr settings tab
            this.menu.pop();
          } else {
            mandatory = this.data.service.getGingrMenu('mandatory');
            const optional = this.data.service.getGingrMenu('optional');

            optional[0].show_go_live = true;
            optional[0].shown = false;

            this.menu = [...mandatory, ...optional];
          }

          this.lookAround(mandatory);
        });
    }

    this.subscriptions.push(
      this.data.service.sidenavAccountState.subscribe((state) => {
        this.isSidenavAccountOpened = state;
        const containers = document.getElementsByClassName(
          'account-save-control'
        );
        const container: Element = containers[containers.length - 1];
        container.classList.remove('sidenav-opened');
        container.parentElement.classList.remove('sidenav-opened');
        if (this.isSidenavAccountOpened) {
          container.classList.add('sidenav-opened');
          container.parentElement.classList.add('sidenav-opened');
        }
      })
    );
    this.subscriptions.push(
      this.observableMedia.asObservable().subscribe((change) => {
        if (change[0].mqAlias !== 'xs') {
          AccountSaveNextBarComponent.removeMargin();
        } else if (this.observableMedia.isActive('xs')) {
          AccountSaveNextBarComponent.addMarginToContent();
        }
      })
    );
    if (this.data.formGroup) {
      this.formInvalid = this.data.formGroup.invalid;
      // emit changes when passed fake FormControl,
      // from component that don't have a forms
      // (profile-location, profile-media-management)
      this.isFormChanged = !AccountSaveNextBarComponent.hasProperty(
        this.data.formGroup.controls
      );
      this.data.formGroup.valueChanges.subscribe((data) => {
        this.isFormChanged = !_.isEqual(this.data.formGroup, data);
        this.formInvalid = this.data.formGroup.invalid;
        if (!this.changes['destroyed']) {
          this.changes.detectChanges();
        }
      });
    } else {
      this.formInvalid = false;
      this.isFormChanged = true;
    }
    this.addClassToOverlay();
  }

  addClassToOverlay() {
    setTimeout(() => {
      const elements = document.getElementsByClassName('account-save-control');
      if (this.showBottomMenu) {
        elements[elements.length - 1].parentElement.classList.add(
          'account-save-control-pane--marginZero'
        );
      } else {
        elements[elements.length - 1].parentElement.classList.add(
          'account-save-control-pane'
        );
      }
    }, 300);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    if (this.data && this.data.service && this.data.service.userDataEvents) {
      this.data.service.userDataEvents.removeAllListeners();
    }
    AccountSaveNextBarComponent.removeMargin();
  }

  public onCancel(url: string) {
    this.userService.wizardRun();
    this.router.navigateByUrl(url);
  }

  public saveAndNext() {
    this.data.service.userDataEvents.on('save', () => {
      this.navigateNext();
    });
    this.data.service.emitAction('save');
  }

  public save() {
    this.data.service.userDataEvents.on('save', () => {
      if (this.isManager) {
        this.router.navigateByUrl('/manage-gingrs/' + this.slug);
      } else {
        this.userService.wizardRun();
        this.router.navigateByUrl(
          this.isDefaultSettings
            ? '/account/profile/default-gingr-settings'
            : '/account/profile'
        );
      }
    });
    this.data.service.emitAction('save');
    this.visible = false;
  }

  public lastManagedSave() {
    this.data.service.userDataEvents.on('save', () => {
      this.router.navigateByUrl('/manage-gingrs/' + this.slug);
    });
    this.data.service.emitAction('save');
  }

  public lastDefaultSave() {
    this.data.service.userDataEvents.on('save', () => {
      if (history.state.addGingr && this.slug) {
        this.router.navigate(['manage-gingrs'], {
          queryParams: {
            newGingr: 'step3',
            gingrType: this.manageGingrsService.addGingrType || null,
          },
        });
      } else {
        this.router.navigate(['account', 'profile']);
      }
    });
    this.data.service.emitAction('save');
  }

  private lookAround(mandatoryMenu: any[]) {
    let prev = 0,
      next = 0;

    //   if (this.set_menu > 0) return;
    this.set_menu++;
    if (this.menu && this.menu.length !== 0) {
      for (let i = 0; i < this.menu.length; i++) {
        let route = this.menu[i].route;
        if (route === 'account-details' && this.router.url.includes(route)) {
          window.golive_shown = false;
        }
        if (route === 'availability' && this.user['roleId'] === 5) {
          route = 'opening-hours';
        }
        if (route && this.router.url.includes(route)) {
          let mandatoryFilled = true;
          mandatoryMenu.forEach((item) => {
            if (
              typeof item['fill_percent'] === 'number' &&
              +item['fill_percent'] < 100
            ) {
              mandatoryFilled = false;
            } else {
              item['fill_percent']
                .subscribe((percent) => {
                  if (+percent < 100) {
                    mandatoryFilled = false;
                  }
                })
                .unsubscribe();
            }
          });

          if (i !== this.menu.length - 1) {
            if (this.isManager) {
              if (
                this.menu[i].canBeDefault &&
                this.user[this.menu[i].canBeDefault] &&
                this.isCustomSetting === false
              ) {
                this.justNext = true;
                this.nextUrl =
                  '/manage-gingrs/' + this.slug + '/' + this.menu[i + 1].route;

                prev = i;
                next = i + 1;
              } else {
                this.justNext = false;
                for (let j = i + 1; j < this.menu.length; j++) {
                  if (
                    !this.user[this.menu[j].canBeDefault] &&
                    this.menu[j].route !== 'location'
                  ) {
                    this.nextUrl =
                      '/manage-gingrs/' + this.slug + '/' + this.menu[j].route;

                    prev = i;
                    next = j;

                    break;
                  }
                }
              }
              this.detectChanges();
            } else if (this.isDefaultSettings) {
              this.nextUrl =
                '/account/profile/default-gingr-settings/' +
                this.menu[i + 1].route;
            } else {
              this.nextUrl = '/account/profile/' + this.menu[i + 1].route;
            }
          }

          if (
            mandatoryFilled &&
            this.user.profile_approved === ProfileApprovingStates.NEWBIE &&
            !window.golive_shown &&
            !this.isLive &&
            !this.goLiveDialog &&
            (this.menu[i].route === 'availability' ||
              this.menu[i].route === 'extra-details' ||
              this.menu[i].route === 'personal-details')
          ) {
            if (i > 0) {
              window.golive_shown = true;

              this.goLiveDialog = this.dialog.open(
                GoLiveOrOptionalModalComponent,
                {
                  panelClass: 'gingr-go-live-or-optional-container--popup',
                  data: {
                    managed: this.isManager,
                    user: this.user.hasOwnProperty('slug')
                      ? this.user
                      : undefined,
                  },
                }
              );
            }
          }

          if (i !== 0) {
            if (this.isManager) {
              if (this.user[this.menu[i].canBeDefault]) {
                this.previousUrl =
                  '/manage-gingrs/' + this.slug + '/' + this.menu[i - 1].route;
              } else {
                if (i === 1) {
                  this.previousUrl =
                    '/manage-gingrs/' + this.slug + '/' + this.menu[0].route;
                } else {
                  for (let j = i - 1; j > 0; j--) {
                    if (
                      !this.user[this.menu[j].canBeDefault] &&
                      this.menu[j].route !== 'location'
                    ) {
                      this.previousUrl =
                        '/manage-gingrs/' +
                        this.slug +
                        '/' +
                        this.menu[j].route;
                      j = 0;
                    }
                  }
                }
              }
            } else if (this.isDefaultSettings) {
              this.previousUrl =
                '/account/profile/default-gingr-settings/' +
                this.menu[i - 1].route;
            } else {
              this.previousUrl = '/account/profile/' + this.menu[i - 1].route;
            }
          }
        }
      }
    }
  }

  private detectChanges() {
    if (!this.changes['destroyed']) {
      this.changes.detectChanges();
    }
  }

  public navigateNext() {
    if (this.nextUrl) {
      this.router.navigateByUrl(this.nextUrl, {
        state: { addGingr: history.state.addGingr },
      });
    }
  }

  public navigatePrevious() {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  NotificationService,
  UserService,
  ValidatorService,
} from '@app/shared/services';
import { Router } from '@angular/router';
import { PasswordMatcherBoth } from '@app/shared/validators';
import { _t } from '@app/shared/helpers';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslationService,
  L10nTranslatePipe,
} from 'angular-l10n';
import { CognitoService } from '@app/shared/services/cognito.service';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { ResetPassword } from '@app/shared/models/resetPassword';

@Component({
  selector: 'gingr-dialog-reset-password',
  templateUrl: './dialog-reset-password.component.html',
  styleUrls: ['./dialog-reset-password.component.scss'],
  providers: [ResetPassword],
  standalone: true,
  imports: [
    ExtendedModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    L10nTranslatePipe,
  ],
})
export class DialogResetPasswordComponent implements OnInit {
  public resetPasswordForm: UntypedFormGroup;
  public resetPasswordTitle: any = 'RESET_PASSWORD_DIALOG.RESET PASSWORD';
  public isSubmitting: boolean = false;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private fb: UntypedFormBuilder,
    private validator: ValidatorService,
    private router: Router,
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    public notify: NotificationService,
    public resetPasswordModel: ResetPassword,

    public dialogRef: MatDialogRef<DialogResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cognitoService: CognitoService
  ) {}

  ngOnInit() {
    this.resetPasswordModel.code = this.data?.code;
    this.resetPasswordModel.username = this.data?.username;

    this.resetPasswordForm = this.fb.group(
      {
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(this.validator.PASSWORD_REGEX),
        ]),
        compare_password: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(this.validator.PASSWORD_REGEX),
        ]),
      },
      {
        validator: PasswordMatcherBoth('password', 'compare_password'),
      }
    );
  }

  public submitForm(): void {
    if (this.isSubmitting) {
      return;
    }

    if (
      !this.resetPasswordForm.valid ||
      this.resetPasswordForm.controls.password.value !==
        this.resetPasswordForm.controls.compare_password.value
    ) {
      this.resetPasswordForm.controls.compare_password.setErrors({
        noMatch: true,
      });
    } else {
      this.isSubmitting = true;

      this.cognitoService
        .forgotPasswordSubmit(
          this.resetPasswordModel.username,
          this.resetPasswordModel.code,
          this.resetPasswordForm.value.password
        )
        .then(
          () => {
            this.isSubmitting = false;
            const message = this.translation.translate(
              _t('RESET_PASSWORD_DIALOG.Your password updated successful')
            );
            this.notify.success('Success', message);
            this.router.navigate(['/login']);
            this.dialogRef.close();
          },
          (error) => {
            this.isSubmitting = false;
            this.notify.error('Error', error?.message);
          }
        );
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

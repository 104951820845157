<div
  class="showVideo"
  [class.play]="
    playersStates.agency || playersStates.gingr || playersStates.client
  "
>
  <a
    class="close-container"
    (click)="stopVideo()"
    fxFlexAlign="row"
    fxLayoutAlign="end center"
  >
    <mat-icon class="material-icons close-btn"> close </mat-icon>
  </a>

  <div
    *ngIf="
      !playersStates.agency && !playersStates.gingr && !playersStates.client
    "
    class="container"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div fxLayout="row" fxLayoutAlign="center center">
      <p class="text">
        {{ 'SHOW-VIDEO.WHAT ARE YOU?' | translate: locale.language }}
      </p>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div
        class="icons"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-around start"
        fxLayoutAlign.xs="start center"
      >
        <div
          class="role"
          fxLayout="column"
          (click)="playersStates.client = true"
          fxLayoutAlign="start center"
        >
          <div class="icon" fxLayout="row" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <img alt="icon-client" src="/assets/images/video/2.svg" />
            </div>
          </div>

          <div class="description">
            <h2>Client</h2>
          </div>
        </div>
        <div
          class="role"
          fxLayout="column"
          (click)="playersStates.gingr = true"
          fxLayoutAlign="start center"
        >
          <div class="icon" fxLayout="row" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <img alt="icon-gingr" src="/assets/images/video/1.svg" />
            </div>
          </div>

          <div class="description">
            <h2>Gingr</h2>
            <p>({{ 'SHOW-VIDEO.SEX-WORKER' | translate: locale.language }})</p>
          </div>
        </div>
        <div
          class="role"
          fxLayout="column"
          (click)="playersStates.agency = true"
          fxLayoutAlign="start center"
        >
          <div class="icon" fxLayout="row" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <img alt="icon-agency" src="/assets/images/video/3.svg" />
            </div>
          </div>

          <div class="description">
            <h2>Agency /<br />Establishment</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="this.playersStates.gingr">
    <ng-container
      *ngTemplateOutlet="
        videoTemplate;
        context: {
          source:
            mediaCdnUrl + 'assets/videos/gingr-intro/gingr-' + lang + '.mp4'
        }
      "
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="this.playersStates.client">
    <ng-container
      *ngTemplateOutlet="
        videoTemplate;
        context: {
          source:
            mediaCdnUrl + 'assets/videos/client-intro/client-' + lang + '.mp4'
        }
      "
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="this.playersStates.agency">
    <ng-container
      *ngTemplateOutlet="
        videoTemplate;
        context: {
          source:
            mediaCdnUrl + 'assets/videos/agency-intro/agency-' + lang + '.mp4'
        }
      "
    ></ng-container>
  </ng-container>
</div>

<ng-template #videoTemplate let-source="source">
  <video
    width="100%"
    controls
    controlslist="nodownload noplaybackrate"
    disablePictureInPicture
    class="prez-video"
  >
    <source [src]="source" type="video/mp4" />
  </video>
</ng-template>

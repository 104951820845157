<div class="will-be-enabled-container" fxLayoutAlign="center center" fxLayout="column">
  <div class="will-be-enabled"  fxLayoutAlign="center center" fxLayout="column">
    <div class="ico-logo" fxLayoutAlign="center center">
      <img src="../../../../assets/images/welcome/icons/ggc_logo.min.svg">
      <svg class="ico-logo__svg" viewBox="49 0 200 30" xmlns="http://www.w3.org/2000/svg" width="169" height="49">
        <text id="text" y="28">
          <tspan class="gg" x="60">GG</tspan>
          <tspan class="coin">COIN</tspan>
        </text>
      </svg>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2>
        {{'WELCOME.ICO.Feature will be enabled' | translate: locale.language}}
        <br>
        {{'WELCOME.ICO.After the ICO' |  translate: locale.language}}
      </h2>
    </div>
    <gingr-button type="secondary" (update)="goToIco()">
      {{'WELCOME.ICO.Visit ICO website' |  translate: locale.language}}
    </gingr-button>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { StorageService } from './storage.service';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { ConfigService } from '@app/shared/services/config.service';
import { ApiService } from '@app/shared/services/api.service';
import { Store } from '@ngrx/store';
import { of } from 'rxjs/internal/observable/of';
import { PhoneVerificationDto } from '@app/shared/models/dto/phone-verification.dto';
import { PhoneVerificationChannel } from '@app/shared/models/phone-verification-channel';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class NodeApiService extends ApiService {
  public static loginActionPath = '/login';
  public csrfToken: String;

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public store: Store<any>,
    public configService: ConfigService,
    public notify: NotificationService,
    public router: Router
  ) {
    super(http, storage, notify, configService, router, store);
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(
        `${this.configService.config.nodeApiUrl}${path}`,
        JSON.stringify(body),
        {
          headers: this.setHeaders(),
        }
      )
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => {
          const response = res;
          if (response && response.status !== 200 && response.status !== 201) {
            if (
              (response.status === 401 ||
                (response.status === 403 && !this.accessToken)) &&
              path !== NodeApiService.loginActionPath
            ) {
              this.notify.warn(
                'Warning',
                'User needs to be logged in to perform this action'
              );
              this.router.navigate(['login']);
            } else {
              Sentry.captureException(response);
              this.notify.error(
                'Error ' + response.status,
                'Something went wrong. Please try again later'
              );
            }
            throw new Error(response.statusText);
          } else {
            return response;
          }
        })
      );
  }

  register(reg): Observable<any> {
    return this.post('/register', reg);
  }

  verify2fa(code: string): Observable<any> {
    return this.post('/verify2fa', { code: code });
  }

  updateAccountDetails(user_role: number, values: any): Observable<any> {
    if (
      user_role === UserRoles.CLIENT_BASIC ||
      user_role === UserRoles.CLIENT_PRO
    ) {
      return this.post('/update/accountdetails/client', values);
    } else if (
      user_role === UserRoles.SERVICE_PROVIDER_BASIC ||
      user_role === UserRoles.SERVICE_PROVIDER_PRO ||
      user_role === UserRoles.SERVICE_PROVIDER_EXTERNAL
    ) {
      return this.post('/update/accountdetails/gingr', values);
    } else if (
      user_role === UserRoles.ESTABLISHMENT_BASIC ||
      user_role === UserRoles.ESTABLISHMENT_PRO
    ) {
      return this.post('/update/accountdetails/establishment', values);
    } else if (
      user_role === UserRoles.AGENCY_BASIC ||
      user_role === UserRoles.AGENCY_PRO
    ) {
      return this.post('/update/accountdetails/agency', values);
    }
    return null;
  }

  updateNationalityAndLanguages(
    nationality: string,
    languages: any[],
    slug?: string
  ): Observable<any> {
    return this.post('/update/nationalitylanguages', {
      nationality,
      languages,
      slug: slug,
    });
  }

  updateLocation(location: any): Observable<any> {
    return this.post('/update/location', location);
  }

  updatePricing(pricing: any): Observable<any> {
    return this.post('/update/pricing', pricing);
  }

  updateServices(services: any): Observable<any> {
    return this.post('/update/services', services);
  }

  updateAvailability(availability: any): Observable<any> {
    return this.post('/update/availability', availability);
  }

  updatePersonalDetails(personal_details: any): Observable<any> {
    return this.post('/update/personal_details', personal_details);
  }

  updateExtraDetails(extra_details: any): Observable<any> {
    return this.post('/update/extra_details', extra_details);
  }

  resendActivationLink(): Observable<any> {
    return this.post('/account/send_activation', {});
  }

  changePassword(current_password, new_password): Observable<any> {
    return this.post('/account/change_password', {
      current_password,
      new_password,
    });
  }

  deleteAccount(): Observable<any> {
    return this.post('/account/delete', {});
  }

  updateBlockedCountries(blocked_countries): Observable<any> {
    return this.post('/update/blocked_countries', { blocked_countries });
  }

  updatePrivacy(object): Observable<any> {
    return this.post('/update/privacy', object);
  }

  requestPhoneVerificationCode(number, landline = false) {
    const requestBody: PhoneVerificationDto = {
      phoneType: landline
        ? PhoneVerificationChannel.PHONE
        : PhoneVerificationChannel.MOBILE,
      number,
    };
    return this.post(
      '/account/request_phone_verification_code',
      requestBody
    ).pipe(catchError(() => of(null)));
  }

  updatePhoneNumber(number, code, landline = false) {
    const requestBody: PhoneVerificationDto = {
      phoneType: landline
        ? PhoneVerificationChannel.PHONE
        : PhoneVerificationChannel.MOBILE,
      number,
      code,
    };
    return this.post('/account/update_phone_number', requestBody).pipe(
      catchError(() => of(null))
    );
  }
}

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { RegUserClient } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class RegisterService extends ApiService {
  /**
   * @param {string} path
   * @param {Object} body
   * @returns {Observable<any>}
   */
  post(path: string, body: Object = {}): Observable<any> {
    return super.basePost(path, body).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  /**
   * register client user
   * @param {RegUserClient} profile
   * @returns {Observable<Response>}
   */
  registerUser(profile: RegUserClient): Observable<Response> {
    return this.post('/createQuickAccount', profile);
  }
}

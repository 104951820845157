import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalService, NotificationService } from '../../services';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { UserService, ValidatorService } from '@app/shared/services';
import { _t } from '@app/shared/helpers';
import { DialogConfirmationEmailLoginComponent } from '@app/shared/components/confirmation-email/confirmation-email.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { WorkplaceService } from '@app/workplace/services';
import { ManageGingrsService } from '@app/manage-gingrs/services';
import {
  L10N_CONFIG,
  L10N_LOCALE,
  L10nConfig,
  L10nLocale,
  L10nTranslationService,
  L10nTranslatePipe,
} from 'angular-l10n';
import { ConfirmationConnectionService } from '@app/shared/components/confirmation-connection/confirmation-connection.service';
import { DialogLoginComponent } from '@app/shared/components';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';

@Component({
  selector: 'gingr-confir-esteblishment-connection',
  templateUrl: './confirmation-connection.component.html',
  styleUrls: ['./confirmation-connection.component.scss'],
  standalone: true,
})
export class ConfirmEstablishmentConnectionComponent
  implements OnInit, OnDestroy
{
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private workplace: WorkplaceService,
    private modalService: ModalService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translate: L10nTranslationService,
    private manageGingrsService: ManageGingrsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
        if (params['token'] && params['action']) {
          this.manageGingrsService.confirmGingrConnection(params).subscribe(
            (response) => {
              if (response.status === 200 && params['action'] === 'accept') {
                this.notify.success(
                  this.translate.translate(_t('COMMON.Success')),
                  response.result.message
                    ? response.result.message
                    : this.translate.translate(
                        _t('CONNECTION.Connection is confirmed')
                      )
                );
              } else {
                this.notify.success(
                  this.translate.translate(_t('COMMON.Success')),
                  response.result.message
                    ? response.result.message
                    : this.translate.translate(
                        _t('CONNECTION.Connection is declined')
                      )
                );
              }
            },
            (err) => {
              if (
                err &&
                err.error &&
                err.error.result &&
                err.error.result.message
              ) {
                this.notify.error(
                  this.translate.translate(_t('CONNECTION.Error')),
                  err.error.result.message
                );
              }
            }
          );
        } else {
          this.notify.error(
            this.translate.translate(_t('CONNECTION.Error')),
            this.translate.translate(_t('CONNECTION.Connection not confirmed'))
          );
        }
        // Redirect to grid
        setTimeout(() => {
          this.router.navigateByUrl('/gingrs');
        }, 3000);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}

@Component({
  selector: 'gingr-confirmation-connection',
  templateUrl: './confirmation-connection.component.html',
  styleUrls: ['./confirmation-connection.component.scss'],
  standalone: true,
})
export class ConfirmationConnectionComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private notify: NotificationService,
    private userService: UserService,
    private modalService: ModalService,
    private translate: L10nTranslationService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
        if (params['token'] && params['action']) {
          this.userService
            .confirmIndependent(params['token'], params['action'])
            .subscribe((res) => {
              if (res.status === 200) {
                this.notify.success(
                  this.translate.translate(_t('CONNECTION.Success')),
                  this.translate.translate(_t('CONNECTION.Token is valid'))
                );
                this.modalService
                  .openDialog(
                    ConfirmationConnectionDialogComponent,
                    { token: params['token'] },
                    'ConfirmationConnectionDialogComponent'
                  )
                  .subscribe((afterClose) => {
                    if (afterClose.data) {
                      this.modalService.openDialog(
                        DialogConfirmationEmailLoginComponent
                      );
                    }
                  });
              } else {
                this.notify.error(
                  this.translate.translate(_t('CONNECTION.Error')),
                  res.message
                    ? res.message
                    : this.translate.translate(_t('CONNECTION.Token not valid'))
                );
                setTimeout(() => {
                  this.router.navigateByUrl('/gingrs');
                }, 2000);
              }
            });
        } else {
          this.notify.error(
            this.translate.translate(_t('CONNECTION.Error')),
            this.translate.translate(_t('CONNECTION.Connection not confirmed'))
          );
          setTimeout(() => {
            this.router.navigateByUrl('/gingrs');
          }, 2000);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}

@Component({
  selector: 'gingr-confirmation-connection-dialog',
  templateUrl: './gingr-confirmation-connection-dialog.html',
  styleUrls: ['./gingr-confirmation-connection-dialog.scss'],
  standalone: true,
  imports: [
    ExtendedModule,
    MatDialogModule,
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    L10nTranslatePipe,
  ],
})
export class ConfirmationConnectionDialogComponent
  implements OnInit, OnDestroy
{
  private subscriptions: Subscription[] = [];
  public setPasswordForm: UntypedFormGroup;
  public isSubmitting: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationEmailLoginComponent>,
    private fb: UntypedFormBuilder,
    private validator: ValidatorService,
    private modalService: ModalService,
    private userService: UserService,
    private notify: NotificationService,
    private translate: L10nTranslationService,
    private confirmationConnectionService: ConfirmationConnectionService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.setPasswordForm =
      this.confirmationConnectionService.passwordFormGroup();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  submitForm() {
    this.isSubmitting = true;
    if (
      !this.setPasswordForm.valid ||
      this.setPasswordForm.value.password !==
        this.setPasswordForm.value.password_confirmation
    ) {
      this.setPasswordForm.controls.password_confirmation.setErrors({
        noMatch: true,
      });
    } else {
      this.subscriptions.push(
        this.userService
          .setPasswordIndependentGingr(
            this.data.token,
            this.setPasswordForm.value.password,
            this.setPasswordForm.value.password_confirmation
          )
          .subscribe((res) => {
            if (res.status === 200) {
              this.dialogRef.close({ data: this.setPasswordForm.valid });
            } else {
              this.notify.error(
                this.translate.translate(_t('CONNECTION.Error')),
                res.message
                  ? res.message
                  : this.translate.translate(
                      _t('CONNECTION.Connection not confirmed')
                    )
              );
              setTimeout(() => {
                this.router.navigateByUrl('/gingrs');
              }, 2000);
            }
          })
      );
    }
  }
}

@Component({
  selector: 'gingr-confirmation-transfer',
  templateUrl: './confirmation-connection.component.html',
  styleUrls: ['./confirmation-connection.component.scss'],
  standalone: true,
})
export class ConfirmationTransferComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private userService: UserService,
    private modalService: ModalService,
    private translate: L10nTranslationService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
        if (params['token'] && params['action'] && params['sc']) {
          if (params['action'] === 'accept') {
            this.modalService
              .openDialog(
                ConfirmationTransferDialogComponent,
                { token: params['token'], sc: params['sc'] },
                'ConfirmationConnectionDialogComponent'
              )
              .subscribe();
          } else {
            this.userService.declineTransfer(params['token']).subscribe(
              (res) => {
                if (res.status && res.status === 200) {
                  this.notify.success(
                    this.translate.translate(_t('CONNECTION.Success')),
                    res.message
                      ? res.message
                      : this.translate.translate(
                          _t('CONNECTION.Your transfer was decline successful')
                        )
                  );
                  setTimeout(() => {
                    this.router.navigateByUrl('/gingrs');
                  }, 2000);
                } else {
                  this.notify.error(
                    this.translate.translate(_t('CONNECTION.Error')),
                    res.result && res.result.message
                      ? res.result.message
                      : this.translate.translate(
                          _t('CONNECTION.Transfer not confirmed')
                        )
                  );
                }
              },
              (errors) => {
                this.notify.error(
                  this.translate.translate(_t('CONNECTION.Error')),
                  errors.error && errors.error.result.message
                    ? errors.error.result.message
                    : this.translate.translate(
                        _t('CONNECTION.Transfer not confirmed')
                      )
                );
              }
            );
          }
        } else {
          this.notify.error(
            this.translate.translate(_t('CONNECTION.Error')),
            this.translate.translate(
              _t('CONNECTION.Please check you confirmation url and try again')
            )
          );
          setTimeout(() => {
            this.router.navigateByUrl('/gingrs');
          }, 2000);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}

@Component({
  selector: 'gingr-confirmation-transfer-dialog',
  templateUrl: './gingr-confirmation-connection-dialog.html',
  styleUrls: ['./gingr-confirmation-connection-dialog.scss'],
  standalone: true,
  imports: [
    ExtendedModule,
    MatDialogModule,
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    L10nTranslatePipe,
  ],
})
export class ConfirmationTransferDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public setPasswordForm: UntypedFormGroup;
  public isSubmitting: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationEmailLoginComponent>,
    private fb: UntypedFormBuilder,
    private validator: ValidatorService,
    private modalService: ModalService,
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private notify: NotificationService,
    private translate: L10nTranslationService,
    private confirmationConnectionService: ConfirmationConnectionService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.setPasswordForm =
      this.confirmationConnectionService.passwordFormGroup();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  submitForm() {
    this.isSubmitting = true;
    if (
      !this.setPasswordForm.valid ||
      this.setPasswordForm.value.password !==
        this.setPasswordForm.value.password_confirmation
    ) {
      this.setPasswordForm.controls.password_confirmation.setErrors({
        noMatch: true,
      });
    } else {
      this.subscriptions.push(
        this.userService
          .setPasswordTransferGingr(
            this.data.token,
            this.data.sc,
            this.setPasswordForm.value.password,
            this.setPasswordForm.value.password_confirmation
          )
          .subscribe(
            (res) => {
              if (res.status === 200) {
                this.notify.success(
                  this.translate.translate(_t('CONNECTION.Success')),
                  this.translate.translate(
                    _t('CONNECTION.Your transfer was accept successful')
                  )
                );

                this.dialog.closeAll();
                this.router.navigate(['gingrs']);
                this.dialog.open(DialogLoginComponent, {
                  data: { type: 'login' },
                  panelClass: 'login-dialog--panel',
                });
              } else {
                this.notify.error(
                  this.translate.translate(_t('CONNECTION.Error')),
                  res.result && res.result.message
                    ? res.result.message
                    : this.translate.translate(
                        _t('CONNECTION.Transfer not confirmed')
                      )
                );
              }
              this.isSubmitting = false;
            },
            (errors) => {
              this.isSubmitting = false;
              this.notify.error(
                this.translate.translate(_t('CONNECTION.Error')),
                errors.error && errors.error.result.message
                  ? errors.error.result.message
                  : this.translate.translate(
                      _t('CONNECTION.Transfer not confirmed')
                    )
              );
            }
          )
      );
    }
  }
}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'gingr-info-back-btn',
    templateUrl: './info-back-btn.component.html',
    styleUrls: ['./info-back-btn.component.scss'],
    standalone: true,
    imports: [
        FlexModule,
        RouterLink,
        MatIconModule,
        L10nTranslatePipe,
    ],
})
export class InfoBackBtnComponent implements OnInit {
  @Input() link: string;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit() {}
}

import { Component, Inject, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { ButtonComponent } from '../button/button.component';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'gingr-feature-will-be-enabled',
    templateUrl: './feature-will-be-enabled.component.html',
    styleUrls: ['./feature-will-be-enabled.component.scss'],
    standalone: true,
    imports: [
        FlexModule,
        ButtonComponent,
        L10nTranslatePipe,
    ],
})
export class FeatureWillBeEnabledComponent implements OnInit {
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit() {}

  goToIco() {
    window.open('https://ggcoin.io/', '_blank');
  }
}

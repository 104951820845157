import { BookState } from '@app/book/store/book.reducer';
import { createSelector } from '@ngrx/store';
import { SharedState } from '@app/shared/reducers/shared.reducer';
import { AppConfig, AppConfigItem } from '@app/shared/models';
import {
  calculateAmountBeforeTax,
  calculateIncludedTax,
} from '@app/shared/helpers/math.helper';

export function getBookState(state): BookState {
  return state.book;
}

export function getSharedState(state): SharedState {
  return state.shared;
}

export const getBookStep = createSelector(getBookState, (state) => state.step);

export const getBookProfile = createSelector(
  getBookState,
  (state) => state.profile
);

export const getServiceFee = createSelector(getSharedState, (state) => {
  return (
    +state?.appConfig?.generalProperties?.find(
      (item: AppConfigItem) => item.name === AppConfig.ESCORT_FEE_BOOK
    )?.value || 0
  );
});

export const getTravelFee = createSelector(getSharedState, (state) => {
  return (
    +state?.appConfig?.generalProperties?.find(
      (item: AppConfigItem) => item.name === AppConfig.TRAVEL_PRICE_PER_KM
    )?.value || 0
  );
});

export const getBookTravelDistance = createSelector(
  getBookState,
  (state) => state.travelDistance
);

export const getBookTravelPrice = createSelector(
  getSharedState,
  getTravelFee,
  getBookTravelDistance,
  (state, travelFee, travelDistance) => {
    if (!travelDistance || !travelFee) {
      return 0;
    }

    if (travelDistance < 1) {
      return 0;
    }

    return Math.max(
      Math.round(travelFee * travelDistance),
      +state?.appConfig?.generalProperties?.find(
        (item: AppConfigItem) => item.name === AppConfig.TRAVEL_PRICE_MIN
      )?.value || 0
    );
  }
);

export const getBookTravelPriceWithTax = createSelector(
  getBookState,
  getBookTravelPrice,
  getServiceFee,
  (state, travelPrice, serviceFeeRate) => {
    if (!travelPrice) {
      return 0;
    }

    if (!state.profile.serviceFeeIncluded) {
      return travelPrice;
    }

    return Math.floor(calculateAmountBeforeTax(travelPrice, serviceFeeRate));
  }
);

export const getBookPrice = createSelector(
  getBookState,
  getBookTravelPrice,
  (state, travelPrice) => {
    if (!state.profile || !state.duration || !state.startDate) {
      return null;
    }

    const durationPrice = state.profile.durationPrices.find(
      (e) => e.duration === state.duration
    )?.amount;
    if (!durationPrice) {
      return null;
    }

    let servicesPrice = 0;
    if (state.profile.services?.length) {
      state.profile.services.forEach(
        (profileService) =>
          (servicesPrice += state.services?.includes(profileService.id)
            ? profileService.price
            : 0)
      );
    }

    return durationPrice + servicesPrice + travelPrice;
  }
);

export const getDurationPrice = createSelector(getBookState, (state) => {
  if (!state.profile || !state.duration || !state.startDate) {
    return null;
  }

  const durationPrice = state.profile.durationPrices.find(
    (e) => e.duration === state.duration
  )?.amount;
  if (!durationPrice) {
    return null;
  }

  return durationPrice;
});

export const includedServiceFeeServices = createSelector(
  getBookState,
  getServiceFee,
  (state, serviceFee) => {
    if (!state.profile || !state.duration || !state.startDate) {
      return null;
    }

    let servicesPrice = 0;
    if (state.profile.services?.length) {
      state.profile.services.forEach(
        (profileService) =>
          (servicesPrice += state.services?.includes(profileService.id)
            ? Math.ceil(calculateIncludedTax(profileService.price, serviceFee))
            : 0)
      );
    }

    return servicesPrice;
  }
);

export const getBookServiceFee = createSelector(
  getBookState,
  getBookPrice,
  getDurationPrice,
  includedServiceFeeServices,
  getBookTravelPrice,
  getServiceFee,
  (
    state,
    bookPrice,
    durationPrice,
    serviceFeeServices,
    travelPrice,
    serviceFee
  ) => {
    if (!bookPrice || !state.profile) {
      return 0;
    }

    if (state.profile.serviceFeeIncluded) {
      return (
        Math.ceil(calculateIncludedTax(durationPrice, serviceFee)) +
        serviceFeeServices +
        Math.ceil(calculateIncludedTax(travelPrice, serviceFee))
      );
    } else {
      return Math.round((bookPrice * serviceFee) / 100);
    }
  }
);

export const getBookPriceWithTax = createSelector(
  getBookState,
  getBookPrice,
  getBookServiceFee,
  (state, bookPrice, serviceFee) => {
    if (!state.profile) {
      return 0;
    }

    if (state.profile.serviceFeeIncluded) {
      return bookPrice;
    } else {
      return bookPrice + serviceFee;
    }
  }
);

export const getBookPlaceType = createSelector(
  getBookState,
  (state) => state.placeType
);

export const getBookOutcallType = createSelector(
  getBookState,
  (state) => state.outcallType
);

export const getBookProfileId = createSelector(
  getBookState,
  (state) => state.profileId
);

export const getBookMessage = createSelector(
  getBookState,
  (state) => state.message
);

export const getBookDetailsReviewed = createSelector(
  getBookState,
  (state) => state.detailsReviewed
);

export const getBookWhen = createSelector(getBookState, (state) => state.when);

export const getBookPaymentMethod = createSelector(
  getBookState,
  (state) => state.paymentMethod
);

export const getBookDuration = createSelector(
  getBookState,
  (state) => state.duration
);

export const getBookStartDate = createSelector(
  getBookState,
  (state) => state.startDate
);

export const getBookServices = createSelector(
  getBookState,
  (state) => state.services
);

export const getBookAddress = createSelector(
  getBookState,
  (state) => state.address
);

export const getCallTypeAvailable = createSelector(
  getBookState,
  (state) => state?.callTypeAvailable
);

export const getDistanceId = createSelector(
  getBookState,
  (state) => state.distanceId
);

export const getIsCashAvailable = createSelector(
  getBookState,
  (state) => state.cashAvailable
);

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  LocationService,
  NotificationService,
  StorageService,
  UserService,
} from '@app/shared/services';
import { SummaryCoordinates, User } from '@app/shared/models';
import { _t } from '@app/shared/helpers';
import { ManageGingrsService } from '@app/manage-gingrs/services';
import { L10N_LOCALE, L10nLocale, L10nTranslationService, L10nTranslatePipe } from 'angular-l10n';
import { select, Store } from '@ngrx/store';
import { getUser } from '@app/shared/reducers/user.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'gingr-go-live-or-optional-modal',
    templateUrl: './go-live-or-optional-modal.component.html',
    styleUrls: ['./go-live-or-optional-modal.component.scss'],
    standalone: true,
    imports: [
        FlexModule,
        MatIconModule,
        ButtonComponent,
        L10nTranslatePipe,
    ],
})
export class GoLiveOrOptionalModalComponent implements OnInit, OnDestroy {
  public slug: string;
  public user: User;
  private destroy$ = new Subject<boolean>();
  private userLocation: SummaryCoordinates;

  constructor(
    private dialog: MatDialogRef<GoLiveOrOptionalModalComponent>,
    private router: Router,
    private userService: UserService,
    private manageGingrsService: ManageGingrsService,
    private notify: NotificationService,
    private locationService: LocationService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private storage: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>
  ) {}

  ngOnInit() {
    if (this.data.user) {
      this.user = this.data.user;
    } else {
      this.store
        .pipe(
          select(getUser),
          filter((user) => !!user),
          takeUntil(this.destroy$)
        )
        .subscribe((user) => {
          if (user.slug) {
            this.user = _.cloneDeep(user);
          }
        });
    }

    this.locationService.currentDeviceLocation
      .pipe(
        filter((data) => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe((userLoc) => (this.userLocation = userLoc));
  }

  goLive() {
    this.userService
      .goToLive(this.user.slug, this.userLocation)
      .subscribe((next) => {
        if (next.status === 200) {
          this.user.live = next.result.live;
          this.user.profile_approved = next.result.profile_approved;
          if (!this.user.activeEstablishment?.isManaged) {
            this.userService.update(this.user);
          } else {
            this.manageGingrsService.update(this.user);
          }

          if (this.user.live) {
            this.notify.info(
              'Success',
              this.translation.translate(
                _t('ACCOUNT.Your account update to live status successful')
              )
            );
          }
          this.close(this.user.activeEstablishment?.isManaged);
        } else {
          this.notify.error(
            'Error',
            this.translation.translate(
              _t('ACCOUNT.Some server error! Try again later')
            )
          );
        }
      });
  }

  close(managed) {
    this.dialog.afterClosed().subscribe(() => {
      if (managed) {
        this.router.navigate(['manage-gingrs', this.user.slug]);
      } else {
        this.router.navigate(['account', 'profile']);
      }
    });
    this.dialog.close();
  }

  closeDialog() {
    this.dialog.close();
  }

  continue() {
    const storage = this.storage.get('gingr-go-live-or-optional-modal-ignore');
    this.storage.set(
      'gingr-go-live-or-optional-modal-ignore',
      Object.assign(storage ? storage : {}, { [this.user.slug]: true })
    );
    this.closeDialog();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

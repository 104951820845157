import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/shared/services/config.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  getReferralLink(referClient: boolean): Observable<{ referralLink: string }> {
    return this.http
      .post<{
        referralLink: string;
      }>(
        `${this.configService.config.apiV3}/user/referral/${referClient}`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  linkUserToReferral(object) {
    return this.http
      .post(`${this.configService.config.apiV3}/user/referral/link`, object)
      .pipe(catchError(() => of(null)));
  }
}

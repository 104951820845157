import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PasswordMatcherBoth } from '@app/shared/validators';
import { ValidatorService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationConnectionService {
  constructor(private fb: UntypedFormBuilder, private validator: ValidatorService) {}

  passwordFormGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(this.validator.PASSWORD_REGEX),
        ]),
        password_confirmation: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(this.validator.PASSWORD_REGEX),
        ]),
      },
      {
        validator: PasswordMatcherBoth('password', 'password_confirmation'),
      }
    );
  }
}

import {AbstractControl, UntypedFormGroup} from '@angular/forms';

// Password Confirm validation function
export function PasswordMatcher(control: AbstractControl) {
    if ( control.parent ) {
        if ( control.value == control.parent.get('password').value) {

            return null

        } else {
            return { noMatch: true };
        }
    }
    return null;
  }

/**
 *
 * @param {string} controlName
 * @param {string} compareControlName
 * @returns {(control: FormControl) => (null | {noMatch: boolean})}
 * @constructor
 */
export function PasswordMatcherBoth( controlName, compareControlName ) {

  return (control: UntypedFormGroup) => {
    if (control.controls[controlName] && control.controls[compareControlName] ) {
      if (control.controls[controlName].value === control.controls[compareControlName].value) {
        return null;
      } else {
        return {noMatch: true};
      }
    }
    return null;
  }
}

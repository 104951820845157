<ng-container *ngIf="isPreICO; else notPreICO">
  <div class="preIco-padding-top">
    <gingr-feature-will-be-enabled></gingr-feature-will-be-enabled>
  </div>
</ng-container>

<ng-template #notPreICO>
  <div class="content-container press-container" fxLayoutAlign="center start" fxLayout="column">
    <section fxHide.xs class="press-logos" fxLayout="column" fxLayoutAlign="center">
      <mat-list fxLayout="row" fxLayoutAlign="center center" class="content-container">
        <mat-list-item *ngFor="let pressLogo of pressLogos[0]">
          <svg-icon [src]="pressLogo"></svg-icon>
        </mat-list-item>
      </mat-list>
      <mat-list fxLayout="row" fxLayoutAlign="center center" class="content-container">
        <mat-list-item *ngFor="let pressLogo of pressLogos[1]">
          <svg-icon [src]="pressLogo"></svg-icon>
        </mat-list-item>
      </mat-list>
    </section>
    <div class="press-header-text">
      <h3 fxHide.xs >
        {{'PRESS.Select country for' | translate: locale.language }}
        <br>
        {{'PRESS.related press releases' | translate: locale.language }}
      </h3>
    </div>
    <div fxLayout="row" fxHide.xs class="full-width" fxLayoutAlign="center center" *ngIf="showMap">
      <gingr-press-map [origins]="origins" [originsLoadedEmitter]="originsLoadedEmitter" (countrySelected)="countrySelected($event)"></gingr-press-map>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="domain-satatus">
      <p *ngIf="(!pressDownloads || pressDownloads.length === 0) && pressDownloads !== undefined">{{ 'PRESS.No downloadable materials' | translate: locale.language}}</p>
      <p *ngIf="(!pressReleases || pressReleases.length === 0) && ((!pressDownloads || pressDownloads.length === 0) && pressDownloads !== undefined)">&nbsp;|&nbsp;</p>
      <p *ngIf="!pressReleases || pressReleases.length === 0">{{ 'PRESS.No releases' | translate: locale.language}}</p>
    </div>
    <div fxLayout="row" class="domains" fxLayoutAlign="center center">
      <gingr-press-domains [origins]="origins" [extraCountry]="selectedCountry" [originsLoadedEmitter]="originsLoadedEmitter" (setOrigin)="setOrigin($event)"></gingr-press-domains>
    </div>
    <div fxLayout="column" class="press-downloads" fxLayoutAlign="center center" *ngIf="showDownloadMaterial">
      <gingr-press-downloads [pressDownloads]="pressDownloads"></gingr-press-downloads>
    </div>
    <div fxLayout="row" class="releases" fxLayoutAlign="center center">
      <gingr-press-releases (takeChanges)="takeChanges($event)" [releases]="pressReleases"></gingr-press-releases>
    </div>
    <div class="spacer"></div>
  </div>
</ng-template>

import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { User } from '@app/shared/models/user';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Action } from '../core.interfaces';
import * as Auth from './auth.actions';
import { AUTH_KEY, AUTH_LOGIN, AUTH_LOGOUT } from './auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  login$: Observable<Action | { user: User }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AUTH_LOGIN),
        map((action: Auth.Login) => action.payload),
        tap((action) => {
          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true });
        })
      ),
    { dispatch: false }
  );

  logout$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AUTH_LOGOUT),
        tap((action) =>
          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: false })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<Action>,
    private localStorageService: LocalStorageService
  ) {}
}

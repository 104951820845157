import { of as observableOf, Observable } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { TruncatePipe } from '../../../../shared/pipes/truncate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PressReleaseModel } from '@app/shared/models/press/press-release.model';

@Component({
  selector: 'gingr-press-releases',
  templateUrl: './press-releases.component.html',
  styleUrls: ['./press-releases.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexModule,
    MatGridListModule,
    NgFor,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    L10nTranslatePipe,
    TruncatePipe,
  ],
})
export class PressReleasesComponent implements OnInit {
  @Input() releases: PressReleaseModel[];
  @Output() takeChanges: EventEmitter<any> = new EventEmitter<any>();
  public cols: Observable<number>;
  public rowHeight: Observable<string>;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private observableMedia: MediaObserver,
    private changes: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.takeChanges.emit(this.detectChanges.bind(this));

    if (this.observableMedia.isActive('xs')) {
      this.cols = observableOf(1);
      this.rowHeight = observableOf('500px');
    } else if (this.observableMedia.isActive('sm')) {
      this.cols = observableOf(2);
      this.rowHeight = observableOf('500px');
    } else if (this.observableMedia.isActive('md')) {
      this.cols = observableOf(3);
      this.rowHeight = observableOf('500px');
    } else if (
      this.observableMedia.isActive('lg') ||
      this.observableMedia.isActive('xl')
    ) {
      this.cols = observableOf(4);
      this.rowHeight = observableOf('500px');
    }

    // observe changes
    this.observableMedia.asObservable().subscribe((change) => {
      switch (change[0].mqAlias) {
        case 'xs':
          return [
            (this.cols = observableOf(1)),
            (this.rowHeight = observableOf('500px')),
          ];
        case 'sm':
          return [
            (this.cols = observableOf(2)),
            (this.rowHeight = observableOf('500px')),
          ];
        case 'md':
          return [
            (this.cols = observableOf(3)),
            (this.rowHeight = observableOf('500px')),
          ];
        case 'lg':
        case 'xl':
          return [
            (this.cols = observableOf(4)),
            (this.rowHeight = observableOf('500px')),
          ];
      }
    });
  }

  videoLoad() {
    const videos: any = document.getElementsByTagName('video');
    for (const video of videos) {
      video.addEventListener(
        'canplaythrough',
        () => {
          video.style.backgroundImage = 'none';
          video.removeEventListener('canplaythrough', () => {});
        },
        false
      );
    }
  }

  getDayMonthYear(date, type): string {
    const d = new Date(date);
    let curr_date = '' + d.getDate();
    let curr_month = d.getMonth() + 1 + '';
    const curr_year = d.getFullYear();
    if (type === 'dayMonth') {
      if (curr_month.length === 1) {
        curr_month = '0' + curr_month;
      }
      if (curr_date.length === 1) {
        curr_date = '0' + curr_date;
      }
      return curr_date + '.' + curr_month;
    } else if (type === 'year') {
      return curr_year.toString();
    }
  }

  detectChanges() {
    if (!this.changes['destroyed']) {
      this.changes.detectChanges();
    }
    setTimeout(this.videoLoad, 0);
  }
}

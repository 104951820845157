import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  ModalService,
  NotificationService,
  UserService,
  ValidatorService,
} from '@app/shared/services';
import { LoginUser, User } from '@app/shared/models';
import { DialogForgotPasswordComponent } from '@app/shared/components/dialog-forgot-password/dialog-forgot-password.component';
import { MediaObserver } from '@angular/flex-layout';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { _t } from '@app/shared/helpers';
import {
  L10N_CONFIG,
  L10N_LOCALE,
  L10nConfig,
  L10nLocale,
  L10nTranslationService,
  L10nTranslatePipe,
} from 'angular-l10n';
import { CognitoService } from '@app/shared/services/cognito.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
  selector: 'gingr-confirmation-email',
  templateUrl: './confirmation-email.component.html',
  styleUrls: ['./confirmation-email.component.scss'],
  standalone: true,
})
export class ConfirmationEmailComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private translate: L10nTranslationService,
    private cognitoService: CognitoService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params?.['code'] && params?.['username']) {
        this.cognitoService
          .confirmRegister(params?.['username'], params['code'])
          .then(
            () => {
              this.notify.success(
                this.translate.translate(_t('ACCOUNT.SETTINGS.Success')),
                this.translate.translate(_t('ACCOUNT.SETTINGS.Email confirmed'))
              );
              this.router.navigateByUrl('/login');
            },
            (error) => {
              this.notify.error('Error', error?.message);
              this.router.navigateByUrl('/gingrs');
            }
          );
      } else {
        this.router.navigateByUrl('/gingrs');
      }
    });
  }
}

@Component({
  selector: 'gingr-confirmation-email-login-base',
  templateUrl: './confirmation-email-login-base.html',
  styleUrls: ['./confirmation-email.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgClass,
    ExtendedModule,
    MatInputModule,
    NgIf,
    MatIconModule,
    MatButtonModule,
    L10nTranslatePipe,
  ],
})
export class ConfirmationEmailLoginBaseComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public loginForm: any;
  public loginUser: LoginUser = new LoginUser();
  public isSubmitting: boolean = false;
  showPassword = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private notify: NotificationService,
    private modalService: ModalService,
    private observableMedia: MediaObserver,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private localeService: L10nTranslationService,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private validator: ValidatorService,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    this.initLoginForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public initLoginForm() {
    this.loginForm = new UntypedFormGroup({
      email: this.validator.PasswordFormControl(),
      password: this.validator.RequiredFormControl(),
    });
  }

  public submitLogin() {
    const credentials = this.loginForm.value;
    this.isSubmitting = true;
    this.userService.attemptAuth('login', credentials).subscribe(
      (data) => {
        this.isSubmitting = true;
        const user: User = data['result'].user;

        if (user['2fa'] !== 'DISABLED') {
        } else {
          this.dialog.closeAll();
          this.router.navigate(['account', 'profile']);
        }
      },
      (err) => {
        this.isSubmitting = true;
        this.notify.error(
          'Error',
          err[0] && err[0].message
            ? err[0].message
            : 'Problem with authorization'
        );
      }
    );
  }

  public forgotPass(): boolean {
    this.modalService.openDialog(DialogForgotPasswordComponent);
    return false;
  }
}

@Component({
  selector: 'gingr-confirmation-email-login-dialog',
  templateUrl: './confirmation-email-login-dialog.html',
  styleUrls: ['./confirmation-email-login-dialog.scss'],
  standalone: true,
  imports: [ExtendedModule, MatIconModule, ConfirmationEmailLoginBaseComponent],
})
export class DialogConfirmationEmailLoginComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationEmailLoginComponent>,
    private router: Router
  ) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe((dialogData) => {
      if (
        dialogData &&
        dialogData.action &&
        dialogData.action === 'close-login'
      ) {
        this.router.navigate(['/welcome']);
      }
    });
  }

  public closeDialog(): void {
    this.dialogRef.close({ action: 'close-login' });
  }
}
// **********************activation email **************************

@Component({
  selector: 'gingr-activation-email-page',
  templateUrl: './activation-email-page.html',
  styleUrls: ['./confirmation-email.component.scss'],
  standalone: true,
})
export class ActivationEmailPageComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private translate: L10nTranslationService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(
      (params: Params) => {
        if (params && params['token']) {
          const token = params['token'];
          this.userService.activateEmailLogin({ token: token }).subscribe(
            (response: any) => {
              if (response.status === 200) {
                this.notify.success(
                  this.translate.translate(_t('ACCOUNT.SETTINGS.Success')),
                  this.translate.translate(
                    _t('ACCOUNT.SETTINGS.Account activated')
                  )
                );

                this.router.navigateByUrl('/login');
              } else {
                this.notify.error('Error', response.message);
                this.router.navigateByUrl('/gingrs');
              }
            },
            (err: any) => {
              this.notify.error('Error', err.message);
              this.router.navigateByUrl('/gingrs');
            }
          );
        } else {
          this.router.navigateByUrl('/gingrs');
        }
      },
      (err: any) => {
        this.router.navigateByUrl('/gingrs');
      }
    );
  }
}

@Component({
  selector: 'gingr-activation-email-login-dialog',
  templateUrl: './activation-email-login-dialog.html',
  styleUrls: ['./confirmation-email-login-dialog.scss'],
  standalone: true,
  imports: [MatIconModule, forwardRef(() => ActivationEmailLoginBaseComponent)],
})
export class DialogActivationEmailLoginComponent implements OnInit {
  public token: string;
  constructor(
    public dialogRef: MatDialogRef<DialogActivationEmailLoginComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.token = this.dialogData.token ? this.dialogData.token : '';
    this.dialogRef.afterClosed().subscribe((dialogData) => {
      if (
        dialogData &&
        dialogData.action &&
        dialogData.action === 'close-login'
      ) {
        this.router.navigate(['/welcome']);
      }
    });
  }

  public closeDialog(): void {
    this.dialogRef.close({ action: 'close-login' });
  }
}

@Component({
  selector: 'gingr-activation-email-login-base',
  templateUrl: './confirmation-email-login-base.html',
  styleUrls: ['./confirmation-email.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgClass,
    ExtendedModule,
    MatInputModule,
    NgIf,
    MatIconModule,
    MatButtonModule,
    L10nTranslatePipe,
  ],
})
export class ActivationEmailLoginBaseComponent implements OnInit, OnDestroy {
  @Input() tokenData: any;
  private subscriptions: Subscription[] = [];

  public loginForm: any;
  public loginUser: LoginUser = new LoginUser();
  public isSubmitting: boolean = false;
  showPassword = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private notify: NotificationService,
    private modalService: ModalService,
    private observableMedia: MediaObserver,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private localeService: L10nTranslationService,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private validator: ValidatorService,
    public dialog: MatDialog,
    private translate: L10nTranslationService
  ) {}
  ngOnInit() {
    this.initLoginForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public initLoginForm() {
    this.loginForm = new UntypedFormGroup({
      email: this.validator.PasswordFormControl(),
      password: this.validator.RequiredFormControl(),
    });
  }

  public submitLogin() {
    const credentials = Object.assign(this.loginForm.value, {
      token: this.tokenData,
    });
    this.isSubmitting = true;
    this.userService.activateEmailLogin(credentials).subscribe(
      (data) => {
        this.isSubmitting = true;
        const user: User = data['result'].user;

        if (user['2fa'] !== 'DISABLED') {
        } else {
          this.dialog.closeAll();
          this.notify.success(
            this.translate.translate(_t('ACCOUNT.SETTINGS.Success')),
            this.translate.translate(_t('ACCOUNT.SETTINGS.Account activated'))
          );
          this.router.navigate(['account', 'profile']);
        }
      },
      (err) => {
        this.isSubmitting = true;
        let errMessage = 'Problem with authorization';
        if (err.error && err.error.result && err.error.result.message) {
          errMessage = err.error.result.message;
        }
        this.notify.error('Error', errMessage);
      }
    );
  }

  public forgotPass(): boolean {
    this.router.navigate(['forgot-password']);
    return false;
  }
}

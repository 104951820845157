import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  NotificationService,
  UserService,
  ValidatorService,
} from '@app/shared/services';
import { ForgotPassword } from '@app/shared/models';
import { Router } from '@angular/router';
import { ModalService } from '@app/shared/services/modal.service';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslationService,
  L10nTranslatePipe,
} from 'angular-l10n';
import { CognitoService } from '@app/shared/services/cognito.service';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';

@Component({
  selector: 'gingr-dialog-forgot-password',
  templateUrl: './dialog-forgot-password.component.html',
  styleUrls: ['./dialog-forgot-password.component.scss'],
  providers: [ForgotPassword],
  standalone: true,
  imports: [
    ExtendedModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    L10nTranslatePipe,
  ],
})
export class DialogForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: UntypedFormGroup;
  public isSubmitting = false;

  constructor(
    public dialog: MatDialog,
    private validator: ValidatorService,
    private userService: UserService,
    private router: Router,
    private modalService: ModalService,
    private fb: UntypedFormBuilder,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    public forgotPasswordModel: ForgotPassword,
    public notify: NotificationService,
    public dialogRef: MatDialogRef<DialogForgotPasswordComponent>,
    private cognitoService: CognitoService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  public initForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(ValidatorService.EMAIL_REGEX),
      ]),
    });
  }

  public submitForm(): void {
    if (this.isSubmitting || this.forgotPasswordForm.invalid) {
      return;
    }
    this.isSubmitting = true;

    this.cognitoService
      .forgotPassword(this.forgotPasswordForm.value.email)
      .then(
        () => {
          this.isSubmitting = false;
          const message = this.translation.translate(
            'FORGOT_PASSWORD_DIALOG.Please check you email We send you letter with detail instruction'
          );
          this.notify.success('Success', message);
          this.dialogRef.close();
        },
        () => {
          this.isSubmitting = false;
        }
      );
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
